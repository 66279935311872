import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";
import { HeaderLogoMH } from "../../../headers/headerLogoMH"
import { TextFooter } from "../../../footers/textFooter";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx"
import { MetaTags } from "react-meta-tags";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import '@splidejs/react-splide/css';


// import required modules
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper";
import { HeaderMenu_ES } from "../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../headers/headerLogoMH_ES";
import { HeaderMenu } from "../../../headers/headerMenu";

export default function SelectWork_EN() {
  const title = [
    "CLIGHT | LO QUE NOS HACE BIEN",
    "HBOMAX | DEL CINE A TU CASA",
    "YPF | IDOLAS",
    "BPLAY | SPONSOR DEL CORAZÓN ARGENTO",
    "ZURICH | OTRO DEPORTE",
    "STRIX | LA EVOLUCION DEL LOJACK",
  ];
  const title2 = [
    " Olivia Sanchez & Martina Galli Agulla",
    "Santiago Franco",
    "Fede Garcia Rico",
    "Mariano Nante",
    "Gonzo Llorente",
    "Fede García Rico",
  ];
  const href1 = [
    "/en/lo-que-nos-hace-bien",
    "/en/del-cine-a-tu-casa",
    "/en/idolas",
    "/en/bplay",
    "/en/another-sport",
    "/en/la-evolucion-del-lojack",
  ];

  return (
    <><MetaTags>
      <title>Mamá Húngara | Selected</title>
    </MetaTags>
      <HeaderMenu />
      <HeaderLogoMH />
      <div className="container-swiper-home">
      <KeyboardEventHandler
          handleKeys={['left']}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="mySwiper container-works-sections-test"
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, Mousewheel]}
          slidesPerView={1}
          loop={true}
          spaceBetween={0}
          centeredSlides={true}
          mousewheel
          pagination={{
            clickable: true,
            renderBullet: function (index, className,) {
              return (
                '<div class="' + className + '">' + '<p class="texto-selected-works">' + title[index] + '</p>' + '<p class="texto-selected-works">' + title2[index] + '</p>' + '<div class="btn-active">' + '</div>' + '<a class="href-works" href="' + href1[index] + '">' + '</a>' + '</div>'
              );
            }
          }}


        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/en/lo-que-nos-hace-bien', '_self'))}
          >
            <ReactPlayer
              alt='CLIGHT | LO QUE NOS HACE BIEN | LA DULCE LIBERAL'
              className="react-player-chau"
              url="https://vimeo.com/803081038"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/del-cine-a-tu-casa', '_self'))}
          >
            <ReactPlayer
              alt='"HBOMAX | DEL CINE A TU CASA'
              className="react-player-video"
              url="https://vimeo.com/806073285"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          <SwiperSlide
            onClick={() => (window.open('/en/idolas', '_self'))}
          >
            <ReactPlayer
              alt='YPF | IDOLAS | FEDE GARCIA RICO'
              className="react-player-video"
              url="https://vimeo.com/848029570"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          <SwiperSlide
            onClick={() => (window.open('/en/bplay', '_self'))}
          >
            <ReactPlayer
              alt='BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE'
              className="react-player-video"
              url="https://vimeo.com/761431757"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 3 */}
          <SwiperSlide
            onClick={() => (window.open('/en/another-sport', '_self'))}
          >
            <ReactPlayer
              alt='ZURICH | OTRO DEPORTE'
              className="react-player-video"
              url="https://vimeo.com/752541132"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          {/* video 4 */}
          <SwiperSlide
            onClick={() => (window.open('/en/la-evolucion-del-lojack', '_self'))}
          >
            <ReactPlayer
              alt='STRIX | LA EVOLUCION DEL LOJACK | FEDE GARCIA RICO'
              className="react-player-chau"
              url="https://vimeo.com/756236637 "
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 5 */}


        </Swiper>
      </div>

      {/* Mobile */}



      <div className="container-video-works-mobile">
        <Splide
          data-splide='{"type":"loop","perPage":1 }'
        >
          <SplideSlide >
            <div className="drag-mobile"> </div>
            <ReactPlayer
              alt='CLIGHT | LO QUE NOS HACE BIEN | LA DULCE LIBERAL '
              className="react-player-chau"
              url="https://vimeo.com/803081038"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='HBOMAX | DEL CINE A TU CASA | GONZO LLORENTE'
              className="react-player-chau"
              url="https://vimeo.com/806073285"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='YPF | IDOLAS | Fede García Rico'
              className="react-player-chau"
              url="https://vimeo.com/848029570"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='BPLAY | SPONSOR DEL CORAZÓN ARGENTO | MARIANO NANTE'
              className="react-player-chau"
              url="https://vimeo.com/761431757"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='ZURICH | OTRO DEPORTE | SANTIAGO FRANCO'
              className="react-player-chau"
              url="https://vimeo.com/752541132"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"> </div>
            <ReactPlayer
              alt="STRIX | LA EVOLUCION DEL LOJACK | FEDE GARCIA RICO"
              className="react-player-chau"
              url="https://vimeo.com/756236637"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
        </Splide>
      </div>
      <div className="container-titles-works-mobile">
        <a href="/en/del-cine-a-tu-casa">
          <p className="texto-selected-works-mobile">
            HBOMAX | DEL CINE A TU CASA
          </p>
          <p className="texto-selected-works-mobile">
            Gonzo Llorente
          </p>
        </a>
        <a href="/en/bplay">
          <p className="texto-selected-works-mobile">
            BPLAY | SPONSOR DEL CORAZÓN ARGENTO
          </p>
          <p className="texto-selected-works-mobile">
            Mariano Nante
          </p>
        </a>
        <a href="/es/idolas">
          <p className="texto-selected-works-mobile">
            YPF | IDOLAS
          </p>
          <p className="texto-selected-works-mobile">
            Fede García Rico
          </p>
        </a>
        <a href="/en/otro-deporte">
          <p className="texto-selected-works-mobile">
            ZURICH | OTRO DEPORTE
          </p>
          <p className="texto-selected-works-mobile">
            Marian & Santi
          </p>
        </a>
        <a href="/en/la-evolucion-del-lojack">
          <p className="texto-selected-works-mobile">
            STRIX | LA EVOLUCION DEL LOJACK
          </p>
          <p className="texto-selected-works-mobile">
            Fede García Rico
          </p>
        </a>
        <a href="/en/otro-deporte">
          <p className="texto-selected-works-mobile">
            CLIGHT | LO QUE NOS HACE BIEN
          </p>
          <p className="texto-selected-works-mobile">
            La Dulce Liberal
          </p>
        </a>


      </div>

      <TextFooter
        title1="ALL SELECTED"
        href1="/en/all-selected"
        hoverHref="hoverHref"
        centerContent
      />
      <FooterSmall2 />
    </>
  );
}
