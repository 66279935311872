import React from 'react';

import { Image, } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from "swiper/react";
import {  Pagination, Navigation, Mousewheel} from "swiper";
import { HeaderMenu } from "../../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../../headers/headerLogoMH";
import { TextFooter } from '../../../../footers/textFooter';
import { FooterLogoColors } from '../../../../footers/footerColors/footerColors';
import logoMHW from "../../../../img/500h/Asset_logo_blanco.png";
import '../.././sliderStill.css';
import Mouse3_drag_blanco from '../../../../mouse/Mouse3_drag_blanco';

import Img1 from './brahma-pomelo-1.jpg';
import Img2 from './brahma-pomelo-2.jpg';
import Img3 from './brahma-pomelo-3.jpg';
import Img4 from './brahma-pomelo-4.jpg';
import Img5 from './brahma-pomelo-5.jpg';
import Img6 from './brahma-pomelo-6.jpg';
import Img7 from './brahma-pomelo-7.jpg';
import { HeaderLogoMH_ES } from '../../../../headers/headerLogoMH_ES';
import { HeaderMenu_ES } from '../../../../headers/headerMenu_ES';

export const S_Proy_brahma_pomelo_ES = () => {
    return (
        <>
            <HeaderLogoMH_ES />
            <HeaderMenu_ES />
            <Mouse3_drag_blanco />
            <Swiper 
                   className='cursor-none container-swiper-still'
                   slidesPerView={"auto"}
                   loopedSlides={7}
                   spaceBetween={0}
                   freeMode={false}
                   modules={[ Pagination, Navigation, Mousewheel]}
mousewheel
                   navigation
            >
                <SwiperSlide>
                    <div className="container-slider-photographer-still  cursor-none">
                        <Image className='img-slider-still' src={Img1} alt='Joe Ekonen' />
                        <div className="contain-02">
                            <p className="text-top-slider-still">2021</p>

                            <div className='container-text-slider-still'>
                                <p className="title-img-slider-stil-1"></p>
                                <p className="title-img-slider-stil-2">TOQUE POMELO</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={Img2} alt='Joe Ekonen' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={Img3} alt='Joe Ekonen' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={Img4} alt='Joe Ekonen' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={Img5} alt='Joe Ekonen' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={Img6} alt='Joe Ekonen' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={Img7} alt='Joe Ekonen' />
                </SwiperSlide>
           

            </Swiper>
            <a onClick={() => { window.history.back() }}>
                <TextFooter
                    title1="BACK"
                />
            </a>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>

    )
}

