import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from '../../../headers/headerText';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
import './grillas-talents.css';

export const GrillaSteveBruno = () => {
    return (<>
        <HeaderTexts
            title="SteveBruno"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | Todo SteveBruno</title>
                <meta
                    content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                    description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/spotify-playlist'></a>
                    <ReactPlayer
                        alt='SPOTIFY | PLAYLIST | STEVE BRUNO'
                        className="video-grilla-talents"
                        url="https://vimeo.com/848730249/d9016bcbda"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        SPOTIFY | PLAYLIST 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Steve Bruno
                        </p>
                    </div>

                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/drives-you-wonder'></a>
                    <ReactPlayer
                        alt='DRIVES YOU WONDER | NISSAN | STEVE BRUNO'
                        className="video-grilla-talents"
                        url="https://vimeo.com/843887356/62e458e7e8?share=copy"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        DRIVES YOU WONDER | NISSAN 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Steve Bruno
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/blending'></a>
                    <ReactPlayer
                        alt='BLENDING / STEVE BRUNO'
                        className="video-grilla-talents"
                        url="https://vimeo.com/848485218/dc9b87a94c"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        BLENDING 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Steve Bruno
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/boticario-cuidese'></a>
                    <ReactPlayer
                        alt='BOTICARIO | CUIDESE BEM BOA NOITE | STEVE BRUNO'
                        className="video-grilla-talents"
                        url="https://vimeo.com/848734278/ce5302881b"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        BOTICARIO | CUIDESE BEM BOA NOITE
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Steve Bruno
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}
