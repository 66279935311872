import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from "./videos/zurich.mp4";
import "swiper/css";
import "swiper/css/bundle";
import "./styleSlideimage2.css";
import { Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import MetaTags from "react-meta-tags";
import "@splidejs/react-splide/css";
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from "react-keyboard-event-handler";

function HernanCorera() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES title="HERNAN CORERA" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>Hernán Corera</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() =>
              window.open("/es/netflix-estes-para-lo-que-estes-1", "_self")
            }
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="react-player-video"
                url="https://vimeo.com/859807311"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES | "
              title2="Hernán Corera & Juan Piczman"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              window.open("/es/netflix-estes-para-lo-que-estes-2", "_self")
            }
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES | "
                className="react-player-video"
                url="https://vimeo.com/857990218"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES | "
              title2="Hernán Corera & Juan Piczman"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              window.open("/es/netflix-estes-para-lo-que-estes-3", "_self")
            }
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES | "
                className="react-player-video"
                url="https://vimeo.com/856874220"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES | "
              title2="Hernán Corera & Juan Piczman"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/nadie", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="NADIE | SIN BANDERA"
                className="react-player-video"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NADIE | SIN BANDERA | "
              title2="Hernán Corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/amor-mio", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                className="react-player-video"
                url="https://vimeo.com/954460674"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA CAMPOS | AMOR MIO | "
              title2="Hernán Corera & Mateo Yaya"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/beldent", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BELDENT | MOSTRALES QUE ESTAS READY"
                className="react-player-video"
                url="https://vimeo.com/763421158"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BELDENT | MOSTRALES QUE ESTAS READY | "
              title2="Hernán Corera & Juan Piczman"
            />
          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => window.open("/es/paraiso", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PARAISO | SADAELS"
                className="react-player-video"
                url="https://vimeo.com/520234550"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="PARAISO | SADAELS | " title2="Hernán Corera" />
          </SwiperSlide>
          {/* video 3*/}
          <SwiperSlide
            onClick={() => window.open("/es/you-are-not-alone", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="YOU´RE NOT ALONE I AGORIA"
                className="react-player-video"
                url="https://vimeo.com/310419904"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="YOU'RE NOT ALONE | AGORIA | "
              title2="Hernán Corera"
            />
          </SwiperSlide>
          {/* video 4 */}
          <SwiperSlide
            onClick={() => window.open("/es/un-amor-verdadero", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="UN AMOR VERDADERO | SADAELS "
                className="react-player-video"
                url="https://vimeo.com/471045785"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="UN AMOR VERDADERO | SADAELS | "
              title2="Hernán Corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/hecho-en-argentina", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="HECHO EN ARGENTINA | NETFLIX  "
                className="react-player-video"
                url="https://vimeo.com/693596507"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="HECHO EN ARGENTINA | NETFLIX | "
              title2="Hernán Corera"
            />
            <a className="more" href="/es/todo-hernan-corera">
              More
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"slide","perPage": 1, "drag": "free" }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="react-player-video"
                url="https://vimeo.com/859807311"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES  "
              title2="Hernán Corera & Juan Piczman"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="react-player-video"
                url="https://vimeo.com/857990218"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES  "
              title2="Hernán Corera & Juan Piczman"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="react-player-video"
                url="https://vimeo.com/856874220"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES  "
              title2="Hernán Corera & Juan Piczman"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NADIE | SIN BANDERA"
                className="react-player-video"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter title1="NADIE | SIN BANDERA  " title2="Hernán Corera" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                className="react-player-video"
                url="https://vimeo.com/954460674"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA CAMPOS | AMOR MIO | "
              title2="Hernán Corera & Mateo Yaya"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BELDENT | MOSTRALES QUE ESTAS READY"
                className="react-player-video"
                url="https://vimeo.com/763421158"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="BELDENT | MOSTRALES QUE ESTAS READY"
              title2="Hernán Corera & Juan Piczman"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PARAISO | SADAELS"
                className="react-player-video"
                url="https://vimeo.com/520227700"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="PARAISO | SADAELS  " title2="Hernán Corera" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="YOU´RE NOT ALONE I AGORIA"
                className="react-player-video"
                url="https://vimeo.com/310419904"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="YOU'RE NOT ALONE | AGORIA  "
              title2="Hernán Corera"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="UN AMOR VERDADERO | SADAELS "
                className="react-player-video"
                url="https://vimeo.com/471045785"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="UN AMOR VERDADERO | SADAELS  "
              title2="Hernán Corera"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="HECHO EN ARGENTINA | NETFLIX  "
                className="react-player-video"
                url="https://vimeo.com/693596507"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="HECHO EN ARGENTINA | NETFLIX  "
              title2="Hernán Corera"
            />
            <a className="more" href="/es/todo-hernan-corera">
              More
            </a>
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}

export default HernanCorera;
