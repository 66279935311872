import React from 'react';

import { useColorModeValue, Image, } from "@chakra-ui/react";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';

import logoMHB from "../../../img/500h/Asset_logo_blanco.png"
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";
import { MetaTags } from 'react-meta-tags';
import '../internoFilm.css';
import { HeaderMenu_ES } from '../../../headers/headerMenu_ES';



export const Paula = () => {
    return (
        <>
            <HeaderMenu_ES />
            <HeaderLogoMH />
            <div className="container-data-film">
            <MetaTags>
                <title>Mamá Húngara | Paula</title>
                <meta
                    content="Largometraje dirigido por Eugenio Canevari. Estreno Mundial en el Festival Internacional de Cine de San Sebastián en la sección Nuevos Directores del 2015."
                    description="Largometraje dirigido por Eugenio Canevari. Estreno Mundial en el Festival Internacional de Cine de San Sebastián en la sección Nuevos Directores del 2015."
                />
            </MetaTags>
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                        Paula cuida los hijos de una familia del campo burguesa en el la provincia de Buenos Aires. Al descubrir que está embarazada, deberá tomar una decisión desesperada.
                    </p>
                    <a href='/es/paula' className='title-video-mobile-film'>
                    PAULA
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/es/paula'>
                                PAULA
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Dirigida por
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                EUGENIO CANEVARI
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Género | Ficción
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duración | 65 minutos
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Formato de rodaje | Digital
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Formato de proyección | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sonido | 5.1
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Idioma | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                País | Argentina - España
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Estreno Mundial | Festival Internacional de Cine de San Sebastián - Nuevos Directores. 2015
                            </p>
                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo='https://vimeo.com/133770419' />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/es/films"
            />
            <FooterLogoColors 
            LogoColor={logoMHW}
      />
        </>
    )
}

