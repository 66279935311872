import { useDisclosure, Image } from "@chakra-ui/react";
import { ReactNode } from "react";

import logoMH from "../img/logos/png/Asset_9_B.png";

import './header.css'

export const HeaderLogoMH = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>
    <div className="container-LOGO-header-1">
      <a href="/en/home/">
        <Image className="logo-header-1" alt="logoMH" src={logoMH} />
      </a>
    </div>
  </>
  );
};
