import React from 'react';

import { HeaderMenu } from "../../headers/headerMenu";
import { HeaderLogoMH } from "../../headers/headerLogoMH";
import { TextFooter } from '../../footers/textFooter';
import './films.css';
import { TitleFilms } from './titleFilms';
import { FooterSmall2 } from '../../footers/FooterSmall2.tsx';
import { FooterLogoColors } from '../../footers/footerColors/footerColors';
import logoMHV from "../../img/500h/Asset_logo_verde.png";
import { HeaderMenu_ES } from '../../headers/headerMenu_ES';
import { MetaTags } from 'react-meta-tags';

export const Film = () => {
    return (
        <>
        <MetaTags>
                <title>Mamá Húngara | Film</title>
                <meta
                    content="Films Mamá Húngara"
                    description="Films Mamá Húngara"
                />
            </MetaTags>
            <HeaderLogoMH />
            <HeaderMenu_ES />
            <TitleFilms />
            <TextFooter
                title1="FILMS"
            />
            <FooterLogoColors
                LogoColor={logoMHV}
            />
        </>
    )
}

