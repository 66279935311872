import { Button, useDisclosure } from "@chakra-ui/react";
import { Drawer, Image } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";

import "./Drawer.css";
import closeIcon from "../img/500h/Asset_1.png";
import openIcon from "../img/500h/Asset_boton_menu_blanco.png";

export const Submenu = ({ href, text }) => (
  <a href={href} className="text-submenu">
    {text}
  </a>
);
export const Drawer_MH_EN = () => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure(); /* aca esto  */
  const [placement, setPlacement] = React.useState("right");
  const [isShown, setShown] = useState("");
  // agregar use effect para que salga el boton de aceptar cookies apenas se carga la web

  return (
    <>
      <Button
        bgColor={"transparent"}
        onClick={onOpen}
        alignItems={"center"}
        _hover={{
          bg: "transparent",
          cursor: "default",
          borderLine: "none",
          boxShadow: "none",
        }}
        padding="0px"
        margin="0px"
        leftIcon={
          <Image margin="0" bgColor="none" src={openIcon} w="36px" h="32px" />
        }
      ></Button>
      <Drawer
        fontWeight={"var(--chakra-fontWeights-thin)"}
        size={"sm"}
        placement={placement}
        onClose={onClose}
        isOpen={isOpen}
      >
        <Fade right>
          <div className="container-menu-drawer">
            <Fade right>
              <div className="container-text-menu">
                <img
                  className="icon-close-menu"
                  onClick={onClose}
                  src={closeIcon}
                />

                <div className="container-menu-with-submenu">
                  <a
                    className="text-menu-drawer"
                    // href='/selected'

                    // onClick={() => (isShown === 'works') ? setShown('') : setShown('works')}
                  >
                    Works
                  </a>
                  {/* {  */}
                  {/* // (isShown === "works") && ( */}
                  <Fade right>
                    <div className="container-submenu-works">
                      <Submenu href="/en/selected" text="Selected" />
                      <Submenu href="/en/commercial" text="Commercials" />
                      <Submenu href="/en/content" text="Content" />
                    </div>
                    <div className="container-submenu-works-mobile">
                      <Submenu href="/en/all-selected" text="Selected " />
                      <Submenu href="/en/all-commercial" text="Commercials" />
                      <Submenu href="/en/all-content" text="Content" />
                    </div>
                  </Fade>
                  {/* )  */}
                  {/* } */}
                </div>
                <a href="/en/talents" className="text-menu-drawer">
                  Directors
                </a>
                <div className="container-menu-with-submenu">
                  <a
                    className="text-menu-drawer "
                    href="/en/home-stills"
                    // onClick={() => (isShown === 'still') ? setShown('') : setShown('still')}
                  >
                    Photographers
                  </a>
                  {/* {  */}
                  {/* // (isShown === "still") && ( */}
                  {/* <Fade right>
                    <div className="container-submenu-works">
                      <Submenu href="/en/martin-sigal" text="Martin Sigal" />
                      <Submenu href="/en/sisso-chouela" text="Sisso Chouela" />
                      <Submenu href="/en/ioni-epelbaum" text="Ioni Epelbaum" />
                      <Submenu href="/en/tatu-garcia" text="Tatu García" />
                      <Submenu href="/en/joe-ekonen" text="Joe Ekonen" />
                      <Submenu href="/en/eze-monjo" text="Ezequiel Monjo" />
                    </div>
                  </Fade> */}
                </div>
                <a href="/en/films" className="text-menu-drawer">
                  Film
                </a>

                <a href="/en/family" className="text-menu-drawer">
                  Contact
                </a>
                {/*       <a href='' className='text-menu-drawer'>
              Marketplace
            </a> */}
              </div>
            </Fade>
            <Fade right>
              <div className="container-footer-drawer">
                <div className="container-es-en">
                  <a href="/es/inicio">ESP</a>
                  <a href="/en/home">ENG</a>
                </div>
                <div className="social">
                  <a
                    className="text-footer-menu-instagram "
                    href="https://www.instagram.com/mamahungara/?hl=en"
                    target="_blank"
                  >
                    Instagram
                  </a>
                  <a
                    className="text-footer-menu-instagram links "
                    target="_blank"
                    href="https://vimeo.com/mamahungara"
                  >
                    Vimeo
                  </a>
                  <a
                    className="text-footer-menu-instagram links "
                    target="_blank"
                    href="https://www.linkedin.com/company/mama-hungara/"
                  >
                    Linkedin
                  </a>
                  <a
                    className="text-footer-menu-instagram links"
                    target="_blank"
                    href="https://mamahungara.us12.list-manage.com/subscribe?u=f13f393d4d76ef79d35a757e7&id=71e7b3ba3c"
                  >
                    Newsletter
                  </a>
                </div>

                <a className="text-footer-menu">© 2024 Mamá Húngara</a>
              </div>
            </Fade>
          </div>
        </Fade>
      </Drawer>
    </>
  );
};
