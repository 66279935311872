import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from '../../../headers/headerText';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import { MetaTags } from 'react-meta-tags';

// import './grillas-talents.css';

export const GrillaMarianSanti_EN = () => {
    return (<>
        <HeaderTexts
            title="MARIAN & SANTI"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
        <MetaTags>
                <title>MH | All Marian & Santi</title>
                <meta
                    content="Dupla de directores de cine y publicidad representados por Mamá Húngara"
                    description="Dupla de directores de cine y publicidad representados por Mamá Húngara"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/bplay-sponsor-oficial'></a>
                    <ReactPlayer
                        alt='BPLAY | SPONSOR OFICIAL | Santiago Franco'
                        className="video-grilla-talents"
                        url="https://vimeo.com/850703869"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        BPLAY | SPONSOR OFICIAL
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Santiago Franco
                        </p>
                    </div>

                </div>

                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/supervielle'></a>
                    <ReactPlayer
                        alt='SUPERVIELLE I POR H O POR B I Mariano Nante'
                        className="video-grilla-talents"
                        url="https://vimeo.com/840222691"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            SUPERVIELLE | POR H O POR B
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Mariano Nante
                        </p>

                    </div>

                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/bplay'></a>
                    <ReactPlayer
                        alt='BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE '
                        className="video-grilla-works"
                        url="https://vimeo.com/761431757"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            BPLAY | SPONSOR DEL CORAZON ARGENTO
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Mariano Nante
                        </p>
                    </div>
                </div>
                {/*  */}
                <div class="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/rugby-world-cup'></a>
                        <ReactPlayer
                            alt='RUGBY WORLD CUP I ZURICH I Marian & Santi'
                            className="video-grilla-works"
                            url="https://vimeo.com/361111813"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                RUGBY WORLD CUP | ZURICH
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Marian & Santi
                            </p>
                        </div>
                </div>
                {/*  */}
                
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/galicia-app'></a>
                        <ReactPlayer
                            alt='GALICIA | GALICIA APP | SANTIAGO FRANCO '
                            className="video-grilla-works"
                            url="https://vimeo.com/767430979"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                GALICIA | GALICIA APP
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Marian & Santi
                            </p>
                        </div>
                </div>

                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/dream-big'></a>
                    <ReactPlayer
                        alt='SUEÑA POTENTE I AMD I MARIAN & SANTI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/515381672"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            DREAM BIG | AMD
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Marian &amp; Santi
                        </p>
                    </div>
                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/eternal-romance'></a>
                    <ReactPlayer
                        alt='ROMANCE ETERNO I FIFA 21 I Santiago Franco'
                        className="video-grilla-talents"
                        url="https://vimeo.com/497254514"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            ETERNAL ROMANCE | FIFA 21
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Santiago Franco
                        </p>
                    </div>
                </div>

                {/* 2 */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                    <a className='container-href-videos0-talents' href='/en/artois-s-life'></a>
                    <ReactPlayer
                        alt='LA VIDA ARTOIS I STELLA ARTOIS I  Marian & Santi'
                        className="video-grilla-talents"
                        url="https://vimeo.com/685534917"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents-2'>
                        <p className="text-footer-grillas-talents-1">
                            ARTOIS'S LIFE | STELLA ARTOIS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Marian & Santi
                        </p>
                    </div>
                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/sex-education'></a>
                        <ReactPlayer
                            alt='SEX EDUCATION I NETFLIX I  Santiago Franco'
                            className="video-grilla-works"
                            url="https://vimeo.com/617028986"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                SEX EDUCATION | NETFLIX
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Santiago Franco
                            </p>
                        </div>

                    </div>
                </div>
                {/*  */}
                
                
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/jugos-del-valle'></a>
                        <ReactPlayer
                            alt='JUGOS DEL VALLE  SANTIAGO FRANCO'
                            className="video-grilla-works"
                            url="https://vimeo.com/727415690"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                JUGOS DEL VALLE
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Marian &amp; Santi
                            </p>
                        </div>
                    </div>
                    
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/brahma-diminutives'></a>
                        <ReactPlayer
                            alt='DIMINUTIVOS | BRAHMA'
                            className="video-grilla-works"
                            url="https://vimeo.com/344817236 "
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                DIMINUTIVES | BRAHMA
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Marian &amp; Santi
                            </p>
                        </div>

                    </div>
                </div>
                {/*  */}
                <div class="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/the-pianists-s-street'></a>
                        <ReactPlayer
                            alt='LA CALLE DE LOS PIANISTAS I Marian & Santi'
                            className="video-grilla-talents"
                            url="https://vimeo.com/353463725"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                THE PIANISTS'S STREET
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Marian & Santi
                            </p>
                        </div>
                </div>
                {/*  */}
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>
        <FooterSmall2 />
    </>
    )
}

