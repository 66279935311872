import React from "react";
import ReactPlayer from "react-player";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import MetaTags from "react-meta-tags";
import "./grillas-talents.css";

export const GrillaSantiagoFrancoEn = () => {
  return (
    <>
      <HeaderTexts title="SANTIAGO FRANCO" />
      <HeaderMenu />
      <div className="container-grillas-talents">
        <MetaTags>
          <title>MH | All Santiago Franco</title>
          <meta
            content="Dupla de directores de cine y publicidad representados por Mamá Húngara"
            description="Dupla de directores de cine y publicidad representados por Mamá Húngara"
          />
        </MetaTags>
        <div className="container-altura-grillas-talents">
          {/* 1 */}
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/bgh-calurosamente"
            ></a>
            <ReactPlayer
              alt="BGH | CALUROSAMENTE ARGENTINO | Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/891557091/d1030e3c5f"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />

            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                BGH | CALUROSAMENTE ARGENTINO
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/bplay-sponsor-oficial"
            ></a>
            <ReactPlayer
              alt="BPLAY | SPONSOR OFICIAL | Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/850727345"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />

            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                BPLAY | SPONSOR OFICIAL
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>

          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/suenia-potente"
            ></a>
            <ReactPlayer
              alt="SUEÑA POTENTE | AMD | Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/515381672"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                SUEÑA POTENTE | AMD
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>

          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/betwarrior"
            ></a>
            <ReactPlayer
              alt="BET WARRIOR | NO ESTÁ NADA | Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/954098255"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                BET WARRIOR | NO ESTÁ NADA
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/conmebol"
            ></a>
            <ReactPlayer
              alt="CONMEBOL | Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/878049177"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                CONMEBOL
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>

          <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/otro-deporte"
              ></a>
              <ReactPlayer
                alt="ZURICH | OTRO DEPORTE "
                className="video-grilla-works"
                url="https://vimeo.com/752541132"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents">
                <p className="text-footer-grillas-talents-1">
                  ZURICH | OTRO DEPORTE
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>
          <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/rugby-world-cup"
              ></a>
              <ReactPlayer
                alt="RUGBY WORLD CUP I ZURICH I Santiago Franco"
                className="video-grilla-works"
                url="https://vimeo.com/361111813"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents">
                <p className="text-footer-grillas-talents-1">
                  RUGBY WORLD CUP | ZURICH
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>

            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/romance-eterno"
              ></a>
              <ReactPlayer
                alt="ROMANCE ETERNO | FIFA 21 | Santiago Franco"
                className="video-grilla-talents"
                url="https://vimeo.com/497254514"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

              <div className="container-footer-grillas-talents">
                <p className="text-footer-grillas-talents-1">
                  ROMANCE ETERNO | FIFA 21
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>

            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/brahma-diminutivos"
              ></a>
              <ReactPlayer
                alt="DIMINUTIVOS | BRAHMA"
                className="video-grilla-works"
                url="https://vimeo.com/344817236 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  DIMINUTIVOS | BRAHMA
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>

            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/galicia-app"
              ></a>
              <ReactPlayer
                alt="GALICIA APP | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/767430979"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  GALICIA APP
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/supervielle"
            ></a>
            <ReactPlayer
              alt="SUPERVIELLE I POR H O POR B I Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/840224683"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />

            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                SUPERVIELLE | POR H O POR B
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>
          
          

          {/* 2 */}
          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/sex-education"
              ></a>
              <ReactPlayer
                alt="SEX EDUCATION I NETFLIX I  Santiago Franco"
                className="video-grilla-works"
                url="https://vimeo.com/617028986"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  SEX EDUCATION | NETFLIX
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/jugos-del-valle"
              ></a>
              <ReactPlayer
                alt="Jugos del VALLE I SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/727415690"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">JUGOS DEL VALLE</p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>
          </div>
          {/* 1 */}
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/la-vida-artois"
            ></a>
            <ReactPlayer
              alt="LA VIDA ARTOIS I STELLA ARTOIS I  Santiago Franco"
              className="video-grilla-talents"
              url="https://vimeo.com/685534917"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">
                LA VIDA ARTOIS | STELLA ARTOIS
              </p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>
          {/* 2 */}
          <div className="container-video-talents-2">
            
            
            <div class="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/es/la-calle-de-los-pianistas"
              ></a>
              <ReactPlayer
                alt="LA CALLE DE LOS PIANISTAS I Santiago Franco"
                className="video-grilla-talents"
                url="https://vimeo.com/353463725"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents">
                <p className="text-footer-grillas-talents-1">
                  LA CALLE DE LOS PIANISTAS
                </p>
                <p className="text-footer-grillas-talents-2">Santiago Franco</p>
              </div>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/citibanamex"
            ></a>
            <ReactPlayer
              alt="CITIBANAMEX | MARIANO & SANTIAGO"
              className="video-grilla-talents"
              url="https://vimeo.com/894116437"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">CITIBANAMEX</p>
              <p className="text-footer-grillas-talents-2">Santiago Franco</p>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
      <a
        onClick={() => {
          window.history.back();
        }}
      >
        <TextFooter title1="BACK" />
      </a>

      <FooterSmall2 />
    </>
  );
};
