import React from 'react';

import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from '../../../footers/textFooter';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '.././grillaStill.css';

// {/* JOE EKONEN  */ }
import img1 from './EDITORIAL/editorial-eze-3.jpg'
import img4 from './EDITORIAL/editorial-eze-2.jpg'
import img2 from './CRONICAS/cronicas-3.jpg'
import img3 from './FIFA22/fifa-eze-1.jpg'

export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)
export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GEze_EN = () => {
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="container-grillas-still">

                <Img50
                    src={img2}
                    href="/en/gallery-cronicas"
                    title="CRONICAS"
                />


                <Img50
                    src={img3}
                    href="/en/gallery-fifa22"
                    title="FIFA 22"
                />
                <Img50
                    src={img1}
                    href="/en/gallery-editorial"
                    title="EDITORIAL"
                />
                <Img50
                    src={img4}
                    href="/en/gallery-editorial"
                    title="EDITORIAL"
                />

            </div>
            {/*             <a href='/slider-joe'>
                <TextFooter
                    title1="+ STILLS JOE EKONEN"
                />
            </a> */}
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

