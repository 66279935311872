import React from 'react';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import { HeaderLogoMH_ES } from '../../../headers/headerLogoMH_ES';
import { HeaderMenu_ES } from '../../../headers/headerMenu_ES';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";
import img1 from '../../5_still/sisso-chouela/SPOTIFY/trap-spotify-siso-1.jpg'
import img2 from '../../5_still/sisso-chouela/HBO/siso-hbo-1.jpg'
import img3 from '../../5_still/sisso-chouela/HIPPO/hippo-siso-3.jpg'
import img4 from '../../5_still/sisso-chouela/NISSAN/nissan-siso-2.jpg'
import img5 from '../../5_still/sisso-chouela/SPOTIFY/spotify-trap-edit.jpg'
import img6 from '../../5_still/sisso-chouela/PURINA/purina-siso-4.jpg'
import '.././grillaStill.css';
import { MetaTags } from 'react-meta-tags';

export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GSisso_ES = () => {
    return (
        <>
        <MetaTags>
        <title>Mamá Húngara | Sisso Chouela</title>
        </MetaTags>
            <HeaderLogoMH_ES />
            <HeaderMenu_ES />
            <div className="container-grillas-still ">

                <Img50
                    src={img3}
                    href="/es/galeria-hippo"
                    title="HIPPO"
                />
                <Img50
                    src={img4}
                    href="/es/galeria-nissan"
                    title="NISSAN"
                />
                <Img100
                    src={img2}
                    href="/es/galeria-hbo"
                    title="HBOmax"
                />
                <Img50
                    src={img5}
                    href="/es/galeria-spotify"
                    title="TRAP | SPOTIFY"
                />
                <Img50
                    src={img6}
                    href="/es/galeria-purina"
                    title="PURINA | DOG CHOW"
                />

            </div>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

