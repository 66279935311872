import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter.js';
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import { MetaTags } from 'react-meta-tags';

// import './grillas-talents.css';

export const GrillaMartinaGalli= () => {
    return (<>
        <HeaderTexts
            title="MARTINA GALLI AGULLA"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | Todo Martina Galli Agulla</title>
                <meta
                    content="Dupla de directoras publicitarias representadas por Mamá Húngara"
                    description="Dupla de directoras publicitarias representadas por Mamá Húngara"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/lo-que-nos-hace-bien'></a>
                    <ReactPlayer
                        alt='DIARCO'
                        className="video-grilla-talents"
                        url="https://vimeo.com/886854534"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        DIARCO
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Martina Galli Agulla
                        </p>
                    </div>
                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/lo-que-nos-hace-bien'></a>
                    <ReactPlayer
                        alt='LO QUE NOS HACE BIEN | CLIGHT'
                        className="video-grilla-talents"
                        url="https://vimeo.com/803081038"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            CLIGHT | LO QUE NOS HACE BIEN
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Martina Galli Agulla
                        </p>
                    </div>
                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/sprite-love-you-hater'></a>

                    <ReactPlayer
                        alt='SPRITE | LOVE YOU HATER'
                        className="video-grilla-talents"
                        url="https://vimeo.com/703862295 "
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            SPRITE | LOVE YOU HATER
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Martina Galli Agulla
                        </p>
                    </div>

                </div>
                
                {/* 2 */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/becks-do-it-different'></a>
                        <ReactPlayer
                            alt=' BECKS HACELO DIFERENTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/703861246 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                BECKS | DO IT DIFFERENT
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Martina Galli Agulla
                            </p>
                        </div>

                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/actron-gel'></a>
                        <ReactPlayer
                            alt='ACTRON GEL / BAYER'
                            className="video-grilla-works"
                            url="https://vimeo.com/703861207"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                ACTRON GEL | BAYER
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Martina Galli Agulla
                            </p>
                        </div>

                    </div>
                </div>
                {/*  */}
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/movistar-more-gig'></a>
                    <ReactPlayer
                        alt='MOVISTAR / MAS GIGAS'
                        className="video-grilla-talents"
                        url="https://vimeo.com/703862101"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            MOVISTAR | MORE GIG
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Martina Galli Agulla
                        </p>
                    </div>
                </div>
                {/*  */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/michelob-ultra'></a>
                        <ReactPlayer
                            alt='MICHELOB ULTRA'
                            className="video-grilla-works"
                            url="https://vimeo.com/703861445"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                MICHELOB ULTRA
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Martina Galli Agulla
                            </p>
                        </div>

                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/jumbo-abandoned-products'></a>
                        <ReactPlayer
                            alt='JUMBO PRODUCTOS ABANDONADOS'
                            className="video-grilla-works"
                            url="https://vimeo.com/703861334"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                JUMBO | ABANDONED PRODUCTS
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Martina Galli Agulla
                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}

