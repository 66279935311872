import React from 'react';

import { HeaderMenu } from "../../headers/headerMenu";
import { HeaderLogoMH } from "../../headers/headerLogoMH";
import { TextFooter } from '../../footers/textFooter';
import { FooterLogoColors } from '../../footers/footerColors/footerColors';
import logoMHW from "../../img/500h/Asset_logo_blanco.png";
import { MetaTags } from 'react-meta-tags';
import './grillaStill.css';

import img1 from '../5_still/joe-ekonen/CARRUSEL AMERICANINO/americanino-joe-5.jpg'
import img2 from '../5_still/joe-ekonen/COLLAB/collab-1.jpg'
import img3 from '../5_still/joe-ekonen/MMIC/mmic-joe-1.jpg'
import img4 from '../5_still/joe-ekonen/NADIE/nadie-1.jpg'
import img5 from '../5_still/martin-sigal/1_Adversiting/5-Flow-Persiana-martin-sigal.jpg'
import img6 from '../5_still/martin-sigal/4_Landscape/landscape-martin-sigal-Ruca-Malen-1.jpg'
import img7 from '../5_still/joe-ekonen/SADAELS PARAISO/sadaels-paraiso-6.jpg'
import img8 from '../5_still/joe-ekonen/SADAELS PARAISO/sadaels-paraiso-1.jpg'
import img9 from '../5_still/joe-ekonen/CARRUSEL AMERICANINO/americanino-joe-3.jpg'
import img10 from '../5_still/martin-sigal/5_Valle_de_Uco_Peniaflor/valle-de-uco-grupo-peñaflor-martin-sigal-1.jpg'
import img11 from '../5_still/martin-sigal/1_Adversiting/1-blue-propel-martin-sigal.jpg'
import img12 from '../5_still/martin-sigal/2_Sports/Nike-martin-sigal-merge-1.jpg'
import img13 from '../5_still/martin-sigal/6_The_returned/the-returned-edit.jpg'
import img14 from '../5_still/martin-sigal/2_Sports/Sports-World-hombre-salto-martin-sigal-2.jpg'
import img15 from '../5_still/ioni-epelbaum/KOSTUME/kostume-ioni-3.jpg'
import img16 from '../5_still/ioni-epelbaum/ADIDAS/adidas-ioni-3.jpg'
import img17 from '../5_still/ioni-epelbaum/FREE/Free-ioni-3.jpg'
import img18 from '../5_still/ioni-epelbaum/WEJS/Wejs-ioni-4.jpg'
import img19 from '../5_still/ioni-epelbaum/KOSTUME/kostume-ioni-2.jpg'
import img20 from '../5_still/tatu-garcia/Fila-B/2.JPG'
import img21 from '../5_still/ioni-epelbaum/EYE/Eye-ioni-1.jpg'
import img22 from '../5_still/ioni-epelbaum/ADIDAS/adidas-ioni-4.jpg'
import img23 from '../5_still/martin-sigal/1_Adversiting/4-Aguila-americanino-martin-sigal.jpg'
import img24 from '../5_still/martin-sigal/6_The_returned/returned-martin-sigal.jpg'
import img25 from '../5_still/eze-monjo/EDITORIAL/editorial-eze-4.jpg'
import img26 from '../5_still/joe-ekonen/MMIC/mmic-joe-8.jpg'
import img27 from '../5_still/tatu-garcia/BRUGAL/BRUGAL-1.jpg'
import img28 from '../5_still/tatu-garcia/MMIC/MMIC-4.jpg'
import img29 from '../5_still/eze-monjo/EDITORIAL/editorial-eze-3.jpg'
import img30 from '../5_still/joe-ekonen/MMIC/mmic-joe-3.jpg'
import img31 from '../5_still/tatu-garcia/STELLA_ARTOIS/stella-artois-1.jpg'
import img32 from '../5_still/tatu-garcia/VOLKSWAGEN/VOLKSWAGEN-5.jpg'
import img33 from '../5_still/sisso-chouela/NISSAN/nissan-siso-1.jpg'
import img34 from '../5_still/sisso-chouela/PURINA/purina-siso-1.jpg'
import img35 from '../5_still/sisso-chouela/SPOTIFY/spotify-trap-edit.jpg'




import { HeaderLogoMH_ES } from '../../headers/headerLogoMH_ES';
import { HeaderMenu_ES } from '../../headers/headerMenu_ES';

export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)
export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GrillaStill = () => {
    return (
        <>
            <MetaTags>
                <title>Mamá Húngara | Still</title>
            </MetaTags>
            <HeaderLogoMH_ES />
            <HeaderMenu_ES />
            <div className="container-grillas-still">
                <Img50
                    src={img5}
                    href="/es/galeria-advertising"
                    title="COMMERCIAL | MARTIN SIGAL"
                />
                <Img50
                    src={img33}
                    href="/es/galeria-nissan"
                    title="NISSAN | SISSO & CHOUELA"
                />
                <Img50
                    src={img3}
                    href="/es/galeria-mmic"
                    title="MMIC | JOE EKONEN"
                />
                  <Img50
                    src={img12}
                    href="/es/galeria-sports"
                    title="SPORT | MARTIN SIGAL"
                />
                <Img50
                    src={img34}
                    href="/es/galeria-purina"
                    title="PURINA DOG CHOW | SISSO & CHOUELA"
                />
                <Img50
                    src={img20}
                    href="/es/galeria-fila"
                    title="FILA | TATU GARCIA "
                />
                <Img50
                    src={img16}
                    href="/es/galeria-adidas"
                    title="ADIDAS |  IONI EPELBAUM "
                />
                 <Img50
                    src={img15}
                    href="/es/galeria-kostume"
                    title="KOSTUME | IONI EPELBAUM "
                />
                 <Img50
                    src={img27}
                    href="/es/galeria-brugal"
                    title="BRUGAL | TATU GARCIA "
                />
                <Img50
                    src={img2}
                    href="/es/galeria-collab"
                    title="WHAT IS LOVE | COLLAB |  JOE EKONEN "
                />
                 <Img50
                    src={img4}
                    href="/es/galeria-nadie"
                    title="NADIE | JOE EKONEN"
                />
                 <Img50
                    src={img6}
                    href="/es/galeria-landscape"
                    title="LANDSCAPE | MARTIN SIGAL"
                />
                  <Img50
                    src={img35}
                    href="/es/galeria-purina"
                    title="TRAP SPOTIFY | SISSO & CHOUELA"
                />
                  <Img50
                    src={img25}
                    href="/es/galeria-editorial"
                    title="EDITORIAL | EZE MONJO"
                />






                <Img50
                    src={img1}
                    href="/es/galeria-americanino"
                    title="THE REASON IS FREEDOM | AMERICANINO | JOE EKONEN"
                />
                
                <Img50
                    src={img3}
                    href="/es/galeria-mmic"
                    title="MMIC | JOE EKONEN"
                />
               

               
                <Img50
                    src={img7}
                    href="/es/galeria-sadaels-paraiso"
                    title="PARAÍSO | SADAELS |  JOE EKONEN"
                />
                <Img50
                    src={img8}
                    href="/es/galeria-sadaels-paraiso"
                    title="PARAÍSO | SADAELS |  JOE EKONEN"
                />
                <Img50
                    src={img9}
                    href="/es/galeria-americanino"
                    title="AMERICANINO"
                />
                <Img50
                    src={img10}
                    href="/es/galeria-valle-de-uco"
                    title="VALLE DE UCO | MARTIN SIGAL"
                />
                <Img50
                    src={img11}
                    href="/es/galeria-advertising"
                    title="COMMERCIAL | MARTIN SIGAL"
                />
               
                <Img50
                    src={img13}
                    href="/es/galeria-the-returned"
                    title="THE RETURNED | MARTIN SIGAL "
                />
                <Img50
                    src={img14}
                    href="/es/galeria-sports"
                    title="SPORT | MARTIN SIGAL"
                />
               
                
                <Img50
                    src={img17}
                    href="/es/galeria-free"
                    title="FREE |  IONI EPELBAUM "
                />
                <Img50
                    src={img18}
                    href="/es/galeria-wejs"
                    title="WEJS |  IONI EPELBAUM "
                />
                <Img50
                    src={img19}
                    href="/es/galeria-kostume"
                    title="KOSTUME | IONI EPELBAUM "
                />
                
                <Img50
                    src={img21}
                    href="/es/galeria-eye"
                    title="EYE | IONI EPELBAUM "
                />
                <Img50
                    src={img22}
                    href="/es/galeria-adidas"
                    title="ADIDAS |  IONI EPELBAUM "
                />
                <Img50
                    src={img23}
                    href="/es/galeria-advertising"
                    title="COMMERCIAL | MARTIN SIGAL"
                />
                <Img50
                    src={img24}
                    href="/es/galeria-the-returned"
                    title="THE RETURNED | MARTIN SIGAL "
                />
              
                <Img50
                    src={img26}
                    href="/es/galeria-mmic"
                    title="MMIC | JOE EKONEN "
                />
               
              <Img50
                    src={img28}
                    href="/es/galeria-mmic-tatu"
                    title="MMIC | TATU GARCIA "
                />
                {/*   <Img50
                    src={img29}
                    href="/es/galeria-editorial"
                    title="EDITORIAL | EZE MONJO"
                />
                <Img50
                    src={img30}
                    href="/es/galeria-mmic"
                    title="MMIC |  JOE EKONEN "
                />
                <Img50
                    src={img31}
                    href="/es/galeria-betunoire"
                    title="BETUNOIRE | STELLA ARTOIS | JOE EKONEN"
                />
                <Img50
                    src={img32}
                    href="/es/galeria-volkswagen"
                    title="VOLKSWAGEN | TATU GARCIA "
                /> */}


         {/* este es el ordern principal hago un backup por las dudas  */}
                {/* 
<Img50
                    src={img1}
                    href="/es/galeria-americanino"
                    title="THE REASON IS FREEDOM | AMERICANINO | JOE EKONEN"
                />
                <Img50
                    src={img2}
                    href="/es/galeria-collab"
                    title="WHAT IS LOVE | COLLAB |  JOE EKONEN "
                />
                <Img50
                    src={img3}
                    href="/es/galeria-mmic"
                    title="MMIC | JOE EKONEN"
                />
                <Img50
                    src={img4}
                    href="/es/galeria-nadie"
                    title="NADIE | JOE EKONEN"
                />
                <Img50
                    src={img5}
                    href="/es/galeria-advertising"
                    title="COMMERCIAL | MARTIN SIGAL"
                />
                <Img50
                    src={img6}
                    href="/es/galeria-landscape"
                    title="LANDSCAPE | MARTIN SIGAL"
                />
                <Img50
                    src={img7}
                    href="/es/galeria-sadaels-paraiso"
                    title="PARAÍSO | SADAELS |  JOE EKONEN"
                />
                <Img50
                    src={img8}
                    href="/es/galeria-sadaels-paraiso"
                    title="PARAÍSO | SADAELS |  JOE EKONEN"
                />
                <Img50
                    src={img9}
                    href="/es/galeria-americanino"
                    title="AMERICANINO"
                />
                <Img50
                    src={img10}
                    href="/es/galeria-valle-de-uco"
                    title="VALLE DE UCO | MARTIN SIGAL"
                />
                <Img50
                    src={img11}
                    href="/es/galeria-advertising"
                    title="COMMERCIAL | MARTIN SIGAL"
                />
                <Img50
                    src={img12}
                    href="/es/galeria-sports"
                    title="SPORT | MARTIN SIGAL"
                />
                <Img50
                    src={img13}
                    href="/es/galeria-the-returned"
                    title="THE RETURNED | MARTIN SIGAL "
                />
                <Img50
                    src={img14}
                    href="/es/galeria-sports"
                    title="SPORT | MARTIN SIGAL"
                />
                <Img50
                    src={img15}
                    href="/es/galeria-kostume"
                    title="KOSTUME | IONI EPELBAUM "
                />
                <Img50
                    src={img16}
                    href="/es/galeria-adidas"
                    title="ADIDAS |  IONI EPELBAUM "
                />
                <Img50
                    src={img17}
                    href="/es/galeria-free"
                    title="FREE |  IONI EPELBAUM "
                />
                <Img50
                    src={img18}
                    href="/es/galeria-wejs"
                    title="WEJS |  IONI EPELBAUM "
                />
                <Img50
                    src={img19}
                    href="/es/galeria-kostume"
                    title="KOSTUME | IONI EPELBAUM "
                />
                <Img50
                    src={img20}
                    href="/es/galeria-fila"
                    title="FILA | TATU GARCIA "
                />
                <Img50
                    src={img21}
                    href="/es/galeria-eye"
                    title="EYE | IONI EPELBAUM "
                />
                <Img50
                    src={img22}
                    href="/es/galeria-adidas"
                    title="ADIDAS |  IONI EPELBAUM "
                />
                <Img50
                    src={img23}
                    href="/es/galeria-advertising"
                    title="COMMERCIAL | MARTIN SIGAL"
                />
                <Img50
                    src={img24}
                    href="/es/galeria-the-returned"
                    title="THE RETURNED | MARTIN SIGAL "
                />
                <Img50
                    src={img25}
                    href="/es/galeria-editorial"
                    title="EDITORIAL | EZE MONJO"
                />
                <Img50
                    src={img26}
                    href="/es/galeria-mmic"
                    title="MMIC | JOE EKONEN "
                />
                <Img50
                    src={img27}
                    href="/es/galeria-brugal"
                    title="BRUGAL | TATU GARCIA "
                />
                <Img50
                    src={img28}
                    href="/es/galeria-mmic-tatu"
                    title="MMIC | TATU GARCIA "
                />
                <Img50
                    src={img29}
                    href="/es/galeria-editorial"
                    title="EDITORIAL | EZE MONJO"
                />
                <Img50
                    src={img30}
                    href="/es/galeria-mmic"
                    title="MMIC |  JOE EKONEN "
                />
                <Img50
                    src={img31}
                    href="/es/galeria-betunoire"
                    title="BETUNOIRE | STELLA ARTOIS | JOE EKONEN"
                />
                <Img50
                    src={img32}
                    href="/es/galeria-volkswagen"
                    title="VOLKSWAGEN | TATU GARCIA "
                />
*/}

       












            </div>
            {/*        <a href='/slider-tatu'>
                <TextFooter
                    title1="+ STILLS TATU GARCIA"
                />
            </a> */}
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}
