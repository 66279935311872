import React from 'react';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";
import '.././grillaStill.css';

import img1 from './FREE/Free-ioni-3.jpg'
import img2 from './ADIDAS/adidas-ioni-3.jpg'
import img3 from './WEJS/Wejs-ioni-4.jpg'
import img4 from './EYE/Eye-ioni-1.jpg'
import img5 from './KOSTUME/kostume-ioni-3.jpg'
import { MetaTags } from 'react-meta-tags';

import { HeaderLogoMH_ES } from '../../../headers/headerLogoMH_ES';
import { HeaderMenu_ES } from '../../../headers/headerMenu_ES';

export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)
export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GIoni_ES = () => {
    return (
        <>
        <MetaTags>
        <title>Mamá Húngara | Ioni Epelbaum</title>
        </MetaTags>
            <HeaderLogoMH_ES />
            <HeaderMenu_ES />
            <div className="container-grillas-still">
                <Img50
                    src={img1}
                    href="/es/galeria-free"
                    title="FREE"
                />
                <Img50
                    src={img3}
                    href="/es/galeria-wejs"
                    title="WEJS"
                />
                <Img50
                    src={img4}
                    href="/es/galeria-eye"
                    title="EYE"
                />
                <Img50
                    src={img2}
                    href="/es/galeria-adidas"
                    title="ADIDAS"
                />
                <Img100
                    src={img5}
                    href="/es/galeria-kostume"
                    title="KOSTUME"
                />
            </div>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

