import React from "react";
import ReactPlayer from "react-player";

import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import video from "./videos/zurich.mp4";
import { Pagination, Navigation, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
// import './styleSlideimage2.css'
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import MetaTags from "react-meta-tags";

function DragFedeGR_EN() {
  return (
    <>
      <HeaderTexts title="FEDE GARCIA RICO" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>Fede García Rico</title>
          <meta
            content="Director publicitario representado por Mamá Húngara. Premiado como Nuevo Talento en Diente 2022"
            description="Director publicitario representado por Mamá Húngara. Premiado como Nuevo Talento en Diente 2022"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          loop={true}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          navigation
        >
                    <SwiperSlide
            onClick={() => window.open("/en/garpamos", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BRAHMA | NO LA GARPAMOS | FEDE GARCIA RICO"
                className="react-player-video"
                url="https://vimeo.com/992156148"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BRAHMA | NO LA GARPAMOS |"
              title2="Fede Garcia Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/consumo", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BRAHMA | CONSUMO | FEDE GARCIA RICO"
                className="react-player-video"
                url="https://vimeo.com/992155726/5fa27728ba"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BRAHMA | CONSUMO |"
              title2="Fede Garcia Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/gatorade", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="GATORADE | MATCH SAVER | Fede García Rico"
                className="react-player-video"
                url="https://vimeo.com/940878425"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GATORADE | MATCH SAVER |"
              title2="Fede Garcia Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/la-evolucion-del-lojack", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="STRIX | LA EVOLUCION DEL LOJACK | FEDE GARCIA RICO"
                className="react-player-video"
                url="https://vimeo.com/756236637"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="STRIX | LA EVOLUCION DEL LoJACK | "
              title2="Fede García Rico & Martin Sigal"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/feeding-dreams", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="UIFRA | ALIMENTANDO SUEÑOS | Fede Garcia Rico"
                className="react-player-video"
                url="https://vimeo.com/755986261"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="UIFRA | FEEDING DREAMS | "
              title2="Fede Garcia Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/love-save-us", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="EL AMOR NOS SALVA | ALMA MORA | Fede Garcia Rico"
                className="react-player-video"
                url="https://vimeo.com/489073992"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="ALMA MORA | LOVE SAVE US | "
              title2="Fede Garcia Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => window.open("/es/burger-king-netflix", "_self")}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt='BURGER KING | NETFLIX + "THE WITCHER" | FEDE GARCIA RICO'
                className="react-player-video"
                url="https://vimeo.com/846028180"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BURGER KING | NETFLIX + 'THE WITCHER' | "
              title2="Fede Garcia Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/idolas", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="YPF | IDOLAS | FEDE GARCIA RICO"
                className="react-player-video"
                url="https://vimeo.com/848844305"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="YPF | IDOLAS | " title2="Fede Garcia Rico" />
          </SwiperSlide>

          

          <SwiperSlide className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt="208 | PEUGEOT |"
                className="react-player-video"
                url="https://vimeo.com/475924465"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="208 | PEUGEOT | " title2="Fede Garcia Rico" />
          </SwiperSlide>
          
          
          
          <SwiperSlide
            onClick={() => window.open("/en/el-esteco", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="El Esteco | Fede García Rico & Martin Sigal"
                className="react-player-video"
                url="https://vimeo.com/735212967"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="EL ESTECO | "
              title2="Fede García Rico & Martin Sigal"
            />
          </SwiperSlide>
          {/* video 4 */}
          <SwiperSlide
            onClick={() => window.open("/en/molinos", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="120 AÑOS MOLINOS"
                className="react-player-video"
                url="https://vimeo.com/780534111"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="120 AÑOS MOLINOS | "
              title2="Fede García Rico"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/bees", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BEES | DATOS DE ALMACEN | "
                className="react-player-video"
                url="https://vimeo.com/654131987"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BEES | DATOS DE ALMACEN | "
              title2="Fede García Rico"
            />
            <a className="more" href="/en/all-fede-garcia-rico">
              More
            </a>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
        <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="GATORADE | MATCH SAVER | Fede García Rico"
                className="react-player-video"
                url="https://vimeo.com/940878425"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GATORADE | MATCH SAVER |"
              title2="Fede Garcia Rico"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="STRIX | LA EVOLUCION DEL LOJACK | FEDE GARCIA RICO"
                className="react-player-video"
                url="https://vimeo.com/756236637"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="STRIX | LA EVOLUCION DEL LoJACK "
              title2="Fede Garcia Rico"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="UIFRA | ALIMENTANDO SUEÑOS | Fede Garcia Rico"
                className="react-player-video"
                url="https://vimeo.com/755986261"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" UIFRA | FEEDING DREAMS "
              title2="Fede Garcia Rico"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="EL AMOR NOS SALVA | ALMA MORA | Fede Garcia Rico"
                className="react-player-video"
                url="https://vimeo.com/489073992"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="ALMA MORA | LOVE SAVE US | "
              title2="Fede Garcia Rico"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='BURGER KING | NETFLIX + "THE WITCHER" | FEDE GARCIA RICO'
                className="react-player-video"
                url="https://vimeo.com/846028180"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BURGER KING | NETFLIX + 'THE WITCHER' | "
              title2="Fede Garcia Rico"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="El Esteco | Fede García Rico & Martin Sigal"
                className="react-player-video"
                url="https://vimeo.com/435130272"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="EL ESTECO"
              title2="Fede García Rico & Martin Sigal "
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="120 AÑOS MOLINOS"
                className="react-player-video"
                url="https://vimeo.com/780534111"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="120 AÑOS MOLINOS" title2="Fede Garcia Rico" />
          </SplideSlide>

          
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}

export default DragFedeGR_EN;
