import React, { useContext, useEffect, useState } from "react";

import { HeaderMenuTalents } from "../../headers/headerTalents/headerMenuTalents";
import { TextFooter } from "../../footers/textFooter";
import { FooterLogoColors } from "../../footers/footerColors/footerColors";
import "./talent.css";
import { TitleVideo } from "./titleVideo";
import logoMHV from "../../img/500h/Asset_logo_violet.png";
import { HeaderLogoMH } from "../../headers/headerLogoMH";
import { HeaderLogoMH_ES } from "../../headers/headerLogoMH_ES";
import MetaTags from "react-meta-tags";
import { DirectorsContext } from "../../context/directorsContext";

export const Talent = () => {
  const {nation} = useContext(DirectorsContext)

  return (
    <>
      <MetaTags>
        <title>Mamá Húngara | Talents</title>
        <meta
          content="Talentos Mamá Húngara"
          description="Talentos Mamá Húngara"
        />
      </MetaTags>
      <HeaderLogoMH_ES />
      <HeaderMenuTalents />
      <TitleVideo/>
      <TextFooter title1="DIRECTORS" country={nation}/>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
};
