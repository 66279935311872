import React from "react";
import MouseApp_blanco from "../../../../mouse/mouseApp_blanco.js";
import logoMHB from "../../../../img/500h/Asset_logo_blanco.png";
import '../videoIndividual.css';
import '../../../../footers/footerColors/footerColors.css';
import {FooterLogoColors} from '../../../../footers/footerColors/footerColors';
import { HeaderMenu_ES } from "../../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../../headers/headerLogoMH_ES";

function Supervielle(props) {

    return (
        <>        
            <HeaderLogoMH_ES />
            <HeaderMenu_ES/>
            <div className="player-wrapper">
                <MouseApp_blanco
                    alt="SUPERVIELLE | POR H O POR B | MARIANO NANTE"
                    urlVideo='https://vimeo.com/840224683'
                />
                 <FooterLogoColors LogoColor={logoMHB}/>
            </div>
        </>
    )
}
export default Supervielle