import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from "./videos/zurich.mp4";
import "swiper/css";
import "swiper/css/bundle";
import "./styleSlideimage2.css";
import { Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import MetaTags from "react-meta-tags";
import "@splidejs/react-splide/css";
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from "react-keyboard-event-handler";

function KeaneShaw() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES title="KEANE SHAW" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>KEANE SHAW</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => window.open("/es/adidas-real-madrid", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="ADIDAS | PARLEY & REAL MADRID | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/886896115"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ADIDAS | PARLEY & REAL MADRID"
              title2="KEANE SHAW"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/fifa-juventus", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt='FIFA 23 | JUVENTUS | KEANE SHAW'
                className="react-player-video"
                url="https://vimeo.com/886895437"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="FIFA 23 | JUVENTUS "
              title2="KEANE SHAW"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/puma-manchester", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PUMA | MANCHESTER | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/886894492"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PUMA | MANCHESTER"
              title2="KEANE SHAW"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/volleyball-championship", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="VOLLEYBALL WORLD CHAMPIONSHIP | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/883262366"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="VOLLEYBALL WORLD CHAMPIONSHIP"
              title2="KEANE SHAW"
            />
          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => window.open("/es/tnt-sports", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="TNT SPORTS | THIS ISN’T JUST SPORT, THIS IS EVERYTHING | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/883264185"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="TNT SPORTS | THIS ISN'T JUST SPORT, THIS IS EVERYTHING"
              title2="KEANE SHAW"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/champion-hoodie", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
            <ReactPlayer
                alt="CHAMPION | INVENTED THE HOODIE | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/883261245"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHAMPION | INVENTED THE HOODIE "
              title2="KEANE SHAW"
            />
          </SwiperSlide>
          {/* video 4 */}
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"slide","perPage": 1, "drag": "free" }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NIKE | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/883259892"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter title1="NIKE" title2="KEANE SHAW" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
            <ReactPlayer
                alt='ADIDAS | PARLEY & REAL MADRID | KEANE SHAW'
                className="react-player-video"
                url="https://vimeo.com/886896115"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ADIDAS | PARLEY & REAL MADRID"
              title2="KEANE SHAW"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
            <ReactPlayer
                alt='FIFA 23 | JUVENTUS | KEANE SHAW'
                className="react-player-video"
                url="https://vimeo.com/886895437"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="FIFA 23 | JUVENTUS "
              title2="KEANE SHAW"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
            <ReactPlayer
                alt="PUMA | MANCHESTER | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/886894492"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PUMA | MANCHESTER"
              title2="KEANE SHAW"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
            <ReactPlayer
                alt='VOLLEYBALL WORLD CHAMPIONSHIP | KEANE SHAW'
                className="react-player-video"
                url="https://vimeo.com/883262366"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="VOLLEYBALL WORLD CHAMPIONSHIP"
              title2="KEANE SHAW"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
            <ReactPlayer
                alt="TNT SPORTS | THIS ISN'T JUST SPORT, THIS IS EVERYTHING"
                className="react-player-video"
                url="https://vimeo.com/883264185"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="TNT SPORTS | THIS ISN'T JUST SPORT, THIS IS EVERYTHING"
              title2="KEANE SHAW"
            />
            <a className="more" href="/es/todo-hernan-corera">
              More
            </a>
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
            <ReactPlayer
                alt="CHAMPION | INVENTED THE HOODIE | KEANE SHAW"
                className="react-player-video"
                url="https://vimeo.com/883261245"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHAMPION | INVENTED THE HOODIE "
              title2="KEANE SHAW"
            />
            <a className="more" href="/es/todo-hernan-corera">
              More
            </a>
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}

export default KeaneShaw;
