import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter.js';
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
import './grillas-talents.css';

export const GrillaMarianoNante = () => {
    return (<>
        <HeaderTexts
            title="MARIANO NANTE"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | Todo Mariano Nante</title>
                <meta
                    content="Dupla de directores de cine y publicidad representados por Mamá Húngara"
                    description="Dupla de directores de cine y publicidad representados por Mamá Húngara"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/personal-pay-esta-bueno-compartirlo'></a>
                    <ReactPlayer
                        alt='PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE'
                        className="video-grilla-talents"
                        url="https://vimeo.com/935527550"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        PERSONAL PAY | ESTA BUENO COMPARTIRLO
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Mariano Nante
                        </p>
                    </div>

                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/suenia-potente'></a>
                    <ReactPlayer
                        alt='SUEÑA POTENTE | AMD | Mariano Nante'
                        className="video-grilla-talents"
                        url="https://vimeo.com/515381672"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            SUEÑA POTENTE | AMD
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Mariano Nante
                        </p>
                    </div>
                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/bplay'></a>
                    <ReactPlayer
                        alt='BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE '
                        className="video-grilla-works"
                        url="https://vimeo.com/761431757"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            BPLAY | SPONSOR DEL CORAZON ARGENTO
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Mariano Nante
                        </p>
                    </div>
                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/supervielle'></a>
                    <ReactPlayer
                        alt='SUPERVIELLE I POR H O POR B I Mariano Nante'
                        className="video-grilla-talents"
                        url="https://vimeo.com/840224683"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        SUPERVIELLE | POR H O POR B
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Mariano Nante
                        </p>
                    </div>

                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/while-you-live'></a>
                    <ReactPlayer
                        alt='HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE'
                        className="video-grilla-talents"
                        url="https://vimeo.com/935937413"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        HERA HYESANG PARK | WHILE YOU LIVE
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Mariano Nante
                        </p>
                    </div>

                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/citibanamex'></a>
                    <ReactPlayer
                        alt='CITIBANAMEX | MARIANO & SANTIAGO'
                        className="video-grilla-talents"
                        url="https://vimeo.com/894116437"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        CITIBANAMEX | MARIANO & SANTIAGO
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Mariano Nante
                        </p>
                    </div>

                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/sellers'></a>
                    <ReactPlayer
                        alt='MERCADO PAGO | SELLERS | Mariano Nante'
                        className="video-grilla-talents"
                        url="https://vimeo.com/940933655"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        MERCADO PAGO | SELLERS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Mariano Nante
                        </p>
                    </div>

                </div>
                
                <div class="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/rugby-world-cup'></a>
                        <ReactPlayer
                            alt='RUGBY WORLD CUP I ZURICH I Mariano Nante'
                            className="video-grilla-works"
                            url="https://vimeo.com/361111813"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                RUGBY WORLD CUP | ZURICH
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Mariano Nante
                            </p>
                        </div>
                </div>
                 <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/galicia-app'></a>
                        <ReactPlayer
                            alt='GALICIA | GALICIA APP | Mariano Nante '
                            className="video-grilla-works"
                            url="https://vimeo.com/767430979"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                GALICIA | GALICIA APP
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Mariano Nante
                            </p>
                        </div>
                </div>
                {/* 2 */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/es/romance-eterno'></a>
                    <ReactPlayer
                        alt='ROMANCE ETERNO | FIFA 21 | Mariano Nante'
                        className="video-grilla-talents"
                        url="https://vimeo.com/497254514"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />

                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            ROMANCE ETERNO | FIFA 21
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Mariano Nante
                        </p>
                    </div>

                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/es/sex-education'></a>
                        <ReactPlayer
                            alt='SEX EDUCATION I NETFLIX I  Mariano Nante'
                            className="video-grilla-works"
                            url="https://vimeo.com/617028986"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                SEX EDUCATION | NETFLIX
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Mariano Nante
                            </p>
                        </div>

                    </div>
                </div>
                                
                {/*  */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/es/jugos-del-valle'></a>
                        <ReactPlayer
                            alt='Jugos del VALLE I Mariano Nante'
                            className="video-grilla-works"
                            url="https://vimeo.com/727415690"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                JUGOS DEL VALLE
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Mariano Nante
                            </p>
                        </div>

                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/es/brahma-diminutivos'></a>
                        <ReactPlayer
                            alt='DIMINUTIVOS | BRAHMA'
                            className="video-grilla-works"
                            url="https://vimeo.com/344817236 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                DIMINUTIVOS | BRAHMA
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                            Mariano Nante
                            </p>
                        </div>

                    </div>
                </div>
                 {/* 1 */}
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/la-vida-artois'></a>
                    <ReactPlayer
                        alt='LA VIDA ARTOIS I STELLA ARTOIS I  Mariano Nante'
                        className="video-grilla-talents"
                        url="https://vimeo.com/685534917"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents-2'>
                        <p className="text-footer-grillas-talents-1">
                            LA VIDA ARTOIS | STELLA ARTOIS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Mariano Nante
                        </p>
                    </div>
                </div>
                {/* 2 */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/es/rugby-world-cup'></a>
                        <ReactPlayer
                            alt='RUGBY WORLD CUP I ZURICH I Mariano Nante'
                            className="video-grilla-works"
                            url="https://vimeo.com/361111813"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                RUGBY WORLD CUP | ZURICH
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Mariano Nante
                            </p>
                        </div>

                    </div>
                    <div class="container-video-talents-50">
                        <a className='container-href-videos0-talents' href='/es/la-calle-de-los-pianistas'></a>
                        <ReactPlayer
                            alt='LA CALLE DE LOS PIANISTAS I Mariano Nante'
                            className="video-grilla-talents"
                            url="https://vimeo.com/353463725"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents'>
                            <p className="text-footer-grillas-talents-1">
                                LA CALLE DE LOS PIANISTAS
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Mariano Nante
                            </p>
                        </div>
                    </div>
                    
                </div>
                
                {/*  */}
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}

