import React, { useContext } from 'react';

import { HeaderMenuTalents } from '../../headers/headerTalents/headerMenuTalents';
import { TextFooter } from '../../footers/textFooter';
import { FooterLogoColors } from '../../footers/footerColors/footerColors';
// import './talent.css';
import { TitleVideo, TitleVideo_Talents_EN } from './titleVideo';
import logoMHV from "../../img/500h/Asset_logo_violet.png";
import { HeaderLogoMH } from '../../headers/headerLogoMH';
import { HeaderMenuTalents_EN } from '../../headers/headerTalents/headerMenuTalents_EN';
import { DirectorsContext } from '../../context/directorsContext';


export const Talent_EN = () => {
    const {nation} = useContext(DirectorsContext)
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenuTalents_EN />
            <TitleVideo_Talents_EN />
            <TextFooter
                title1="DIRECTORS" country={nation}
            />
            <FooterLogoColors
                LogoColor={logoMHV}
            />
        </>
    )
}

