import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter.js';
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
import './grillas-talents.css';

export const GrillaKeaneShaw = () => {
    return (<>
        <HeaderTexts
            title="Keane Shaw"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | Todo Keane Shaw</title>
                <meta
                    content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                    description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/adidas-real-madrid'></a>
                    <ReactPlayer
                        alt='ADIDAS | PARLEY & REAL MADRID | KEANE SHAW'
                        className="video-grilla-talents"
                        url="https://vimeo.com/886896115"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        ADIDAS | PARLEY & REAL MADRID
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Keane Shaw
                        </p>
                    </div>

                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/fifa-juventus'></a>
                    <ReactPlayer
                        alt='FIFA 23 | JUVENTUS | KEANE SHAW'
                        className="video-grilla-talents"
                        url="https://vimeo.com/886895437"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        FIFA 23 | JUVENTUS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Keane Shaw
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/champion-hoodie'></a>
                    <ReactPlayer
                        alt='CHAMPION | INVENTED THE HOODIE | KEANE SHAW'
                        className="video-grilla-talents"
                        url="https://vimeo.com/883261245"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        CHAMPION | INVENTED THE HOODIE
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Keane Shaw
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/volleyball-championship'></a>
                    <ReactPlayer
                        alt='VOLLEYBALL WORLD CHAMPIONSHIP | KEANE SHAW'
                        className="video-grilla-talents"
                        url="https://vimeo.com/883262366"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        VOLLEYBALL WORLD CHAMPIONSHIP
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Keane Shaw
                        </p>
                    </div>

                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/puma-manchester'></a>
                    <ReactPlayer
                        alt="PUMA | MANCHESTER | KEANE SHAW"
                        className="video-grilla-talents"
                        url="https://vimeo.com/886894492"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        PUMA | MANCHESTER
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Keane Shaw
                        </p>
                    </div>
                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/tnt-sports'></a>
                    <ReactPlayer
                       alt="TNT SPORTS | THIS ISN'T JUST SPORT, THIS IS EVERYTHING | KEANE SHAW"
                        className="video-grilla-talents"
                        url="https://vimeo.com/883264185"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        TNT SPORTS | THIS ISN'T JUST SPORT, THIS IS EVERYTHING
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Keane Shaw
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}