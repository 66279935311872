import React, { useState } from "react";
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from '../../../footers/textFooter.js'
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import Mouse3 from "../../../mouse/Mouse3.js";
import { Pagination, Navigation, Mousewheel } from "swiper";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from './videos/zurich.mp4'
import "swiper/css";
import "swiper/css/bundle";
import './styleSlideimage2.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import MetaTags from 'react-meta-tags';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import '@splidejs/react-splide/css';

function DiegoFried() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts
        title=" DIEGO FRIED "
      />

      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>Diego Fried</title>
          <meta
            content="Director de cine y publicidad. Representado por Mamá Húngara."
            description="Director de cine y publicidad. Representado por Mamá Húngara."
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={['left']}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className='cursor-none container-swiper-home container-slider-photographer-still'
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >

          {/* video 1 */}

          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/purina', '_self'))}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PURINA | DIEGO FRIED "
                className="react-player-video"
                url="https://vimeo.com/825976294"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PURINA | "
              title2="Diego Fried"
            />
          </SwiperSlide>
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/kelloggs-pop-tarts', '_self'))}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="KELLOGG'S | POP-TARTS | DIEGO FRIED "
                className="react-player-video"
                url="https://vimeo.com/769897032"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="KELLOGG'S | POP-TARTS | "
              title2="Diego Fried"
            />
          </SwiperSlide>
          
          {/* video 1 */}
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/la-fiesta-silenciosa', '_self'))}
          >

            <div className="player-wrapper">
              <ReactPlayer
                alt='LA FIESTA SILENCIOSA I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396142179"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="LA FIESTA SILENCIOSA | "
              title2="Diego Fried"
            />

          </SwiperSlide>

          {/* video 2 */}
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/purina-dog-chow', '_self'))}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt='PURINA DOG CHOW | '
                className="react-player-video"
                url="https://vimeo.com/752245982"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PURINA DOG CHOW |"
              title2="Diego Fried"
            />
          </SwiperSlide>
          {/* video 3 */}
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/challenge-gillette', '_self'))}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt='CHALLENGE I GILLETTE I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396178205"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHALLENGE | GILLETTE |"
              title2="Diego Fried"
            />
          </SwiperSlide>
          {/* video 4*/}
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/love-story-ripley', '_self'))}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt='LOVE STORY I RIPLEY I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396445722"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="LOVE STORY | RIPLEY |"
              title2="Diego Fried"
            />
          </SwiperSlide>
          {/* video 5 */}
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => (window.open('/es/cereal-mix', '_self'))}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt='CEREAL MIX I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396434883"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" CEREAL MIX | "
              title2="Diego Fried"
            />
            <a className="more" href="/es/todo-diego-fried">More</a>

          </SwiperSlide>
        </Swiper>
      </div>
      <FooterLogoColors
        LogoColor={logoMHV}
      />
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide
          // drag={true} 
          data-splide='{"type":"loop","perPage":1 }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='LA FIESTA SILENCIOSA I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396142179"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="LA FIESTA SILENCIOSA "
              title2="Diego Fried"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='PURINA DOG CHOW | '
                className="react-player-video"
                url="https://vimeo.com/752245982"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PURINA DOG CHOW "
              title2="Diego Fried"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='CHALLENGE I GILLETTE I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396178205"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHALLENGE | GILLETTE "
              title2="Diego Fried"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='LOVE STORY I RIPLEY I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396445722"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="LOVE STORY | RIPLEY "
              title2="Diego Fried"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='CEREAL MIX I DIEGO FRIED'
                className="react-player-video"
                url="https://vimeo.com/396434883"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" CEREAL MIX  "
              title2="Diego Fried"
            />
            <a className="more" href="/es/todo-diego-fried">More</a>
          </SplideSlide>

        </Splide>
      </div>
    </>
  )
}

export default DiegoFried