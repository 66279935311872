import React from "react";
import ReactPlayer from "react-player";
import { TextFooter } from "../../../footers/textFooter";
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from "../../../headers/headerText";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import MetaTags from "react-meta-tags";

// import './grillas-talents.css';

export const GrillaGonzo_EN = () => {
  return (
    <>
      <HeaderTexts title="GONZO LLORENTE" />
      <HeaderMenu />
      <div className="container-grillas-talents">
        <MetaTags>
          <title>All Gonzo Llorente</title>
          <meta
            content="Director de cine y publicidad. Representado por Mamá Húngara"
            description="Director de cine y publicidad. Representado por Mamá Húngara"
          />
        </MetaTags>
        <div className="container-altura-grillas-talents">
          <a
            className="container-href-videos0-talents"
            href="/en/personal-conexion"
          ></a>
          {/* 1 */}
          <div class="container-video-talents-1">
            <ReactPlayer
              alt="AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU |"
              className="video-grilla-talents"
              url="https://vimeo.com/710488571"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU
              </p>
              <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/big-groom"
            ></a>
            <ReactPlayer
              alt="BIG GROOM ENERGY I MANSCAPED I GONZO LLORENTE"
              className="video-grilla-talents"
              url="https://vimeo.com/744617420"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                BIG GROOM ENERGY | MANSCAPED
              </p>
              <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <ReactPlayer
              alt="PERSONAL | CONEXIÓN TOTAL | GONZO LLORENTE"
              className="video-grilla-talents"
              url="https://vimeo.com/888108453"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                PERSONAL | CONEXIÓN TOTAL
              </p>
              <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-50">
            <a
              className="container-href-videos0-talents"
              href="/en/uber-boxer"
            ></a>
            <ReactPlayer
              alt="UBER I BOXER I GONZO LLORENTE"
              className="video-grilla-works"
              url="https://vimeo.com/710392513"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">UBER | BOXER</p>
              <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
            </div>
          </div>
          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/en/your-life"
              ></a>
              <ReactPlayer
                alt="HYUNDAI"
                className="video-grilla-works"
                url="https://vimeo.com/710406194"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  HYUNDAI | YOUR LIFE
                </p>
                <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
              </div>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/del-cine-a-tu-casa"
            ></a>
            <ReactPlayer
              alt="HBOMAX | DEL CINE A TU CASA |"
              className="video-grilla-talents"
              url="https://vimeo.com/806073285"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                HBOMAX | DEL CINE A TU CASA
              </p>
              <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/jealousy"
            ></a>
            <ReactPlayer
              alt='COCA COLA I JEALOUSY I GONZO LLORENTE'
              className="video-grilla-talents"
              url="https://vimeo.com/710479842"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                COCA COLA | JEALOUSY
              </p>
              <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
            </div>
          </div>
          {/* 2 */}

          {/*  */}

          {/*  */}
          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/en/nivea-beauty"
              ></a>
              <ReactPlayer
                alt="NIVEA I  BEAUTY I GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/710391880"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">NIVEA | BEAUTY</p>
                <p className="text-footer-grillas-talents-2">Gonzo Llorente</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        onClick={() => {
          window.history.back();
        }}
      >
        <TextFooter title1="BACK" />
      </a>

      <FooterSmall2 />
    </>
  );
};
