import React, { useState } from "react";
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from '../../../footers/textFooter.js'
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from './videos/zurich.mp4'
import "swiper/css";
import "swiper/css/bundle";
import './styleSlideimage2.css'
import {  Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import MetaTags from 'react-meta-tags';
import '@splidejs/react-splide/css';
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from 'react-keyboard-event-handler';

function KacperLarskiEN() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES
        title="KACPER LARSKI"
      />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>KACPER LARSKI</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={['left']}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className='cursor-none container-swiper-home container-slider-photographer-still'
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[ Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/en/garmin', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='GARMIN | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882908413"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GARMIN"
              title2="KACPER LARSKI"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/ford', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='FORD | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882911580"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="FORD"
              title2="KACPER LARSKI"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/jeep-conform-rough', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='JEEP | CONFORM ROUGH | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882892554"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="JEEP | CONFORM ROUGH"
              title2="KACPER LARSKI"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/we-make-wow', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='HYUNDAI | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882916034"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | WE MAKE WOW"
              title2="KACPER LARSKI"
            />

          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => (window.open('/en/land-rover-defender', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='LAND ROVER DEFENDER | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882885242"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="LAND ROVER DEFENDER"
              title2="KACPER LARSKI"
            />
          </SwiperSlide>
          {/* video 3*/}
          <SwiperSlide
            onClick={() => (window.open('/en/mazda', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='MAZDA | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882906067"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MAZDA"
              title2="KACPER LARSKI"
            />
          </SwiperSlide>
          {/* video 4 */}
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide
          data-splide='{"type":"slide","perPage": 1, "drag": "free" }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='GARMIN | KACPER LARKSI'
                className="react-player-video"
                url="https://vimeo.com/882908413"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
             title1="GARMIN"
             title2="KACPER LARKSI"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='FORD | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882911580"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
             title1="FORD"
             title2="KACPER LARSKI"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='JEEP | CONFORM ROUGH | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882892554"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="JEEP | CONFORM ROUGH"
              title2="KACPER LARSKI"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='HYUNDAI | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882916034"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | WE MAKE WOW"
              title2="KACPER LARSKI"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='LAND ROVER DEFENDER | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882885242"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="LAND ROVER DEFENDER"
              title2="KACPER LARSKI"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='MAZDA | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882906067"


                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="MAZDA"
              title2="KACPER LARSKI"
            />
            <a className="more" href='/en/todo-hernan-corera'>More</a>
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors
        LogoColor={logoMHV}
      />
    </>
  )
}

export default KacperLarskiEN