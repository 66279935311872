import React from 'react';

import { useColorModeValue, Image, } from "@chakra-ui/react";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';

import logoMHB from "../../../img/500h/Asset_logo_blanco.png"
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '../internoFilm.css';



export const Paula_EN = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderLogoMH />
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                        Paula takes care of the children of a bourgeois family in the province of Buenos Aires. After finding out her pregnancy, she will have to take a desperate decision.
                    </p>
                    <a href='/en/paula' className='title-video-mobile-film'>
                        PAULA
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/en/paula'>
                                PAULA
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Directed by
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                EUGENIO CANEVARI
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Genre | Fiction
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duration | 65 minutos
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Shooting Format | Digital
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Projection Format | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sound | 5.1
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Language | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Country | Argentina - España
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                World Première | San Sebastian International Film Festival - New Directors. 2015
                            </p>
                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo='https://vimeo.com/133770419' />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/en/films"
            />
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

