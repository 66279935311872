import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from "../../../footers/textFooter";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper";
import ".././styleSelectWork.css";
import { HeaderMenu_ES } from "../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../headers/headerLogoMH_ES";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { MetaTags } from "react-meta-tags";
import "@splidejs/react-splide/css";
import KeyboardEventHandler from "react-keyboard-event-handler";

export default function Content_ES() {
  const [swiper, setSwiper] = useState(null);
  const title = [
    "AMERICANINO | THE REASON IS FREEDOM",
    "HERA HYESANG PARK | WHILE YOU LIVE",
    "L.E.S.",
    "DANTE SPINETTA | EL LADO OSCURO DEL CORAZON",
    "PARAISO | SADAELS",
    "YOU'RE NOT ALONE | AGORIA",
    
  ];
  const title2 = [
    "Mc Calle",
    "Mariano nante",
    "Sahara",
    "Hernán Corera",
    "Hernán Corera & Juan Piczman",
    "Hernán Corera",
    
  ];
  const href1 = [
    "/es/americanino",
    "/es/while-you-live",
    "/es/sahara",
    "/es/el-lado-oscuro-del-corazon",
    "/es/paraiso",
    "/es/you-are-not-alone",
    
  ];

  return (
    <>
      <MetaTags>
        <title>Mamá Húngara | Content</title>
      </MetaTags>
      <HeaderMenu_ES />
      <HeaderLogoMH_ES />
      <div className="container-swiper-home">
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="mySwiper  container-works-sections-test "
          slidesPerView={1}
          onSwiper={setSwiper}
          loop={true}
          spaceBetween={0}
          centeredSlides={true}
          Autoplay={false}
          /* autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }} */
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<div class="' +
                className +
                '">' +
                '<p class="texto-selected-works">' +
                title[index] + 
                "</p>" +
                '<p class="texto-selected-works">' +
                title2[index] +
                "</p>" +
                '<div class="btn-active">' +
                "</div>" +
                '<a class="href-works" href="' +
                href1[index] +
                '">' +
                "</a>" +
                "</div>"
              );
            },
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, Mousewheel]}
          mousewheel
        >
          {/* video 1 */}
          

          {/* video 2 */}
          <SwiperSlide onClick={() => window.open("/es/americanino", "_self")}>
            <ReactPlayer
              alt="AMERICANINO | THE REASON IS FREEDOM | MC CALLE"
              className="react-player-chau"
              url="https://vimeo.com/766590399"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide onClick={() => window.open("/es/while-you-live", "_self")}>
            <ReactPlayer
              alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
              className="react-player-chau"
              url="https://vimeo.com/935937413"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          <SwiperSlide onClick={() => window.open("/es/sahara", "_self")}>
            <ReactPlayer
              alt="L.E.S. SAHARA"
              className="react-player-chau"
              url="https://vimeo.com/861750876/e33c9ee6a7?share=copy"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              window.open("/es/el-lado-oscuro-del-corazon", "_self")
            }
          >
            <ReactPlayer
              alt="DANTE SPINETTA | EL LADO OSCURO DEL CORAZON | HERNAN CORERA & JUAN PICZMAN"
              className="react-player-chau"
              url="https://vimeo.com/759890900"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          {/* video 4 */}
          <SwiperSlide onClick={() => window.open("/es/paraiso", "_self")}>
            <ReactPlayer
              alt="PARAISO I SADAELS I  Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/520227700"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          {/* video 5 */}
          <SwiperSlide
            onClick={() => window.open("/es/you-are-not-alone", "_self")}
          >
            <ReactPlayer
              alt="YOU´RE NOT ALONE I AGORIA I Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/310419904"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>

          {/*  <SwiperSlide
            onClick={() => (window.open('/es/sin-senial', '_self'))}
          >
            <ReactPlayer
              alt='SIN SEÑAL | TOMO TERZANO'
              className="react-player-chau"
              url="https://vimeo.com/441068545"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide> */}
        </Swiper>
      </div>

      {/* mobile cambiar  */}
      <div className="container-video-works-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
              className="react-player-chau"
              url="https://vimeo.com/935937413"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="AMERICANINO | THE REASON IS FREEDOM | MC CALLE"
              className="react-player-chau"
              url="https://vimeo.com/766590399"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>

          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="L.E.S. SAHARA"
              className="react-player-chau"
              url="https://vimeo.com/861750876/e33c9ee6a7?share=copy"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>

          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="PARAISO | SADAELS | Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/520227700"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>

          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="YOU´RE NOT ALONE | AGORIA | Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/310419904"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="UN AMOR VERDADERO | SADAELS"
              className="react-player-chau"
              url="https://vimeo.com/471045785"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          {/* <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='SIN SEÑAL | TOMO TERZANO'
              className="react-player-chau"
              url="https://vimeo.com/441068545"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide> */}
        </Splide>
      </div>
      <div className="container-titles-works-mobile">
        <a href="/es/americanino">
          <p className="texto-selected-works-mobile">
            AMERICANINO | THE REASON IS FREEDOM
          </p>
          <p className="texto-selected-works-mobile">Mc Calle</p>
        </a>
        <a href="/es/americanino">
          <p className="texto-selected-works-mobile">HERA HYESANG PARK | WHILE YOU LIVE</p>
          <p className="texto-selected-works-mobile">Mariano Nante</p>
        </a>
        <a href="/es/el-lado-oscuro-del-corazon">
          <p className="texto-selected-works-mobile">
            DANTE SPINETTA | EL LADO OSCURO DEL CORAZON
          </p>
          <p className="texto-selected-works-mobile">
            Hernán Corera & Juan Piczman
          </p>
        </a>
        <a href="/es/paraiso">
          <p className="texto-selected-works-mobile">PARAISO | SADAELS</p>
          <p className="texto-selected-works-mobile">Hernán Corera</p>
        </a>
        <a href="/es/you-are-not-alone">
          <p className="texto-selected-works-mobile">
            YOU'RE NOT ALONE | AGORIA
          </p>
          <p className="texto-selected-works-mobile">Hernán Corera</p>
        </a>
        <a href="/es/un-amor-verdadero">
          <p className="texto-selected-works-mobile">A TRUE LOVE | SADAELS</p>
          <p className="texto-selected-works-mobile">Hernán Corera</p>
        </a>
      </div>
      <TextFooter title1="ALL CONTENT" href1="/es/todo-content" centerContent />
      <FooterSmall2 />
    </>
  );
}
