import React from 'react';

import { HeaderMenu } from '../../headers/headerMenu';
import { HeaderLogoMH } from '../../headers/headerLogoMH';
import { FooterSmall2 } from '../../footers/FooterSmall2.tsx';
import VideoHome_EN from './video-background/videoHome_EN.js';
import { MetaTags } from 'react-meta-tags';
export const Home_EN = () => {
    return (
        <>
        <MetaTags>
                <title>Mamá Húngara</title>
                <meta description="Productora audiovisual con base en Buenos Aires que crea contenido original para todo el mundo"
                    content="Productora audiovisual con base en Buenos Aires que crea contenido original para todo el mundo"
                ></meta>
            </MetaTags>
        <HeaderMenu />
        <HeaderLogoMH/>
            <VideoHome_EN/>            
        <FooterSmall2 />
        </>
    )
}

