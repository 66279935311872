import { useDisclosure, Image } from "@chakra-ui/react";
import { ReactNode } from "react";
import logoMH from "../img/500h/MHvioleta.png";
import './header.css'

export const HeaderLogoVioleta = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>
    <div className="container-LOGO-header-1">
      <a href="/en/home/">
        <Image className="logo-header-1" alt="logoMH" src={logoMH} />
      </a>
    </div>
  </>
  );
};
