import React from 'react';

import { Image } from '@chakra-ui/react';
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';
import poster5 from "../../../img/posters/anoche.jpg"

import '../internoFilm.css';


export const Anoche_EN = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderLogoMH />
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                    At a birthday party, three friends push their limits to the extreme, letting their sexual fantasies and repressed fears surface. A plane, a night, an opportunity to feel something different.

                    </p>
                    <a /* href='/film-anoche' */ className='title-video-mobile-film'>
                    LAST NIGHT
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <p className='text-data-vidoe-film-0 color-film-1'>
                            LAST NIGHT
                            </p>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Directed by
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                JUAN MANUEL PINZÓN
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Genre | Fiction
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duration | 21 minutes
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                            Shooting Format | 4K
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                            Projection Format | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sound | Stereo
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Language | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Country | Argentina - Colombia
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                World Première | FICCI Cartagena International Film Festival - New Directors 2018
                            </p>
                        </div>

                        <div className="video-gilda">
                            <Image src={poster5} />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/en/films"
            />
            <FooterSmall2 />
        </>
    )
}

