import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from "../../../footers/textFooter";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css";
import { HeaderMenu } from "../../../headers/headerMenu";
export default function Content_EN() {
  const title = [
    "AMERICANINO | THE REASON IS FREEDOM",
    "HERA HYESANG PARK | WHILE YOU LIVE",
    "DANTE SPINETTA | EL LADO OSCURO DEL CORAZON",
    " PARAISO | SADAELS",
    "YOU'RE NOT ALONE | AGORIA",
  ];
  const title2 = [
    "Mc Calle",
    "Mariano nante",
    "Hernán Corera & Juan Piczman",
    "Hernán Corera",
    "Hernán Corera",
  ];
  const href1 = [
    "/en/americanino",
    "/es/while-you-live",
    "/en/the-dark-side-of-the-heart",
    "/en/paradise",
    "/en/you-are-not-alone",
  ];

  return (
    <>
      <HeaderMenu />
      <HeaderLogoMH />
      <div className="container-swiper-home">
        <Swiper
          className="mySwiper  container-works-sections-test "
          slidesPerView={1}
          loop={true}
          spaceBetween={0}
          centeredSlides={true}
          Autoplay={false}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<div class="' +
                className +
                '">' +
                '<p class="texto-selected-works">' +
                title[index] +
                "</p>" +
                '<p class="texto-selected-works">' +
                title2[index] +
                "</p>" +
                '<div class="btn-active">' +
                "</div>" +
                '<a class="href-works" href="' +
                href1[index] +
                '">' +
                "</a>" +
                "</div>"
              );
            },
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, Mousewheel]}
          mousewheel
        >
          {/* video 1 */}
          {/* video 2 */}
          <SwiperSlide onClick={() => window.open("/es/americanino", "_self")}>
            <ReactPlayer
              alt="AMERICANINO | THE REASON IS FREEDOM | MC CALLE"
              className="react-player-chau"
              url="https://vimeo.com/766590399  "
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide onClick={() => window.open("/en/while-you-live", "_self")}>
            <ReactPlayer
              alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
              className="react-player-chau"
              url="https://vimeo.com/935937413"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 3 */}
          <SwiperSlide
            onClick={() =>
              window.open("/en/the-dark-side-of-the-heart", "_self")
            }
          >
            <ReactPlayer
              alt="DANTE SPINETTA | EL LADO OSCURO DEL CORAZON | HERNAN CORERA & JUAN PICZMAN"
              className="react-player-chau"
              url="https://vimeo.com/759890900"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 4 */}
          <SwiperSlide onClick={() => window.open("/en/paradise", "_self")}>
            <ReactPlayer
              alt="PARAISO I SADAELS I Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/520227700"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/you-are-not-alone", "_self")}
          >
            <ReactPlayer
              alt="YOU'RE NOT ALONE I AGORIA I Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/310419904"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* MOBILE */}

      <div className="container-video-works-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
              className="react-player-chau"
              url="https://vimeo.com/935937413"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="PARAISO I SADAELS I  Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/520227700"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="YOU'RE NOT ALONE I AGORIA I Hernán Corera"
              className="react-player-chau"
              url="https://vimeo.com/310419904"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt="A TRUE LOVE | SADAELS"
              className="react-player-chau"
              url="https://vimeo.com/471045785"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          {/* <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='SIN SEÑAL | TOMO TERZANO'
              className="react-player-chau"
              url="https://vimeo.com/441068545"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide> */}
        </Splide>
      </div>
      <div className="container-titles-works-mobile">
        <a href="/en/americanino">
          <p className="texto-selected-works-mobile">
            AMERICANINO | THE REASON IS FREEDOM
          </p>
          <p className="texto-selected-works-mobile">Mc Calle</p>
        </a>
        <a href="/en/the-dark-side-of-the-heart">
          <p className="texto-selected-works-mobile">HERA HYESANG PARK | WHILE YOU LIVE</p>
          <p className="texto-selected-works-mobile">
            Mariano Nante
          </p>
        </a>
        <a href="/en/paradise">
          <p className="texto-selected-works-mobile">
            DANTE SPINETTA | EL LADO OSCURO DEL CORAZON
          </p>
          <p className="texto-selected-works-mobile">Hernán Corera</p>
        </a>
        <a href="en/you-are-not-alone">
          <p className="texto-selected-works-mobile">
            YOU'RE NOT ALONE | AGORIA
          </p>
          <p className="texto-selected-works-mobile">Hernán Corera</p>
        </a>
        <a href="/en/a-true-love">
          <p className="texto-selected-works-mobile">A TRUE LOVE | SADAELS</p>
          <p className="texto-selected-works-mobile">Hernán Corera</p>
        </a>
      </div>
      <TextFooter title1="ALL CONTENT" href1="/en/all-content" centerContent />
      <FooterSmall2 />
    </>
  );
}
