import React from "react";
import { useDisclosure, Image } from "@chakra-ui/react";

import './header.css'
import { Drawer_MH_ES } from "../drawer/Drawer";

export const HeaderMenu_ES = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>
    <div className="container-header-1">
      <Drawer_MH_ES className="color" />
    </div>
  </>
  );
};
