import React from "react";
import MouseApp_blanco from "../../../../mouse/mouseApp_blanco.js";
import logoMHB from "../../../../img/500h/Asset_logo_blanco.png";
import '../videoIndividual.css';
import '../../../../footers/footerColors/footerColors.css';
import {FooterLogoColors} from '../../../../footers/footerColors/footerColors';
import { HeaderMenu_ES } from "../../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../../headers/headerLogoMH_ES";
function PersonalConexEs(props) {

    return (
        <>        
        {/* <HeaderMenuHome /> */}
            <HeaderLogoMH_ES />
            <HeaderMenu_ES/>
            <div className="player-wrapper">
                <MouseApp_blanco
                alt="personal | conexion total"
                urlVideo='https://vimeo.com/888108453'
                // urlTitulo='Hecho en Argentina - Netflix'
                />
              
                 <FooterLogoColors 
            LogoColor={logoMHB}
            />
                

            </div>
        </>
    )
}
export default PersonalConexEs