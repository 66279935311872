import React from "react";
import { VStack, Text, Flex } from "@chakra-ui/react";
// import FontFace from './fonts/Fonts';
import VideoControls from "./components/mouse/VideoControls";
import VideoControlsDrag from "./components/mouse/VideoControlsDrag";
import VideoControlFilm from "./components/mouse/videoControlFilm";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import FontFace from './fonts/Fonts';
import "./fonts/GoodSans-Thin.otf";
import "./fonts/Serifbabe_Regular.ttf";
import Vimeo from "@u-wave/react-vimeo";
import CaptionCarousel from "./components/pages/1_home/SliderHome.tsx";
import { Drawer_MH } from "./components/drawer/Drawer";
import { Header_ES } from "./components/headers/Header_ES.tsx";
import { FooterSmall2 } from "./components/footers/FooterSmall2.tsx";
import VideoPlayer from "./components/videos/Video.js";
import { Works } from "./components/pages/2_works/Works";
import Commercial from "./components/pages/2_works/commercial/commercial";
import SelectWork from "./components/pages/2_works/selectWorks/SelectWork.js";
import FashionFilms from "./components/pages/2_works/fashionFilm/fashion-films";
import { Grid_Mockup } from "./components/Grid_Mockup";

import "./fonts/GoodSans-Thin.otf";
import "./fonts/Serifbabe_Regular.ttf";

import { Home_ES } from "./components/pages/1_home/Home.js";
import { Home_EN } from "./components/pages_english/1_home/Home_EN.js";
import { Talent } from "./components/pages/3_talent/Talent";
import { Family } from "./components/pages/6_family/Family";
import { Film } from "./components/pages/4_films/Films";

import { Intro_segunda_capa } from "./components/pages/Intro/intro_segunda_capa";
import Real_Intro from "./components/pages/Intro/real_intro/intro";

// import { GrillasFashion } from './components/pages/2_works/fashionFilm/all-fashion/grillasFashion';
import { GrillasCommercial } from "./components/pages/2_works/commercial/all-commercial/grillasCommercial";
import { GrillasContent } from "./components/pages/2_works/content/all-content/grillasContent";
// import { GrillasContent_ES } from './components/pages_english/2_works/content/all-content/grillasContent';
import { GrillasWorks_ES } from "./components/pages/2_works/selectWorks/all-works/grillasWorks";
import { GrillasWorks_EN } from "./components/pages_english/2_works/selectWorks/all-works/grillasWorks";
/* STILL */
import { GrillaStill } from "./components/pages/5_still/grillaStill";
import { GrillaStill_EN } from "./components/pages_english/5_still/grillaStill";
/* HOME STILL POR FOTOGRAFO E IDIOMA */
import { GEze_ES } from "./components/pages/5_still/eze-monjo/G-Eze-Monjo";
import { GIoni_ES } from "./components/pages/5_still/ioni-epelbaum/G-Ioni-Epelbaum";
import { GSigalES } from "./components/pages/5_still/martin-sigal/G-Martin-Sigal";
import { GSisso_ES } from "./components/pages/5_still/sisso-chouela/G-Sisso-Chouela";
import { GTatu_ES } from "./components/pages/5_still/tatu-garcia/G-Tatu-Garcia";
import { GJoe_ES } from "./components/pages/5_still/joe-ekonen/G-Joe-Ekonen";

import { GEze_EN } from "./components/pages_english/5_still/eze-monjo/G-Eze-Monjo";
import { GTatu_EN } from "./components/pages_english/5_still/tatu-garcia/G-Tatu-Garcia";
import { GJoe_EN } from "./components/pages_english/5_still/joe-ekonen/G-Joe-Ekonen";
import { GIoni_EN } from "./components/pages_english/5_still/ioni-epelbaum/G-Ioni-Epelbaum";
import { GSisso_EN } from "./components/pages_english/5_still/sisso-chouela/G-Sisso-Chouela";
import { GSigalEN } from "./components/pages_english/5_still/martin-sigal/G-Martin-Sigal";

/* GALERIAS POR FOTOGRAFO E IDIOMA*/
import { S_Proy_Betunoire_ES } from "./components/pages/5_still/joe-ekonen/BETUNOIRE/s-p-betunoire";
import { S_Proy_brahma_pomelo_ES } from "./components/pages/5_still/joe-ekonen/BRAHMA POMELO/s-p-brahma-pomelo";
import { S_Proy_AmericaninoES } from "./components/pages/5_still/joe-ekonen/CARRUSEL AMERICANINO/s-p-americanino";
import { S_Proy_collab_ES } from "./components/pages/5_still/joe-ekonen/COLLAB/s-p-collab";
import { S_Proy_mmic_ES } from "./components/pages/5_still/joe-ekonen/MMIC/s-p-mmic";
import { S_Proy_nadie_ES } from "./components/pages/5_still/joe-ekonen/NADIE/s-p-nadie";
import { S_Proy_sadaels_paraiso_ES } from "./components/pages/5_still/joe-ekonen/SADAELS PARAISO/s-p-sadaels-paraiso";
import { S_Proy_brugal_ES } from "./components/pages/5_still/tatu-garcia/BRUGAL/s-p-brugal";
import { S_Proy_fractal_ES } from "./components/pages/5_still/tatu-garcia/FRACTAL/s-p-fractal";
import { S_Proy_mmic_tatu_ES } from "./components/pages/5_still/tatu-garcia/MMIC/s-p-mmic-tatu";
import { S_Proy_remix_b_ES } from "./components/pages/5_still/tatu-garcia/REMIX_B/s-p-remix-b";
import { S_Proy_stella_artois_ES } from "./components/pages/5_still/tatu-garcia/STELLA_ARTOIS/s-p-stella-artois";
import { S_Proy_toque_brahma_ES } from "./components/pages/5_still/tatu-garcia/TOQUE_BRAHMA/s-p-toque-brahma";
import { S_Proy_volkswagen_ES } from "./components/pages/5_still/tatu-garcia/VOLKSWAGEN/s-p-volkswagen";
import { S_Proy_fila_ES } from "./components/pages/5_still/tatu-garcia/Fila-B/s-p-fila";
import { S_Proy_Betunoire_EN } from "./components/pages_english/5_still/joe-ekonen/BETUNOIRE/s-p-betunoire";
import { S_Proy_brahma_pomelo_EN } from "./components/pages_english/5_still/joe-ekonen/BRAHMA POMELO/s-p-brahma-pomelo";
import { S_Proy_americanino_EN } from "./components/pages_english/5_still/joe-ekonen/CARRUSEL AMERICANINO/s-p-americanino";
import { S_Proy_collab_EN } from "./components/pages_english/5_still/joe-ekonen/COLLAB/s-p-collab";
import { S_Proy_mmic_EN } from "./components/pages_english/5_still/joe-ekonen/MMIC/s-p-mmic";
import { S_Proy_nadie_EN } from "./components/pages_english/5_still/joe-ekonen/NADIE/s-p-nadie";
import { S_Proy_sadaels_paraiso_EN } from "./components/pages_english/5_still/joe-ekonen/SADAELS PARAISO/s-p-sadaels-paraiso";
import { S_Proy_brugal_EN } from "./components/pages_english/5_still/tatu-garcia/BRUGAL/s-p-brugal";
import { S_Proy_fractal_EN } from "./components/pages_english/5_still/tatu-garcia/FRACTAL/s-p-fractal";
import { S_Proy_mmic_tatu_EN } from "./components/pages_english/5_still/tatu-garcia/MMIC/s-p-mmic-tatu";
import { S_Proy_remix_b_EN } from "./components/pages_english/5_still/tatu-garcia/REMIX_B/s-p-remix-b";
import { S_Proy_stella_artois_EN } from "./components/pages_english/5_still/tatu-garcia/STELLA_ARTOIS/s-p-stella-artois";
import { S_Proy_toque_brahma_EN } from "./components/pages_english/5_still/tatu-garcia/TOQUE_BRAHMA/s-p-toque-brahma";
import { S_Proy_volkswagen_EN } from "./components/pages_english/5_still/tatu-garcia/VOLKSWAGEN/s-p-volkswagen";
import { S_Proy_fila_EN } from "./components/pages_english/5_still/tatu-garcia/Fila-B/s-p-fila";
import { S_Proy_Adidas_ES } from "./components/pages/5_still/ioni-epelbaum/ADIDAS/s-p-adidas";
import { S_Proy_Eye_ES } from "./components/pages/5_still/ioni-epelbaum/EYE/s-p-eye";
import { S_Proy_Free_ES } from "./components/pages/5_still/ioni-epelbaum/FREE/s-p-free";
import { S_Proy_Kostume_ES } from "./components/pages/5_still/ioni-epelbaum/KOSTUME/s-p-kostume";
import { S_Proy_Wejs_ES } from "./components/pages/5_still/ioni-epelbaum/WEJS/s-p-wejs";
import { S_Proy_HBO_ES } from "./components/pages/5_still/sisso-chouela/HBO/s-p-hbo";
import { S_Proy_HIPPO_ES } from "./components/pages/5_still/sisso-chouela/HIPPO/s-p-hippo";
import { S_Proy_NISSAN_ES } from "./components/pages/5_still/sisso-chouela/NISSAN/s-p-nissan";
import { S_Proy_PURINA_ES } from "./components/pages/5_still/sisso-chouela/PURINA/s-p-purina";
import { S_Proy_SPOTIFY_ES } from "./components/pages/5_still/sisso-chouela/SPOTIFY/s-p-spotify";
import { S_Proy_HBO_EN } from "./components/pages_english/5_still/sisso-chouela/HBO/s-p-hbo";
import { S_Proy_HIPPO_EN } from "./components/pages_english/5_still/sisso-chouela/HIPPO/s-p-hippo";
import { S_Proy_NISSAN_EN } from "./components/pages_english/5_still/sisso-chouela/NISSAN/s-p-nissan";
import { S_Proy_PURINA_EN } from "./components/pages_english/5_still/sisso-chouela/PURINA/s-p-purina";
import { S_Proy_SPOTIFY_EN } from "./components/pages_english/5_still/sisso-chouela/SPOTIFY/s-p-spotify";
import { S_Proy_Advertising_ES } from "./components/pages/5_still/martin-sigal/1_Adversiting/s-p-advertising";
import { S_Proy_Sports_ES } from "./components/pages/5_still/martin-sigal/2_Sports/s-p-sports";
import { S_Proy_Portrait_ES } from "./components/pages/5_still/martin-sigal/3_Portrait/s-p-portrait";
import { S_Proy_Landscape_ES } from "./components/pages/5_still/martin-sigal/4_Landscape/s-p-landscape";
import { S_Proy_ValleDeUco_ES } from "./components/pages/5_still/martin-sigal/5_Valle_de_Uco_Peniaflor/s-p-valle-de-uco";
import { S_Proy_TheReturned_ES } from "./components/pages/5_still/martin-sigal/6_The_returned/s-p-the-returned";
import { S_Proy_Beberages_ES } from "./components/pages/5_still/martin-sigal/7_Beberages/s-p-beberages";
import { S_Proy_Advertising_EN } from "./components/pages_english/5_still/martin-sigal/1_Adversiting/s-p-advertising";
import { S_Proy_Sports_EN } from "./components/pages_english/5_still/martin-sigal/2_Sports/s-p-sports";
import { S_Proy_Portrait_EN } from "./components/pages_english/5_still/martin-sigal/3_Portrait/s-p-portrait";
import { S_Proy_Landscape_EN } from "./components/pages_english/5_still/martin-sigal/4_Landscape/s-p-landscape";
import { S_Proy_ValleDeUco_EN } from "./components/pages_english/5_still/martin-sigal/5_Valle_de_Uco_Peniaflor/s-p-valle-de-uco";
import { S_Proy_TheReturned_EN } from "./components/pages_english/5_still/martin-sigal/6_The_returned/s-p-the-returned";
import { S_Proy_Beberages_EN } from "./components/pages_english/5_still/martin-sigal/7_Beberages/s-p-beberages";
import { S_Proy_Editorial_ES } from "./components/pages/5_still/eze-monjo/EDITORIAL/s-p-editorial";
import { S_Proy_Cronica_ES } from "./components/pages/5_still/eze-monjo/CRONICAS/s-p-cronica";
import { S_Proy_Fifa_ES } from "./components/pages/5_still/eze-monjo/FIFA22/s-p-fifa";
import { S_Proy_Editorial_EN } from "./components/pages_english/5_still/eze-monjo/EDITORIAL/s-p-editorial";
import { S_Proy_Cronica_EN } from "./components/pages_english/5_still/eze-monjo/CRONICAS/s-p-cronica";
import { S_Proy_Fifa_EN } from "./components/pages_english/5_still/eze-monjo/FIFA22/s-p-fifa";
import { S_Proy_Adidas_EN } from "./components/pages_english/5_still/ioni-epelbaum/ADIDAS/s-p-adidas";
import { S_Proy_Eye_EN } from "./components/pages_english/5_still/ioni-epelbaum/EYE/s-p-eye";
import { S_Proy_Free_EN } from "./components/pages_english/5_still/ioni-epelbaum/FREE/s-p-free";
import { S_Proy_Kostume_EN } from "./components/pages_english/5_still/ioni-epelbaum/KOSTUME/s-p-kostume";
import { S_Proy_Wejs_EN } from "./components/pages_english/5_still/ioni-epelbaum/WEJS/s-p-wejs";

/* FILM */
import { Gilda } from "./components/pages/4_films/videosInternos/Gilda";
import { Anoche } from "./components/pages/4_films/videosInternos/Anoche";
import { Figuras } from "./components/pages/4_films/videosInternos/Figuras";
import { Schwaneck } from "./components/pages/4_films/videosInternos/Schwaneck";
import { Paula } from "./components/pages/4_films/videosInternos/Paula";
import { Hombre } from "./components/pages/4_films/videosInternos/Hombre";

/* title talents */
import DragFedeGR from "./components/pages/3_talent/dragTitle/fedeGR";
import DiegoFried from "./components/pages/3_talent/dragTitle/diegoFried";
import Gonzo from "./components/pages/3_talent/dragTitle/gonzo";
import HernanCorera from "./components/pages/3_talent/dragTitle/hernanCorera";
import HernanCorera_EN from "./components/pages_english/3_talent/dragTitle/hernanCorera";
import Gonzo_EN from "./components/pages_english/3_talent/dragTitle/gonzo";
import DiegoFried_EN from "./components/pages_english/3_talent/dragTitle/diegoFried";
import DragFedeGR_EN from "./components/pages_english/3_talent/dragTitle/fedeGR";
import OliviaSanchez from "./components/pages/3_talent/dragTitle/oliviaSanchez.js";
import MartinaGalli from "./components/pages/3_talent/dragTitle/martinaGalli.js";
import MartinaGalliEn from "./components/pages_english/3_talent/dragTitle/martinaGalliEn.js";
import OliviaSanchezEn from "./components/pages_english/3_talent/dragTitle/oliviaSanchezEn.js";
import LaDulceLiberal_EN from "./components/pages_english/3_talent/dragTitle/oliviaSanchezEn.js";
import MarianoNante from "./components/pages/3_talent/dragTitle/marianoNante.js";
import SantiagoFranco from "./components/pages/3_talent/dragTitle/santiagoFranco.js";
import MarianoNanteEn from "./components/pages_english/3_talent/dragTitle/marianoNante.js";
import SantiagoFrancoEn from "./components/pages_english/3_talent/dragTitle/santiagoFrancoEn.js";

/* grillas talents */
import { GrillaDiegoFried } from "./components/pages/3_talent/grillas/grillaDiegoFried";
import { GrillaSantiagoFranco } from "./components/pages/3_talent/grillas/grillaSantiagoFranco.js";
import { GrillaHernanCorera } from "./components/pages/3_talent/grillas/grillaHernanCorera";
import { GrillaFedeGR } from "./components/pages/3_talent/grillas/grillaFedeGR";
import { GrillaGonzo } from "./components/pages/3_talent/grillas/grillaGonzo";
import { GrillaMartinaGalli } from "./components/pages/3_talent/grillas/grillaMartinaGalli.js";
import { GrillaOliviaSanchez } from "./components/pages/3_talent/grillas/grillaOliviaSanchez.js";
import { GrillaMarianoNante } from "./components/pages/3_talent/grillas/grillaMarianoNante.js";

import { GrillasCommercial_EN } from "./components/pages_english/2_works/commercial/all-commercial/grillasCommercial_EN";
import { GrillaDiegoFried_EN } from "./components/pages_english/3_talent/grillas/grillaDiegoFried";
import { GrillaFedeGR_EN } from "./components/pages_english/3_talent/grillas/grillaFedeGR";
import { GrillasContent_EN } from "./components/pages_english/2_works/content/all-content/grillasContent_EN";
import { GrillaHernanCorera_EN } from "./components/pages_english/3_talent/grillas/grillaHernanCorera";
import { GrillaMartinaGalliEn } from "./components/pages_english/3_talent/grillas/grillaMartinaGalliEn.js";
import { GrillaOliviaSanchezEn } from "./components/pages_english/3_talent/grillas/grillaOliviaSanchezEn.js";
import { GrillaMarianoNanteEn } from "./components/pages_english/3_talent/grillas/grillaMarianoNanteEn.js";
import { GrillaSantiagoFrancoEn } from "./components/pages_english/3_talent/grillas/grillaSantiagoFrancoEn.js";

/* componentes de video individuales seleccionados por nombre de obra*/
import HechoEnArgentina from "./components/pages/1_home/videoIndividual/videos_commercials/hechoEnArgentina";
import HechoEnArgentina_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/hechoEnArgentina";
import BetuNoire from "./components/pages/1_home/videoIndividual/videos_commercials/betuNoire";
import BetuNoire_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/betuNoire";
import SuenaPotente from "./components/pages/1_home/videoIndividual/videos_commercials/sueñaPotente";
import SuenaPotente_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/sueñaPotente";
import Nadie from "./components/pages/1_home/videoIndividual/videos_content/nadie-sinBandera";
import Nadie_EN from "./components/pages_english/1_home/videoIndividual/videos_content/nadie-sinBandera";
import Paraiso from "./components/pages/1_home/videoIndividual/videos_content/paraiso-sadaels";
import Paraiso_EN from "./components/pages_english/1_home/videoIndividual/videos_content/paraiso-sadaels";
import RomanceEterno from "./components/pages/1_home/videoIndividual/videos_commercials/romanceEterno";
import RomanceEterno_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/romanceEterno";
import Supervielle from "./components/pages/1_home/videoIndividual/videos_commercials/supervielle";
import Supervielle_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/supervielle";
import ElAmorNosSalva from "./components/pages/1_home/videoIndividual/videos_commercials/elAmorNosSalva";
import ElAmorNosSalva_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/elAmorNosSalva";
import LaVidaArtois from "./components/pages/1_home/videoIndividual/videos_commercials/laVidaArtois";
import LaVidaArtois_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/laVidaArtois";
import SexEducation from "./components/pages/1_home/videoIndividual/videos_commercials/sexEducation";
import SexEducation_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/sexEducation";
import RugbyWorldCup from "./components/pages/1_home/videoIndividual/videos_commercials/rugbyWorldCup";
import RugbyWorldCup_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/rugbyWorldCup";
import Dokkaebis from "./components/pages/1_home/videoIndividual/videos_content/dokkaebis-MMIC";
import Dokkaebis_EN from "./components/pages_english/1_home/videoIndividual/videos_content/dokkaebis-MMIC";
import El_Idolo from "./components/pages/1_home/videoIndividual/videos_content/el-idolo-bandalosChinos";
import El_Idolo_EN from "./components/pages_english/1_home/videoIndividual/videos_content/el-idolo-bandalosChinos";
import Everytime from "./components/pages/1_home/videoIndividual/videos_content/everytime-forma";
import Everytime_EN from "./components/pages_english/1_home/videoIndividual/videos_content/everytime-forma";
import MiVida from "./components/pages/1_home/videoIndividual/videos_content/miVida-danteSpinetta";
import MiVida_EN from "./components/pages_english/1_home/videoIndividual/videos_content/miVida-danteSpinetta";
import UnAmorVerdadero from "./components/pages/1_home/videoIndividual/videos_content/unAmorVerdadero-sadaels";
import UnAmorVerdadero_EN from "./components/pages_english/1_home/videoIndividual/videos_content/unAmorVerdadero-sadaels";
import YouAreNotAlone from "./components/pages/1_home/videoIndividual/videos_content/youreNotAloneAgoria";
import YouAreNotAlone_EN from "./components/pages_english/1_home/videoIndividual/videos_content/youreNotAloneAgoria";
import LaCalleDeLosPianistas from "./components/pages/1_home/videoIndividual/videos_talents/talent1_marysanti/laCalleDeLosPianistas";
import LaCalleDeLosPianistas_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent1_marysanti/laCalleDeLosPianistas";
import ToqueBrahma from "./components/pages/1_home/videoIndividual/videos_talents/talent1_marysanti/toqueBrahma";
import ToqueBrahma_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent1_marysanti/toqueBrahma";
import Nowness from "./components/pages/1_home/videoIndividual/videos_talents/talent2_gonzo/nowNess";
import Nowness_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent2_gonzo/nowNess";
import ZurichInsurance from "./components/pages/1_home/videoIndividual/videos_talents/talent2_gonzo/zurichInsurance";
import ZurichInsurance_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent2_gonzo/zurichInsurance";
import Boxer from "./components/pages/1_home/videoIndividual/videos_talents/talent2_gonzo/uberBoxer";
import Boxer_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent2_gonzo/uberBoxer";
import AguasDeRioDeJaneiro from "./components/pages/1_home/videoIndividual/videos_talents/talent2_gonzo/aguasDeRioDeJaneiro";
import AguasDeRioDeJaneiro_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent2_gonzo/aguasDeRioDeJaneiro";
import HyundaiYourLife from "./components/pages/1_home/videoIndividual/videos_talents/talent2_gonzo/hyyundaiYourLife";
import HyundaiYourLife_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent2_gonzo/hyyundaiYourLife";
import NiveaBeauty from "./components/pages/1_home/videoIndividual/videos_talents/talent2_gonzo/niveaBeauty";
import NiveaBeauty_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent2_gonzo/niveaBeauty";
import Peugeot from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/208Peugeot";
import Peugeot_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent3_fede_rico/208Peugeot";
import AspiremosAmas from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/aspiremosAmas";
import AspiremosAmas_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent3_fede_rico/aspiremosAmas";
import InmigrantesDigitales from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/inmigrantesDigitales";
import InmigrantesDigitales_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent3_fede_rico/inmigrantesDigitales";
import StellaVintage from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/stellaVintage";
import StellaVintage_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent3_fede_rico/stellaVintage";
import ActronGel from "./components/pages/1_home/videoIndividual/videos_talents/talent4_la_dulce/actronGelBayer";
import ActronGel_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent4_la_dulce/actronGelBayer";
import SpriteLoveYouHater from "./components/pages/1_home/videoIndividual/videos_talents/talent4_la_dulce/spriteLoveYourHater";
import SpriteLoveYouHater_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent4_la_dulce/spriteLoveYourHater";
import BecksHaceloDiferente from "./components/pages/1_home/videoIndividual/videos_talents/talent4_la_dulce/becksHaceloDiferente";
import BecksHaceloDiferente_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent4_la_dulce/becksHaceloDiferente";
import MichelobUltra from "./components/pages/1_home/videoIndividual/videos_talents/talent4_la_dulce/michelobUltra";
import MichelobUltra_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent4_la_dulce/michelobUltra";
import JumboProductosAbandonados from "./components/pages/1_home/videoIndividual/videos_talents/talent4_la_dulce/jumboProductosAbandonados";
import JumboProductosAbandonados_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent4_la_dulce/jumboProductosAbandonados";
import CerealMix from "./components/pages/1_home/videoIndividual/videos_talents/talent6_diego/cerealMix";
import CerealMix_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent6_diego/cerealMix";
import MovistarMasGigas from "./components/pages/1_home/videoIndividual/videos_talents/talent4_la_dulce/movistarMasGigas";
import MovistarMasGigas_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent4_la_dulce/movistarMasGigas";
import ChallengeGillette from "./components/pages/1_home/videoIndividual/videos_talents/talent6_diego/challengeGillette";
import ChallengeGillette_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent6_diego/challengeGillette";
import LaFiestaSilenciosa from "./components/pages/1_home/videoIndividual/videos_talents/talent6_diego/laFiestaSilenciosa";
import LaFiestaSilenciosa_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent6_diego/laFiestaSilenciosa";
import LoveStoryRipley from "./components/pages/1_home/videoIndividual/videos_talents/talent6_diego/loveStoryRipley";
import LoveStoryRipley_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent6_diego/loveStoryRipley";
import FilmGilda from "./components/pages/1_home/videoIndividual/videos_film/film-gilda";
import FilmGilda_EN from "./components/pages_english/1_home/videoIndividual/videos_film/film-gilda";
import FilmHombreEnLaLlanura from "./components/pages/1_home/videoIndividual/videos_film/film-hombre";
import FilmHombreEnLaLlanura_EN from "./components/pages_english/1_home/videoIndividual/videos_film/film-hombre";
import FilmFiguras from "./components/pages/1_home/videoIndividual/videos_film/film-figuras";
import FilmFiguras_EN from "./components/pages_english/1_home/videoIndividual/videos_film/film-figuras";
import FilmPaula from "./components/pages/1_home/videoIndividual/videos_film/film-paula";
import FilmPaula_EN from "./components/pages_english/1_home/videoIndividual/videos_film/film-paula";
import FilmSchwaneck from "./components/pages/1_home/videoIndividual/videos_film/film-schwaneck";
import FilmSchwaneck_EN from "./components/pages_english/1_home/videoIndividual/videos_film/film-schwaneck";
import JugosDelValle from "./components/pages/1_home/videoIndividual/videos_commercials/jugosDelValle";
import JugosDelValle_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/jugosDelValle";
import BrahmaDiminutivos from "./components/pages/1_home/videoIndividual/videos_commercials/brahmaDiminutivos";
import BrahmaDiminutivos_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/brahmaDiminutivos";
import PurinaDogChow from "./components/pages/1_home/videoIndividual/videos_commercials/purinaDogChow";
import PurinaDogChow_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/purinaDogChow";
import NoSeChonguea from "./components/pages/1_home/videoIndividual/videos_commercials/noSeChonguea";
import NoSeChonguea_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/noSeChonguea";
import IncrediblendsDada from "./components/pages/1_home/videoIndividual/videos_commercials/incrediblendsDada";
import IncrediblendsDada_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/incrediblendsDada";
import TrapArgento from "./components/pages/1_home/videoIndividual/videos_commercials/trapArgento";
import TrapArgento_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/trapArgento";
import OtroDeporte from "./components/pages/1_home/videoIndividual/videos_talents/talent1_marysanti/OtroDeporte";
import OtroDeporte_EN from "./components/pages_english/1_home/videoIndividual/videos_talents/talent1_marysanti/OtroDeporte";

import { Drawer_MH_EN } from "./components/drawer/Drawer_EN";
import { Family_EN } from "./components/pages_english/6_family/Family";
import { Film_EN } from "./components/pages_english/4_films/Films";
import { Gilda_EN } from "./components/pages_english/4_films/videosInternos/Gilda_EN";
import { Anoche_EN } from "./components/pages_english/4_films/videosInternos/Anoche_EN";
import { Talent_EN } from "./components/pages_english/3_talent/Talent";
import { Hombre_EN } from "./components/pages_english/4_films/videosInternos/Hombre_EN";
import { Paula_EN } from "./components/pages_english/4_films/videosInternos/Paula_EN";
import { Figuras_EN } from "./components/pages_english/4_films/videosInternos/Figuras_EN";
import { Schwaneck_EN } from "./components/pages_english/4_films/videosInternos/Schwaneck_EN";
import Commercial_ES from "./components/pages/2_works/commercial/commercial";
import SelectWork_ES from "./components/pages/2_works/selectWorks/SelectWork.js";
import Content_ES from "./components/pages/2_works/content/content";
import SelectWork_EN from "./components/pages_english/2_works/selectWorks/SelectWork";
import SinSenialES from "./components/pages_english/1_home/videoIndividual/videos_content/sinSenial";
import SinSenial_EN from "./components/pages_english/1_home/videoIndividual/videos_content/sinSenial";
import Commercial_EN from "./components/pages_english/2_works/commercial/commercial";
import Content_EN from "./components/pages_english/2_works/content/content";
import { GrillaMarianSanti_EN } from "./components/pages_english/3_talent/grillas/grillaMarianSanti";
import { GrillaGonzo_EN } from "./components/pages_english/3_talent/grillas/grillaGonzo";
import { LoginPage } from "./components/login/loginPage";
import RegisterPage from "./components/login/registerPage";
import AmericaninoES from "./components/pages/1_home/videoIndividual/videos_content/americanino";
import Americanino_EN from "./components/pages_english/1_home/videoIndividual/videos_content/americanino";
import AdminSelect from "./components/frontAdmin/Admin";
import HomeAdmin from "./components/frontAdmin/home";
import AlimentandoSueños from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/alimentandoSueños";
import AlimentandoSueños_EN from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/alimentandoSueños";
import ElLadoOscuroDelCorazonEs from "./components/pages/1_home/videoIndividual/videos_content/el-lado-oscuro-del-corazon";
import ElLadoOscuroDelCorazonEn from "./components/pages_english/1_home/videoIndividual/videos_content/el-lado-oscuro-del-corazon";
import Register2 from "./components/login/registerPage2";
import LoginPage2 from "./components/login/loginPage2";
import BplayCoraArgentoEs from "./components/pages/1_home/videoIndividual/videos_commercials/bplayCoraArgento";
import BplayCoraArgentoEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/bplayCoraArgento";
import BeldentEs from "./components/pages/1_home/videoIndividual/videos_commercials/beldent";
import BeldentEN from "./components/pages_english/1_home/videoIndividual/videos_commercials/beldent";
import ElEstecoEN from "./components/pages_english/1_home/videoIndividual/videos_commercials/elEsteco";
import ElEsteco from "./components/pages/1_home/videoIndividual/videos_commercials/elEsteco";
import MolinosEs from "./components/pages/1_home/videoIndividual/videos_commercials/molinos120a";
import MolinosEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/molinos120a";
import { S_Proy_ElEsteco_ES } from "./components/pages/5_still/martin-sigal/8_El_Esteco/s-p-el-esteco";
import { S_Proy_ElEsteco_EN } from "./components/pages_english/5_still/martin-sigal/8_El_Esteco/s-p-el-esteco";
import DelCineATuCasaEs from "./components/pages/1_home/videoIndividual/videos_commercials/hbomax-cineAcasa";
import DelCineATuCasaEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/hbomax-cineAcasa";
import StrixLoJackEs from "./components/pages/1_home/videoIndividual/videos_commercials/strix-loJack";
import StrixLoJackEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/strix-loJack";
import LoQueNosHaceBienEs from "./components/pages/1_home/videoIndividual/videos_commercials/clight-loQueNosHaceBien";
import LoQueNosHaceBienEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/clight-loQueNosHaceBien";
import KelloggsPopTartsEs from "./components/pages/1_home/videoIndividual/videos_commercials/kellogsPopTarts";
import KelloggsPopTartsEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/kellogsPopTarts";
import GaliciaAppEs from "./components/pages/1_home/videoIndividual/videos_commercials/galicia-app";
import GaliciaAppEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/galicia-app";
import { Schettinimous_EN } from "./components/pages_english/4_films/videosInternos/Schettinimous_EN";
import { Schettinimous } from "./components/pages/4_films/videosInternos/Schettinimous";
import FilmSchettinimous from "./components/pages/1_home/videoIndividual/videos_film/film-schettinimous";
import FilmSchettinimous_EN from "./components/pages_english/1_home/videoIndividual/videos_film/film-schettinimous";
import BplaySponsorOficialEs from "./components/pages/1_home/videoIndividual/videos_commercials/bplaySponsorOficial";
import BplaySponsorOficialEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/bplayCoraArgento copy";
import YPFIdolasEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/idolas";
import YPFIdolasEs from "./components/pages/1_home/videoIndividual/videos_commercials/idolas";
// import FilmDetails from './components/pages/4_films/FilmDetails';
import Netflix3En1_ES from "./components/pages/1_home/videoIndividual/videos_commercials/netfix-3-en-1";
import PurinaEs from "./components/pages/1_home/videoIndividual/videos_commercials/purinaDogChow copy";
import NetflixEstesParaLoQueEstes1_ES from "./components/pages/1_home/videoIndividual/videos_commercials/netflix-estes-para-lo-que-estes-1";
import NetflixEstesParaLoQueEstes2_ES from "./components/pages/1_home/videoIndividual/videos_commercials/netflix-estes-para-lo-que-estes-2";
import NetflixEstesParaLoQueEstes3_ES from "./components/pages/1_home/videoIndividual/videos_commercials/netflix-estes-para-lo-que-estes-3";
import LesSaharaEs from "./components/pages/1_home/videoIndividual/videos_commercials/les-sahara";
import BeesEs from "./components/pages/1_home/videoIndividual/videos_commercials/bees";
import Netflix3En1_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/netfix-3-en-1";
import NetflixEstesParaLoQueEstes1_EN from "./components/pages/1_home/videoIndividual/videos_commercials/netflix-estes-para-lo-que-estes-1";
import NetflixEstesParaLoQueEstes2_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/netflix-estes-para-lo-que-estes-2";
import NetflixEstesParaLoQueEstes3_EN from "./components/pages_english/1_home/videoIndividual/videos_commercials/netflix-estes-para-lo-que-estes-3";
import Santa from "./components/pages/3_talent/dragTitle/santa.js";
import ChryslerSanta from "./components/pages/1_home/videoIndividual/videos_commercials/chryslerSanta.js";
import WeLoveTacos from "./components/pages/1_home/videoIndividual/videos_commercials/weLoveTacos.js";
import Jeep from "./components/pages/1_home/videoIndividual/videos_commercials/jeep.js";
import GoodVibes from "./components/pages/1_home/videoIndividual/videos_commercials/soloGoodVibes.js";
import RegresoClase from "./components/pages/1_home/videoIndividual/videos_commercials/regresoClase.js";
import ElevateLife from "./components/pages/1_home/videoIndividual/videos_commercials/elevateYourLife.js";
import NoFilter from "./components/pages/1_home/videoIndividual/videos_commercials/noFilter.js";
import SuperBock from "./components/pages/1_home/videoIndividual/videos_commercials/superBock.js";
import OpenForMore from "./components/pages/1_home/videoIndividual/videos_commercials/openMore.js";
import SantaEn from "./components/pages_english/3_talent/dragTitle/santaEn.js";
import SteveBruno from "./components/pages/3_talent/dragTitle/steveBruno.js";
import SteveBrunoEn from "./components/pages_english/3_talent/dragTitle/steveBrunoEn.js";
import SpotifyPlaylist from "./components/pages/1_home/videoIndividual/videos_commercials/spotify.js";
import NissanDrives from "./components/pages/1_home/videoIndividual/videos_commercials/nissanDrives.js";
import Blending from "./components/pages/1_home/videoIndividual/videos_commercials/blending.js";
import Boticario from "./components/pages/1_home/videoIndividual/videos_commercials/boticario.js";
import KacperLarski from "./components/pages/3_talent/dragTitle/kacperLarks.js";
import KacperLarskiEn from "./components/pages_english/3_talent/dragTitle/kacperLarskiEn.js";
import Garmin from "./components/pages/1_home/videoIndividual/videos_commercials/garmin.js";
import Ford from "./components/pages/1_home/videoIndividual/videos_commercials/ford.js";
import JeepConform from "./components/pages/1_home/videoIndividual/videos_commercials/jeepConform.js";
import Hyundai from "./components/pages/1_home/videoIndividual/videos_commercials/hyundai.js";
import LandDefender from "./components/pages/1_home/videoIndividual/videos_commercials/landDefender.js";
import Mazda from "./components/pages/1_home/videoIndividual/videos_commercials/mazda.js";
import { GrillaSanta } from "./components/pages/3_talent/grillas/grillaSanta.js";
import { GrillaSteveBruno } from "./components/pages/3_talent/grillas/grillaSteveBruno.js";
import { GrillaSteveBrunoEn } from "./components/pages_english/3_talent/grillas/grillaSteveBrunoEn.js";
import { GrillaKacper } from "./components/pages/3_talent/grillas/grillaKacper.js";
import { GrillaKacperEn } from "./components/pages_english/3_talent/grillas/grillaKacperEn.js";
import KeaneShaw from "./components/pages/3_talent/dragTitle/keaneShaw.js";
import KeaneShawEn from "./components/pages_english/3_talent/dragTitle/keaneShawEn.js";
import { GrillaKeaneShaw } from "./components/pages/3_talent/grillas/grillaKeaneShaw.js";
import Nike from "./components/pages/1_home/videoIndividual/videos_commercials/nike.js";
import Betano from "./components/pages/1_home/videoIndividual/videos_commercials/betano.js";
import Champion from "./components/pages/1_home/videoIndividual/videos_commercials/champion.js";
import VolleyBall from "./components/pages/1_home/videoIndividual/videos_commercials/volleyball.js";
import NikeImagine from "./components/pages/1_home/videoIndividual/videos_commercials/nikeImagine.js";
import TntSports from "./components/pages/1_home/videoIndividual/videos_commercials/tntSports.js";
import SalomonSimhon from "./components/pages/3_talent/dragTitle/salomonSimhon.js";
import SalomonSimhonEn from "./components/pages_english/3_talent/dragTitle/salomonSimhonEn.js";
import { GrillaSalomon } from "./components/pages/3_talent/grillas/grillaSalomon.js";
import { GrillaSalomonEn } from "./components/pages_english/3_talent/grillas/grillaSalomonEn.js";
import RiotGames from "./components/pages/1_home/videoIndividual/videos_commercials/riot.js";
import Soledad from "./components/pages/1_home/videoIndividual/videos_commercials/soledad.js";
import Senorita from "./components/pages/1_home/videoIndividual/videos_commercials/senorita.js";
import Colombina from "./components/pages/1_home/videoIndividual/videos_commercials/colombina.js";
import Diarco from "./components/pages/1_home/videoIndividual/videos_commercials/diarco.js";
import { DirectorsProvider } from "./components/context/directorsProvider.js";
import Fifa from "./components/pages/1_home/videoIndividual/videos_commercials/fifa.js";
import Adidas from "./components/pages/1_home/videoIndividual/videos_commercials/adidas.js";
import Puma from "./components/pages/1_home/videoIndividual/videos_commercials/puma.js";
import PersonalConexEs from "./components/pages/1_home/videoIndividual/videos_commercials/personalConexEs.js";
import PersonalConexEn from "./components/pages/1_home/videoIndividual/videos_commercials/personalConexEn.js";
import BghCalurosamenteEs from "./components/pages/1_home/videoIndividual/videos_commercials/bghCalurosamenteEs.js";
import BghCalurosamenteEn from "./components/pages/1_home/videoIndividual/videos_commercials/bghCalurosamenteEn.js";
import Citibanamex from "./components/pages/1_home/videoIndividual/videos_commercials/citibanaMex.js";
import BurgerNetflix from "./components/pages/1_home/videoIndividual/videos_commercials/burgerNetflix.js";
import CopaDavis from "./components/pages/1_home/videoIndividual/videos_talents/talent3_fede_rico/copaDavis.js";
import { GrillaFernandoCattori } from "./components/pages/3_talent/grillas/grillaFernandoCattori.js";
import FernandoCattori from "./components/pages/3_talent/dragTitle/fernandoCattori.js";
import RubioLlorar from "./components/pages/1_home/videoIndividual/videos_commercials/rubioLlorar.js";
import ElasYElmar from "./components/pages/1_home/videoIndividual/videos_commercials/elsaElmar.js";
import ElsaYElmar from "./components/pages/1_home/videoIndividual/videos_commercials/elsaElmar.js";
import PalacioDeHierro from "./components/pages/1_home/videoIndividual/videos_commercials/palacioDeHierro.js";
import UltraHardSeltzer from "./components/pages/1_home/videoIndividual/videos_commercials/michelobUltra.js";
import RunnersAyudandoRunners from "./components/pages/1_home/videoIndividual/videos_commercials/runnersAyudando.js";
import GqCartier from "./components/pages/1_home/videoIndividual/videos_commercials/cartier.js";
import MichelobLanzamiento from "./components/pages/1_home/videoIndividual/videos_commercials/michelobLanzamiento.js";
import FernandoCattoriEn from "./components/pages_english/3_talent/dragTitle/fernandoCattoriEn.js";
import CarmenRivoira from "./components/pages/3_talent/dragTitle/carmenRivoira.js";
import Bonafont from "./components/pages/1_home/videoIndividual/videos_commercials/bonafont.js";
import Prosegur from "./components/pages/1_home/videoIndividual/videos_commercials/prosegur.js";
import MariaWolf from "./components/pages/1_home/videoIndividual/videos_commercials/mariaWolf.js";
import Chita from "./components/pages/1_home/videoIndividual/videos_commercials/chita.js";
import IslaDeCaras from "./components/pages/1_home/videoIndividual/videos_commercials/islaDeCaras.js";
import ProsegurEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/prosegurEn.js";
import CarmenRivoiraEn from "./components/pages_english/3_talent/dragTitle/carmenRivoiraEn.js";
import { GrillaCarmenRivoiraEn } from "./components/pages_english/3_talent/grillas/grillaCarmenRivoira.js";
import { GrillaCarmenRivoira } from "./components/pages/3_talent/grillas/grillaCarmenRivoira.js";
import MercadoPago from "./components/pages/1_home/videoIndividual/videos_commercials/mercadoPago.js";
import MercadoPagoEn from "./components/pages/1_home/videoIndividual/videos_commercials/mercadoPagoEn.js";
import PersonalPayCompartir from "./components/pages_english/1_home/videoIndividual/videos_commercials/personalPayCompartir.js";
import PersonalPayCompartirEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/personalPayCompartirEn.js";
import WhileYouLive from "./components/pages_english/1_home/videoIndividual/videos_commercials/whileYouLive.js";
import Sellers from "./components/pages/1_home/videoIndividual/videos_commercials/sellers.js";
import BetwarriorEs from "./components/pages_english/1_home/videoIndividual/videos_commercials/betwarriorEs.js";
import BetwarriorEn from "./components/pages_english/1_home/videoIndividual/videos_commercials/betwarriorEn.js";
import Conmebol from "./components/pages_english/1_home/videoIndividual/videos_commercials/conmebol.js";
import GatoradeEs from "./components/pages_english/1_home/videoIndividual/videos_commercials/gatorade.js";
import AmorMio from "./components/pages/1_home/videoIndividual/videos_commercials/amorMio.js";
import Jealousy from "./components/pages/1_home/videoIndividual/videos_commercials/jealousy.js";
import BigGroom from "./components/pages/1_home/videoIndividual/videos_commercials/bigRoom.js";
import Consumo from "./components/pages/1_home/videoIndividual/videos_commercials/consumo.js";
import Garpamos from "./components/pages/1_home/videoIndividual/videos_commercials/garpamos.js";
import ConsumoEn from "./components/pages/1_home/videoIndividual/videos_commercials/consumoEn.js";
import GarpamosEn from "./components/pages/1_home/videoIndividual/videos_commercials/garpamosEn.js";


// import HomeTalentsEs from './components/pages/3_talent/dragTitle/HomeTalentsES';

// colors: violeta: #986cff - verde: #d2ff68

const IndexPage = () => {
  function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  return (
    <Router>
      {/* <DirectorsProvider> */}
        <Switch>
          {/* intro components */}

          {/* <Route path="/welcome" exact>
          <Real_Intro />
        </Route>
        <Route path="/welcome2" exact>
          <Intro_segunda_capa />
        </Route>

        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/login2" exact>
          <LoginPage2 />
        </Route>

        <Route path="/register" exact>
          <RegisterPage />
        </Route>
        <Route path="/register2" exact>
          <Register2 />
        </Route> */}

          {/*WebSite Components*/}

          {/* <Route path="/" exact>
      <Real_Intro />
    </Route> */}

          {/* admin */}
          {/* <Route path="/admin/home" exact>
      <HomeAdmin />
    </Route> */}

          {/*  */}

          <Route path="/" exact>
            {isMobile() ? <GrillasWorks_ES /> : <Home_ES />}
            {/* <AdminSelect/> */}
          </Route>

          <Route path="/es/inicio" exact>
            <Home_ES />
          </Route>

          <Route path="/en/home/" exact>
            <Home_EN />
          </Route>

          {/* Talents */}

          <Route path="/es/talents" exact>
            <Talent />
          </Route>
          <Route path="/en/talents" exact>
            <Talent_EN />
          </Route>

          {/* title TALENTS ESPAÑOL */}

          <Route path="/es/fede-garcia-rico" exact>
            <DragFedeGR />
          </Route>

          <Route path="/es/santiago-franco" exact>
            <SantiagoFranco />
          </Route>
          <Route path="/es/mariano-nante" exact>
            <MarianoNante />
          </Route>

          <Route path="/es/diego-fried" exact>
            <DiegoFried />
          </Route>
          
          <Route path="/es/fernando-cattori" exact>
            <FernandoCattori />
          </Route>
          <Route path="/es/carmen-rivoira" exact>
            <CarmenRivoira />
          </Route>

          <Route path="/es/gonzo-llorente" exact>
            <Gonzo />
          </Route>

          <Route path="/es/hernan-corera" exact>
            <HernanCorera />
          </Route>

          <Route path="/es/martina-galli-agulla" exact>
            <MartinaGalli />
          </Route>
          <Route path="/es/olivia-sanchez-ruival" exact>
            <OliviaSanchez />
          </Route>

          <Route path="/es/santa" exact>
            <Santa />
          </Route>
          <Route path="/es/keane-shaw" exact>
            <KeaneShaw />
          </Route>
          <Route path="/es/salomon" exact>
            <SalomonSimhon />
          </Route>

          {/* title TALENTS INGLES*/}

          <Route path="/en/fede-garcia-rico" exact>
            <DragFedeGR_EN />
          </Route>

          <Route path="/en/santiago-franco" exact>
            <SantiagoFrancoEn />
          </Route>
          <Route path="/en/mariano-nante" exact>
            <MarianoNanteEn />
          </Route>

          <Route path="/en/diego-fried" exact>
            <DiegoFried_EN />
          </Route>

          <Route path="/en/gonzo-llorente" exact>
            <Gonzo_EN />
          </Route>

          <Route path="/en/hernan-corera" exact>
            <HernanCorera_EN />
          </Route>
          <Route path="/en/santa" exact>
            <SantaEn />
          </Route>
          <Route path="/es/steve-bruno" exact>
            <SteveBruno />
          </Route>
          <Route path="/en/steve-bruno" exact>
            <SteveBrunoEn />
          </Route>

          <Route path="/es/kacper-larski" exact>
            <KacperLarski />
          </Route>
          <Route path="/en/kacper-larski" exact>
            <KacperLarskiEn />
          </Route>
          <Route path="/en/fernando-cattori" exact>
            <FernandoCattoriEn />
          </Route>
          <Route path="/en/keane-shaw" exact>
            <KeaneShawEn />
          </Route>
          <Route path="/en/martina-galli-agulla" exact>
            <MartinaGalliEn />
          </Route>
          <Route path="/en/olivia-sanchez-ruival" exact>
            <OliviaSanchezEn />
          </Route>
          <Route path="/en/salomon" exact>
            <SalomonSimhonEn />
          </Route>
          <Route path="/en/carmen-rivoira" exact>
            <CarmenRivoiraEn />
          </Route>

          {/* videos en ESPAÑOL*/}

          <Route path="/es/netflix-3-en-1" exact>
            <Netflix3En1_ES />
          </Route>
          <Route path="/es/netflix-3-en-1" exact>
            <Netflix3En1_EN />
          </Route>
          <Route path="/es/sahara" exact>
            <LesSaharaEs />
          </Route>
          <Route path="/es/personal-conexion">
            <PersonalConexEs />
          </Route>
          <Route path='/es/llorar' exact>
            <RubioLlorar />
          </Route>
          <Route path='/es/elsa-y-elmar' exact>
            <ElsaYElmar />
          </Route>
          <Route path='/es/palacio-de-hierro' exact>
            <PalacioDeHierro />
          </Route>
          <Route path='/es/ultra-hard-seltzer' exact>
            <UltraHardSeltzer />
          </Route>
          <Route path='/es/runners-ayudando' exact>
            <RunnersAyudandoRunners />
          </Route>
          <Route path='/es/cartier' exact>
            <GqCartier />
          </Route>
          <Route path='/es/michelob-lanzamiento' exact>
            <MichelobLanzamiento />
          </Route>

          <Route path='/es/bonafont' exact>
            <Bonafont />
          </Route>
          <Route path='/es/prosegur' exact>
            <Prosegur />
          </Route>
          <Route path='/es/maria-wolf' exact>
            <MariaWolf />
          </Route>
          <Route path='/es/chita' exact>
            <Chita />
          </Route>
          <Route path='/es/isla-de-caras' exact>
            <IslaDeCaras />
          </Route>
          <Route path='/es/amor-mio' exact>
            <AmorMio />
          </Route>
          <Route path='/es/big-groom' exact>
            <BigGroom />
          </Route>
          <Route path='/es/jealousy' exact>
            <Jealousy />
          </Route>

          <Route path="/en/personal-conexion">
            <PersonalConexEn />
          </Route>
          <Route path="/es/bgh-calurosamente" exact>
            <BghCalurosamenteEs />
          </Route>
          <Route path="/en/bgh-calurosamente" exact>
            <BghCalurosamenteEn />
          </Route>
          <Route path="/es/citibanamex" exact>
            <Citibanamex />
          </Route>
          <Route path="/en/citibanamex" exact>
            <Citibanamex />
          </Route>
          <Route path="/es/sellers" exact>
            <Sellers />
          </Route>
          <Route path="/es/garpamos" exact>
            <Garpamos />
          </Route>
          <Route path="/es/consumo" exact>
            <Consumo />
          </Route>
          <Route path="/en/garpamos" exact>
            <GarpamosEn />
          </Route>
          <Route path="/en/consumo" exact>
            <ConsumoEn />
          </Route>


          {/* Santa en espanol */}
          <Route path="/es/chrysler-santa" exact>
            <ChryslerSanta />
          </Route>
          <Route path="/es/we-love-tacos" exact>
            <WeLoveTacos />
          </Route>
          <Route path="/es/jeep-liberty" exact>
            <Jeep />
          </Route>
          <Route path="/es/solo-good-vibes" exact>
            <GoodVibes />
          </Route>
          <Route path="/es/regreso-a-clase" exact>
            <RegresoClase />
          </Route>
          <Route path="/es/elevate-your-life" exact>
            <ElevateLife />
          </Route>
          <Route path="/es/no-filter" exact>
            <NoFilter />
          </Route>
          <Route path="/es/super-bock" exact>
            <SuperBock />
          </Route>
          <Route path="/es/open-for-more" exact>
            <OpenForMore />
          </Route>
          {/* Steve bruno espanol */}
          <Route path="/es/spotify-playlist" exact>
            <SpotifyPlaylist />
          </Route>
          <Route path="/es/drives-you-wonder" exact>
            <NissanDrives />
          </Route>
          <Route path="/es/blending" exact>
            <Blending />
          </Route>
          <Route path="/es/boticario-cuidese" exact>
            <Boticario />
          </Route>

          {/* Steve bruno ingles */}
          <Route path="/en/spotify-playlist" exact>
            <SpotifyPlaylist />
          </Route>
          <Route path="/en/drives-you-wonder" exact>
            <NissanDrives />
          </Route>
          <Route path="/en/blending" exact>
            <Blending />
          </Route>
          <Route path="/en/boticario-cuidese" exact>
            <Boticario />
          </Route>

          {/* Kacper espanol */}
          <Route path="/es/garmin" exact>
            <Garmin />
          </Route>
          <Route path="/es/ford" exact>
            <Ford />
          </Route>
          <Route path="/es/jeep-conform-rough" exact>
            <JeepConform />
          </Route>
          <Route path="/es/we-make-wow" exact>
            <Hyundai />
          </Route>
          <Route path="/es/land-rover-defender" exact>
            <LandDefender />
          </Route>
          <Route path="/es/mazda" exact>
            <Mazda />
          </Route>

          {/* Kacper ingles */}
          <Route path="/en/garmin" exact>
            <Garmin />
          </Route>
          <Route path="/en/ford" exact>
            <Ford />
          </Route>
          <Route path="/en/jeep-conform-rough" exact>
            <JeepConform />
          </Route>
          <Route path="/en/we-make-wow" exact>
            <Hyundai />
          </Route>
          <Route path="/en/land-rover-defender" exact>
            <LandDefender />
          </Route>
          <Route path="/en/mazda" exact>
            <Mazda />
          </Route>

          {/* Santa en ingles */}
          <Route path="/en/chrysler-santa" exact>
            <ChryslerSanta />
          </Route>
          <Route path="/en/we-love-tacos" exact>
            <WeLoveTacos />
          </Route>
          <Route path="/en/jeep-liberty" exact>
            <Jeep />
          </Route>
          <Route path="/en/solo-good-vibes" exact>
            <GoodVibes />
          </Route>
          <Route path="/en/regreso-a-clase" exact>
            <RegresoClase />
          </Route>
          <Route path="/en/elevate-your-life" exact>
            <ElevateLife />
          </Route>
          <Route path="/en/no-filter" exact>
            <NoFilter />
          </Route>
          <Route path="/en/super-bock" exact>
            <SuperBock />
          </Route>
          <Route path="/en/open-for-more" exact>
            <OpenForMore />
          </Route>

          {/* Keane Shaw espanol */}
          <Route path="/es/nike" exact>
            <Nike />
          </Route>
          <Route path="/es/betano-sportsbook" exact>
            <Betano />
          </Route>
          <Route path="/es/champion-hoodie" exact>
            <Champion />
          </Route>
          <Route path="/es/volleyball-championship" exact>
            <VolleyBall />
          </Route>
          <Route path="/es/nike-imagine" exact>
            <NikeImagine />
          </Route>
          <Route path="/es/tnt-sports" exact>
            <TntSports />
          </Route>
          <Route path="/es/fifa-juventus" exact>
            <Fifa />
          </Route>
          <Route path="/es/adidas-real-madrid" exact>
            <Adidas />
          </Route>
          <Route path="/es/puma-manchester" exact>
            <Puma />
          </Route>

          {/* Keane Shaw ingles */}
          <Route path="/en/nike" exact>
            <Nike />
          </Route>
          <Route path="/en/betano-sportsbook" exact>
            <Betano />
          </Route>
          <Route path="/en/champion-hoodie" exact>
            <Champion />
          </Route>
          <Route path="/en/volleyball-championship" exact>
            <VolleyBall />
          </Route>
          <Route path="/en/nike-imagine" exact>
            <NikeImagine />
          </Route>
          <Route path="/en/tnt-sports" exact>
            <TntSports />
          </Route>
          <Route path="/en/fifa-juventus" exact>
            <Fifa />
          </Route>
          <Route path="/en/adidas-real-madrid" exact>
            <Adidas />
          </Route>
          <Route path="/en/puma-manchester" exact>
            <Puma />
          </Route>

          {/* Solomon es */}
          <Route path="/en/riot-games-lol" exact>
            <RiotGames />
          </Route>
          <Route path="/es/100-años-soledad" exact>
            <Soledad />
          </Route>
          <Route path="/en/señorita" exact>
            <Senorita />
          </Route>
          <Route path="/es/colombina-abuela" exact>
            <Colombina />
          </Route>

          {/* Solomon en */}
          <Route path="/es/riot-games-lol" exact>
            <RiotGames />
          </Route>
          <Route path="/en/100-años-soledad" exact>
            <Soledad />
          </Route>
          <Route path="/es/señorita" exact>
            <Senorita />
          </Route>
          <Route path="/en/colombina-abuela" exact>
            <Colombina />
          </Route>

          <Route path="/es/netflix-estes-para-lo-que-estes-1" exact>
            <NetflixEstesParaLoQueEstes1_ES />
          </Route>
          <Route path="/es/netflix-estes-para-lo-que-estes-2" exact>
            <NetflixEstesParaLoQueEstes2_ES />
          </Route>
          <Route path="/es/netflix-estes-para-lo-que-estes-3" exact>
            <NetflixEstesParaLoQueEstes3_ES />
          </Route>
          <Route path="/en/netflix-estes-para-lo-que-estes-1" exact>
            <NetflixEstesParaLoQueEstes1_EN />
          </Route>
          <Route path="/en/netflix-estes-para-lo-que-estes-2" exact>
            <NetflixEstesParaLoQueEstes2_EN />
          </Route>
          <Route path="/en/netflix-estes-para-lo-que-estes-3" exact>
            <NetflixEstesParaLoQueEstes3_EN />
          </Route>
          <Route path="/es/bees" exact>
            <BeesEs />
          </Route>

          <Route path="/es/netflix-3-en-1" exact>
            <Netflix3En1_ES />
          </Route>
          <Route path="/es/netflix-3-en-1" exact>
            <Netflix3En1_ES />
          </Route>

          <Route path="/es/hecho-en-argentina" exact>
            <HechoEnArgentina />
          </Route>
          <Route path="/en/made-in-argentina" exact>
            <HechoEnArgentina_EN />
          </Route>

          <Route path="/es/betunoire" exact>
            <BetuNoire />
          </Route>
          <Route path="/en/betunoire" exact>
            <BetuNoire_EN />
          </Route>

          <Route path="/es/suenia-potente" exact>
            <SuenaPotente />
          </Route>
          <Route path="/en/dream-big" exact>
            <SuenaPotente_EN />
          </Route>

          <Route path="/es/nadie" exact>
            <Nadie />
          </Route>
          <Route path="/en/no-one" exact>
            <Nadie_EN />
          </Route>
          <Route path='/es/llorar' exact>
            <RubioLlorar />
          </Route>
          <Route path='/es/elsa-y-elmar' exact>
            <ElsaYElmar />
          </Route>
          <Route path='/en/palacio-de-hierro' exact>
            <PalacioDeHierro />
          </Route>
          <Route path='/en/ultra-hard-seltzer' exact>
            <UltraHardSeltzer />
          </Route>
          <Route path='/en/runners-ayudando' exact>
            <RunnersAyudandoRunners />
          </Route>
          <Route path='/en/cartier' exact>
            <GqCartier />
          </Route>
          <Route path='/en/michelob-lanzamiento' exact>
            <MichelobLanzamiento />
          </Route>

          <Route path='/es/mercado-pago' exact>
            <MercadoPago />
          </Route>
          <Route path='/en/mercado-pago' exact>
            <MercadoPagoEn />
          </Route>

          <Route path='/en/bonafont' exact>
            <Bonafont />
          </Route>
          <Route path='/en/prosegur' exact>
            <ProsegurEn />
          </Route>
          <Route path='/en/maria-wolf' exact>
            <MariaWolf />
          </Route>
          <Route path='/en/chita' exact>
            <Chita />
          </Route>
          <Route path='/en/isla-de-caras' exact>
            <IslaDeCaras />
          </Route>

          <Route path='/es/while-you-live' exact>
            <WhileYouLive />
          </Route>

          <Route path='/en/while-you-live' exact>
            <WhileYouLive />
          </Route>
          <Route path='/es/betwarrior' exact>
            <BetwarriorEs />
          </Route>

          <Route path='/en/betwarrior' exact>
            <BetwarriorEn />
          </Route>
          
          <Route path='/en/conmebol' exact>
            <Conmebol />
          </Route>

          <Route path='/en/gatorade' exact>
            <GatoradeEs />
          </Route>
          <Route path='/es/gatorade' exact>
            <GatoradeEs />
          </Route>

          <Route path='/en/personal-pay-esta-bueno-compartirlo' exact>
            <PersonalPayCompartirEn />
          </Route>
          <Route path='/es/personal-pay-esta-bueno-compartirlo' exact>
            <PersonalPayCompartir />
          </Route>

          <Route path="/es/paraiso" exact>
            <Paraiso />
          </Route>
          <Route path="/en/paradise" exact>
            <Paraiso_EN />
          </Route>

          <Route path="/es/romance-eterno" exact>
            <RomanceEterno />
          </Route>
          <Route path="/en/eternal-romance" exact>
            <RomanceEterno_EN />
          </Route>
          <Route path="/es/supervielle" exact>
            <Supervielle />
          </Route>
          <Route path="/en/supervielle" exact>
            <Supervielle_EN />
          </Route>
          <Route path="/es/alimentando-sueños" exact>
            <AlimentandoSueños />
          </Route>
          <Route path="/en/feeding-dreams" exact>
            <AlimentandoSueños_EN />
          </Route>

          <Route path="/es/el-amor-nos-salva" exact>
            <ElAmorNosSalva />
          </Route>
          <Route path="/en/love-save-us" exact>
            <ElAmorNosSalva_EN />
          </Route>

          <Route path="/es/la-vida-artois" exact>
            <LaVidaArtois />
          </Route>
          <Route path="/en/artois-s-life" exact>
            <LaVidaArtois_EN />
          </Route>

          <Route path="/es/sex-education" exact>
            <SexEducation />
          </Route>
          <Route path="/en/sex-education" exact>
            <SexEducation_EN />
          </Route>

          <Route path="/es/del-cine-a-tu-casa" exact>
            <DelCineATuCasaEs />
          </Route>
          <Route path="/en/del-cine-a-tu-casa" exact>
            <DelCineATuCasaEn />
          </Route>

          <Route path="/es/kelloggs-pop-tarts" exact>
            <KelloggsPopTartsEs />
          </Route>
          <Route path="/en/kelloggs-pop-tarts" exact>
            <KelloggsPopTartsEn />
          </Route>

          <Route path="/es/galicia-app" exact>
            <GaliciaAppEs />
          </Route>
          <Route path="/en/galicia-app" exact>
            <GaliciaAppEn />
          </Route>

          <Route path="/es/idolas" exact>
            <YPFIdolasEs />
          </Route>
          <Route path="/es/burger-king-netflix">
            <BurgerNetflix />
          </Route>

          <Route path="/en/idolas" exact>
            <YPFIdolasEn />
          </Route>

          <Route path="/es/la-evolucion-del-lojack" exact>
            <StrixLoJackEs />
          </Route>
          <Route path="/en/la-evolucion-del-lojack" exact>
            <StrixLoJackEn />
          </Route>

          <Route path="/es/lo-que-nos-hace-bien" exact>
            <LoQueNosHaceBienEs />
          </Route>
          <Route path="/en/lo-que-nos-hace-bien" exact>
            <LoQueNosHaceBienEn />
          </Route>

          <Route path="/es/rugby-world-cup" exact>
            <RugbyWorldCup />
          </Route>
          <Route path="/en/rugby-world-cup" exact>
            <RugbyWorldCup_EN />
          </Route>

          <Route path="/es/dokkaebis" exact>
            <Dokkaebis />
          </Route>
          <Route path="/en/dokkaebis" exact>
            <Dokkaebis_EN />
          </Route>

          <Route path="/es/el-idolo" exact>
            <El_Idolo />
          </Route>

          <Route path="/en/the-idole" exact>
            <El_Idolo_EN />
          </Route>

          <Route path="/es/everytime" exact>
            <Everytime />
          </Route>
          <Route path="/en/everytime" exact>
            <Everytime_EN />
          </Route>

          <Route path="/es/mi-vida" exact>
            <MiVida />
          </Route>
          <Route path="/en/mi-vida" exact>
            <MiVida_EN />
          </Route>
          <Route path="/es/el-lado-oscuro-del-corazon" exact>
            <ElLadoOscuroDelCorazonEs />
          </Route>
          <Route path="/en/the-dark-side-of-the-heart" exact>
            <ElLadoOscuroDelCorazonEn />
          </Route>
          <Route path="/es/bplay-sponsor-oficial" exact>
            <BplaySponsorOficialEs />
          </Route>
          <Route path="/es/bplay-sponsor-oficial" exact>
            <BplaySponsorOficialEn />
          </Route>
          <Route path="/en/bplay" exact>
            <BplayCoraArgentoEn />
          </Route>
          <Route path="/es/bplay" exact>
            <BplayCoraArgentoEs />
          </Route>

          <Route path="/es/beldent" exact>
            <BeldentEs />
          </Route>
          <Route path="/en/beldent" exact>
            <BeldentEN />
          </Route>
          <Route path="/es/molinos" exact>
            <MolinosEs />
          </Route>
          <Route path="/en/molinos" exact>
            <MolinosEn />
          </Route>

          <Route path="/es/mi-vida" exact>
            <MiVida />
          </Route>
          <Route path="/en/mi-vida" exact>
            <MiVida_EN />
          </Route>

          <Route path="/es/un-amor-verdadero" exact>
            <UnAmorVerdadero />
          </Route>
          <Route path="/en/a-true-love" exact>
            <UnAmorVerdadero_EN />
          </Route>

          <Route path="/es/you-are-not-alone" exact>
            <YouAreNotAlone />
          </Route>
          <Route path="/en/you-are-not-alone" exact>
            <YouAreNotAlone_EN />
          </Route>

          <Route path="/es/la-calle-de-los-pianistas" exact>
            <LaCalleDeLosPianistas />
          </Route>
          <Route path="/en/the-pianists-s-street" exact>
            <LaCalleDeLosPianistas_EN />
          </Route>

          <Route path="/es/toque-brahma-pomelo" exact>
            <ToqueBrahma />
          </Route>
          <Route path="/en/touch-brahma-pomelo" exact>
            <ToqueBrahma_EN />
          </Route>

          <Route path="/es/brahma-diminutivos" exact>
            <BrahmaDiminutivos />
          </Route>
          <Route path="/en/brahma-diminutives" exact>
            <BrahmaDiminutivos_EN />
          </Route>

          <Route path="/es/jugos-del-valle" exact>
            <JugosDelValle />
          </Route>
          <Route path="/en/jugos-del-valle" exact>
            <JugosDelValle_EN />
          </Route>

          <Route path="/es/purina-dog-chow" exact>
            <PurinaDogChow />
          </Route>

          <Route path="/en/purina-dog-chow" exact>
            <PurinaDogChow_EN />
          </Route>

          <Route path="/es/purina" exact>
            <PurinaEs />
          </Route>

          <Route path="/es/no-se-chonguea" exact>
            <NoSeChonguea />
          </Route>
          <Route path="/en/no-se-chonguea" exact>
            <NoSeChonguea_EN />
          </Route>

          <Route path="/es/incrediblends-dada" exact>
            <IncrediblendsDada />
          </Route>
          <Route path="/en/incrediblends-dada" exact>
            <IncrediblendsDada_EN />
          </Route>

          <Route path="/es/trap-argento" exact>
            <TrapArgento />
          </Route>
          <Route path="/en/trap-argento" exact>
            <TrapArgento_EN />
          </Route>
          <Route path="/es/otro-deporte" exact>
            <OtroDeporte />
          </Route>
          <Route path="/en/another-sport" exact>
            <OtroDeporte_EN />
          </Route>
          <Route path="/es/americanino" exact>
            <AmericaninoES />
          </Route>
          <Route path="/en/americanino" exact>
            <Americanino_EN />
          </Route>

          {/* videos gonzo llorente /> */}
          <Route path="/es/nowness" exact>
            <Nowness />
          </Route>
          <Route path="/en/nowness" exact>
            <Nowness_EN />
          </Route>

          <Route path="/es/your-days-are-precious" exact>
            <ZurichInsurance />
          </Route>
          <Route path="/en/your-days-are-precious" exact>
            <ZurichInsurance_EN />
          </Route>

          <Route path="/es/uber-boxer" exact>
            <Boxer />
          </Route>
          <Route path="/en/uber-boxer" exact>
            <Boxer_EN />
          </Route>

          <Route path="/es/aguas-de-rio-de-janeiro" exact>
            <AguasDeRioDeJaneiro />
          </Route>
          <Route path="/en/rio-de-janeiro-s-water" exact>
            <AguasDeRioDeJaneiro_EN />
          </Route>

          <Route path="/es/your-life" exact>
            <HyundaiYourLife />
          </Route>
          <Route path="/en/your-life" exact>
            <HyundaiYourLife_EN />
          </Route>

          <Route path="/es/nivea-beauty" exact>
            <NiveaBeauty />
          </Route>
          <Route path="/en/nivea-beauty" exact>
            <NiveaBeauty_EN />
          </Route>


          {/* videos fede garcia rico /> */}
          <Route path="/es/208-peugeot" exact>
            <Peugeot />
          </Route>
          <Route path="/en/208-peugeot" exact>
            <Peugeot_EN />
          </Route>
          <Route path="/es/copa-davis" exact>
            <CopaDavis />
          </Route>
          <Route path="/en/copa-davis" exact>
            <CopaDavis />
          </Route>

          <Route path="/es/aspiremos-a-mas" exact>
            <AspiremosAmas />
          </Route>
          <Route path="/en/aspire-for-more" exact>
            <AspiremosAmas_EN />
          </Route>

          <Route path="/es/inmigrantes-digitales" exact>
            <InmigrantesDigitales />
          </Route>
          <Route path="/en/digital-inmigrants" exact>
            <InmigrantesDigitales_EN />
          </Route>

          <Route path="/es/stella-vintage" exact>
            <StellaVintage />
          </Route>
          <Route path="/en/stella-vintage" exact>
            <StellaVintage_EN />
          </Route>

          {/* videos la dulce liberal /> */}
          <Route path="/es/actron-gel" exact>
            <ActronGel />
          </Route>

          <Route path="/en/actron-gel" exact>
            <ActronGel_EN />
          </Route>

          <Route path="/es/becks-hacelo-diferente" exact>
            <BecksHaceloDiferente />
          </Route>
          <Route path="/en/becks-do-it-different" exact>
            <BecksHaceloDiferente_EN />
          </Route>

          <Route path="/es/sprite-love-you-hater" exact>
            <SpriteLoveYouHater />
          </Route>
          <Route path="/en/sprite-love-you-hater" exact>
            <SpriteLoveYouHater_EN />
          </Route>

          <Route path="/es/movistar-mas-gigas" exact>
            <MovistarMasGigas />
          </Route>
          <Route path="/en/movistar-more-gig" exact>
            <MovistarMasGigas_EN />
          </Route>
          <Router path="/en/diarco" exact>
            <Diarco />
          </Router>
          <Router path="/es/diarco" exact>
            <Diarco />
          </Router>

          <Route path="/es/michelob-ultra" exact>
            <MichelobUltra />
          </Route>
          <Route path="/en/michelob-ultra" exact>
            <MichelobUltra_EN />
          </Route>
          <Route path="/es/jumbo-productos-abandonados" exact>
            <JumboProductosAbandonados />
          </Route>
          <Route path="/en/jumbo-abandoned-products" exact>
            <JumboProductosAbandonados_EN />
          </Route>

          {/* videos diego-fried /> */}
          <Route path="/es/cereal-mix" exact>
            <CerealMix />
          </Route>
          <Route path="/en/cereal-mix" exact>
            <CerealMix_EN />
          </Route>

          <Route path="/es/la-fiesta-silenciosa" exact>
            <LaFiestaSilenciosa />
          </Route>
          <Route path="/en/the-quiet-party" exact>
            <LaFiestaSilenciosa_EN />
          </Route>

          <Route path="/es/challenge-gillette" exact>
            <ChallengeGillette />
          </Route>
          <Route path="/en/challenge-gillette" exact>
            <ChallengeGillette_EN />
          </Route>

          <Route path="/es/love-story-ripley" exact>
            <LoveStoryRipley />
          </Route>
          <Route path="/en/love-story-ripley" exact>
            <LoveStoryRipley_EN />
          </Route>
          <Route path="/es/sin-senial" exact>
            <SinSenialES />
          </Route>
          <Route path="/en/no-signal" exact>
            <SinSenial_EN />
          </Route>
          <Route path="/es/el-esteco" exact>
            <ElEsteco />
          </Route>
          <Route path="/en/el-esteco" exact>
            <ElEstecoEN />
          </Route>

          {/* videos films  /> */}
          <Route path="/es/gilda" exact>
            <FilmGilda />
          </Route>
          <Route path="/en/gilda" exact>
            <FilmGilda_EN />
          </Route>

          <Route path="/es/figuras" exact>
            <FilmFiguras />
          </Route>
          <Route path="/en/figures" exact>
            <FilmFiguras_EN />
          </Route>

          <Route path="/es/hombre-en-la-llanura" exact>
            <FilmHombreEnLaLlanura />
          </Route>
          <Route path="/en/man-on-the-plain" exact>
            <FilmHombreEnLaLlanura_EN />
          </Route>

          <Route path="/es/paula" exact>
            <FilmPaula />
          </Route>
          <Route path="/en/paula" exact>
            <FilmPaula_EN />
          </Route>

          <Route path="/es/schwaneck" exact>
            <FilmSchwaneck />
          </Route>
          <Route path="/en/schwaneck" exact>
            <FilmSchwaneck_EN />
          </Route>

          <Route path="/es/schettinimous" exact>
            <FilmSchettinimous />
          </Route>
          <Route path="/en/schettinimous" exact>
            <FilmSchettinimous_EN />
          </Route>

          {/* grillas talents de cada uno en ESPAÑOL */}

          <Route path="/es/todo-fede-garcia-rico" exact>
            <GrillaFedeGR />
          </Route>

          <Route path="/es/todo-mariano-nante" exact>
            <GrillaMarianoNante />
          </Route>
          <Route path="/es/todo-santiago-franco" exact>
            <GrillaSantiagoFranco />
          </Route>

          <Route path="/es/todo-diego-fried" exact>
            <GrillaDiegoFried />
          </Route>

          <Route path="/es/todo-gonzo-llorente" exact>
            <GrillaGonzo />
          </Route>

          <Route path="/es/todo-hernan-corera" exact>
            <GrillaHernanCorera />
          </Route>

          <Route path="/es/todo-olivia-sanchez-ruival" exact>
            <GrillaOliviaSanchez />
          </Route>
          <Route path="/es/todo-martina-galli-agulla" exact>
            <GrillaMartinaGalli />
          </Route>
          <Route path="/es/todo-santa" exact>
            <GrillaSanta />
          </Route>
          <Route path="/es/todo-steve-bruno" exact>
            <GrillaSteveBruno />
          </Route>
          <Route path="/es/todo-fernando-cattori" exact>
            <GrillaFernandoCattori />
          </Route>
          <Route path="/es/todo-kacper-larski" exact>
            <GrillaKacper />
          </Route>
          <Route path="/es/todo-keane-shaw" exact>
            <GrillaKeaneShaw />
          </Route>
          <Route path="/es/todo-salomon" exact>
            <GrillaSalomon />
          </Route>
          <Route path="/es/todo-fernando-cattori" exact>
            <GrillaFernandoCattori />
          </Route>
          <Route path="/es/todo-carmen-rivoira" exact>
            <GrillaCarmenRivoira />
          </Route>

          {/* grillas talents de cada uno EN INGLES */}
          <Route path="/en/all-fede-garcia-rico" exact>
            <GrillaFedeGR_EN />
          </Route>

          <Route path="/en/all-mariano-nante" exact>
            <GrillaMarianoNanteEn />
          </Route>
          <Route path="/en/all-santiago-franco" exact>
            <GrillaSantiagoFrancoEn />
          </Route>

          <Route path="/en/all-diego-fried" exact>
            <GrillaDiegoFried_EN />
          </Route>

          <Route path="/en/all-gonzo-llorente" exact>
            <GrillaGonzo_EN />
          </Route>

          <Route path="/en/all-hernan-corera" exact>
            <GrillaHernanCorera_EN />
          </Route>

          <Route path="/en/all-martina-galli-agulla" exact>
            <GrillaMartinaGalliEn />
          </Route>
          <Route path="/en/all-olivia-sanchez-ruival" exact>
            <GrillaOliviaSanchezEn />
          </Route>
          <Route path="/en/all-santa" exact>
            <GrillaSanta />
          </Route>
          <Route path="/en/all-steve-bruno" exact>
            <GrillaSteveBrunoEn />
          </Route>
          <Route path="/en/all-kacper-larski" exact>
            <GrillaKacperEn />
          </Route>
          <Route path="/en/all-keane-shaw" exact>
            <GrillaKeaneShaw />
          </Route>
          <Route path="/en/all-salomon" exact>
            <GrillaSalomonEn />
          </Route>
          <Route path="/en/all-carmen-rivoira" exact>
            <GrillaCarmenRivoiraEn />
          </Route>


          <Route path="/es/family" exact>
            <Family />
          </Route>
          <Route path="/en/family" exact>
            <Family_EN />
          </Route>

          {/* STILL */}
          <Route path="/es/home-stills" exact>
            <GrillaStill />
          </Route>
          <Route path="/en/home-stills" exact>
            <GrillaStill_EN />
          </Route>

          {/* HOME DE CADA FOTOGRAFO ESPAÑOL */}
          {/* tatu garcia */}
          <Route path="/es/tatu-garcia" exact>
            <GTatu_ES />
          </Route>

          {/* joe ekonen */}
          <Route path="/es/joe-ekonen" exact>
            <GJoe_ES />
          </Route>

          {/* ezequiel monjo */}
          <Route path="/es/eze-monjo" exact>
            <GEze_ES />
          </Route>

          {/* ioni epelbaum */}
          <Route path="/es/ioni-epelbaum" exact>
            <GIoni_ES />
          </Route>

          {/* sisso chouela */}
          <Route path="/es/sisso-chouela" exact>
            <GSisso_ES />
          </Route>

          {/* martin sigal */}
          <Route path="/es/martin-sigal" exact>
            <GSigalES />
          </Route>
          {/**/}

          {/* HOME DE CADA FOTOGRAFO INGLES */}
          {/* tatu garcia */}
          <Route path="/en/tatu-garcia" exact>
            <GTatu_EN />
          </Route>

          {/* joe ekonen */}
          <Route path="/en/joe-ekonen" exact>
            <GJoe_EN />
          </Route>

          {/* ezequiel monjo */}
          <Route path="/en/eze-monjo" exact>
            <GEze_EN />
          </Route>

          {/* ioni epelbaum */}
          <Route path="/en/ioni-epelbaum" exact>
            <GIoni_EN />
          </Route>

          {/* sisso chouela */}
          <Route path="/en/sisso-chouela" exact>
            <GSisso_EN />
          </Route>

          {/* martin sigal */}
          <Route path="/en/martin-sigal" exact>
            <GSigalEN />
          </Route>
          {/**/}

          {/* SLIDERS STILLS*/}
          {/*  joe ekonen*/}
          <Route path="/es/galeria-betunoire" exact>
            <S_Proy_Betunoire_ES />
          </Route>
          <Route path="/es/galeria-brahma" exact>
            <S_Proy_brahma_pomelo_ES />
          </Route>
          <Route path="/es/galeria-americanino" exact>
            <S_Proy_AmericaninoES />
          </Route>
          <Route path="/es/galeria-collab" exact>
            <S_Proy_collab_ES />
          </Route>
          <Route path="/es/galeria-mmic" exact>
            <S_Proy_mmic_ES />
          </Route>
          <Route path="/es/galeria-nadie" exact>
            <S_Proy_nadie_ES />
          </Route>
          <Route path="/es/galeria-sadaels-paraiso" exact>
            <S_Proy_sadaels_paraiso_ES />
          </Route>

          <Route path="/en/gallery-betunoire" exact>
            <S_Proy_Betunoire_EN />
          </Route>
          <Route path="/en/gallery-brahma" exact>
            <S_Proy_brahma_pomelo_EN />
          </Route>
          <Route path="/en/gallery-americanino" exact>
            <S_Proy_americanino_EN />
          </Route>
          <Route path="/en/gallery-collab" exact>
            <S_Proy_collab_EN />
          </Route>
          <Route path="/en/gallery-mmic" exact>
            <S_Proy_mmic_EN />
          </Route>
          <Route path="/en/gallery-nadie" exact>
            <S_Proy_nadie_EN />
          </Route>
          <Route path="/en/gallery-sadaels-paraiso" exact>
            <S_Proy_sadaels_paraiso_EN />
          </Route>

          {/* proyectos tatu garcia */}
          <Route path="/es/galeria-brugal" exact>
            <S_Proy_brugal_ES />
          </Route>
          <Route path="/es/galeria-fractal" exact>
            <S_Proy_fractal_ES />
          </Route>
          <Route path="/es/galeria-mmic-tatu" exact>
            <S_Proy_mmic_tatu_ES />
          </Route>
          <Route path="/es/galeria-remix" exact>
            <S_Proy_remix_b_ES />
          </Route>
          <Route path="/es/galeria-stella-artois" exact>
            <S_Proy_stella_artois_ES />
          </Route>
          <Route path="/es/galeria-toque-brahma" exact>
            <S_Proy_toque_brahma_ES />
          </Route>
          <Route path="/es/galeria-volkswagen" exact>
            <S_Proy_volkswagen_ES />
          </Route>
          <Route path="/es/galeria-fila" exact>
            <S_Proy_fila_ES />
          </Route>

          <Route path="/en/gallery-brugal" exact>
            <S_Proy_brugal_EN />
          </Route>
          <Route path="/en/gallery-fractal" exact>
            <S_Proy_fractal_EN />
          </Route>
          <Route path="/en/gallery-mmic-tatu" exact>
            <S_Proy_mmic_tatu_EN />
          </Route>
          <Route path="/en/gallery-remix" exact>
            <S_Proy_remix_b_EN />
          </Route>
          <Route path="/en/gallery-stella-artois" exact>
            <S_Proy_stella_artois_EN />
          </Route>
          <Route path="/en/gallery-toque-brahma" exact>
            <S_Proy_toque_brahma_EN />
          </Route>
          <Route path="/en/gallery-volkswagen" exact>
            <S_Proy_volkswagen_EN />
          </Route>
          <Route path="/en/gallery-fila" exact>
            <S_Proy_fila_EN />
          </Route>

          {/* proyectos ezequiel monjo */}
          <Route path="/es/galeria-editorial" exact>
            <S_Proy_Editorial_ES />
          </Route>
          <Route path="/es/galeria-cronicas" exact>
            <S_Proy_Cronica_ES />
          </Route>
          <Route path="/es/galeria-fifa22" exact>
            <S_Proy_Fifa_ES />
          </Route>

          <Route path="/en/gallery-editorial" exact>
            <S_Proy_Editorial_EN />
          </Route>
          <Route path="/en/gallery-cronicas" exact>
            <S_Proy_Cronica_EN />
          </Route>
          <Route path="/en/gallery-fifa22" exact>
            <S_Proy_Fifa_EN />
          </Route>

          {/* proyectos ioni epelbaum */}
          <Route path="/es/galeria-adidas" exact>
            <S_Proy_Adidas_ES />
          </Route>
          <Route path="/es/galeria-eye" exact>
            <S_Proy_Eye_ES />
          </Route>
          <Route path="/es/galeria-free" exact>
            <S_Proy_Free_ES />
          </Route>
          <Route path="/es/galeria-kostume" exact>
            <S_Proy_Kostume_ES />
          </Route>
          <Route path="/es/galeria-wejs" exact>
            <S_Proy_Wejs_ES />
          </Route>

          <Route path="/en/gallery-adidas" exact>
            <S_Proy_Adidas_EN />
          </Route>
          <Route path="/en/gallery-eye" exact>
            <S_Proy_Eye_EN />
          </Route>
          <Route path="/en/gallery-free" exact>
            <S_Proy_Free_EN />
          </Route>
          <Route path="/en/gallery-kostume" exact>
            <S_Proy_Kostume_EN />
          </Route>
          <Route path="/en/gallery-wejs" exact>
            <S_Proy_Wejs_EN />
          </Route>

          {/* proyectos sisso chouela */}
          <Route path="/es/galeria-hbo" exact>
            <S_Proy_HBO_ES />
          </Route>
          <Route path="/es/galeria-hippo" exact>
            <S_Proy_HIPPO_ES />
          </Route>
          <Route path="/es/galeria-nissan" exact>
            <S_Proy_NISSAN_ES />
          </Route>
          <Route path="/es/galeria-purina" exact>
            <S_Proy_PURINA_ES />
          </Route>
          <Route path="/es/galeria-spotify" exact>
            <S_Proy_SPOTIFY_ES />
          </Route>

          <Route path="/en/gallery-hbo" exact>
            <S_Proy_HBO_EN />
          </Route>
          <Route path="/en/gallery-hippo" exact>
            <S_Proy_HIPPO_EN />
          </Route>
          <Route path="/en/gallery-nissan" exact>
            <S_Proy_NISSAN_EN />
          </Route>
          <Route path="/en/gallery-purina" exact>
            <S_Proy_PURINA_EN />
          </Route>
          <Route path="/en/gallery-spotify" exact>
            <S_Proy_SPOTIFY_EN />
          </Route>

          {/* proyectos martin sigal */}
          <Route path="/es/galeria-advertising" exact>
            <S_Proy_Advertising_ES />
          </Route>
          <Route path="/es/galeria-sports" exact>
            <S_Proy_Sports_ES />
          </Route>
          <Route path="/es/galeria-portrait" exact>
            <S_Proy_Portrait_ES />
          </Route>
          <Route path="/es/galeria-landscape" exact>
            <S_Proy_Landscape_ES />
          </Route>
          <Route path="/es/galeria-valle-de-uco" exact>
            <S_Proy_ValleDeUco_ES />
          </Route>
          <Route path="/es/galeria-the-returned" exact>
            <S_Proy_TheReturned_ES />
          </Route>
          <Route path="/es/galeria-beberages" exact>
            <S_Proy_Beberages_ES />
          </Route>
          <Route path="/es/galeria-el-esteco" exact>
            <S_Proy_ElEsteco_ES />
          </Route>

          <Route path="/en/gallery-el-esteco" exact>
            <S_Proy_ElEsteco_EN />
          </Route>
          <Route path="/en/gallery-advertising" exact>
            <S_Proy_Advertising_EN />
          </Route>
          <Route path="/en/gallery-sports" exact>
            <S_Proy_Sports_EN />
          </Route>
          <Route path="/en/gallery-portrait" exact>
            <S_Proy_Portrait_EN />
          </Route>
          <Route path="/en/gallery-landscape" exact>
            <S_Proy_Landscape_EN />
          </Route>
          <Route path="/en/gallery-valle-de-uco" exact>
            <S_Proy_ValleDeUco_EN />
          </Route>
          <Route path="/en/gallery-the-returned" exact>
            <S_Proy_TheReturned_EN />
          </Route>
          <Route path="/en/gallery-beberages" exact>
            <S_Proy_Beberages_EN />
          </Route>

          {/* WORKS */}

          {/* commercial */}

          <Route path="/es/commercial" exact>
            {isMobile() ? <GrillasCommercial /> : <Commercial_ES />}
            {/* <Commercial_ES /> */}
          </Route>
          <Route path="/en/commercial" exact>
            <Commercial_EN />
          </Route>
          <Route path="/es/todo-commercial" exact>
            <GrillasCommercial />
          </Route>
          <Route path="/en/all-commercial" exact>
            <GrillasCommercial_EN />
          </Route>
          {/* selected */}

          <Route path="/es/selected" exact>
            <SelectWork_ES />
          </Route>
          <Route path="/en/selected" exact>
            <SelectWork_EN />
          </Route>
          <Route path="/es/todo-selected" exact>
            <GrillasWorks_ES />
          </Route>
          <Route path="/en/all-selected" exact>
            <GrillasWorks_EN />
          </Route>
          {/* content */}

          <Route path="/es/content" exact>
            <Content_ES />
          </Route>
          <Route path="/en/content" exact>
            <Content_EN />
          </Route>
          <Route path="/es/todo-content" exact>
            <GrillasContent />
          </Route>
          <Route path="/en/all-content" exact>
            <GrillasContent_EN />
          </Route>

          {/* FILM */}

          <Route path="/es/films" exact>
            <Film />
          </Route>
          <Route path="/en/films" exact>
            <Film_EN />
          </Route>

          {/*films components*/}

          <Route path="/es/film-gilda" exact>
            <Gilda />
          </Route>
          <Route path="/en/film-gilda" exact>
            <Gilda_EN />
          </Route>
          <Route path="/es/film-anoche" exact>
            <Anoche />
          </Route>
          <Route path="/en/film-last-night" exact>
            <Anoche_EN />
          </Route>
          <Route path="/es/film-figuras" exact>
            <Figuras />
          </Route>
          <Route path="/en/film-figures" exact>
            <Figuras_EN />
          </Route>
          <Route path="/es/film-schwaneck" exact>
            <Schwaneck />
          </Route>
          <Route path="/en/film-schwaneck" exact>
            <Schwaneck_EN />
          </Route>
          <Route path="/es/film-paula" exact>
            <Paula />
          </Route>
          <Route path="/en/film-paula" exact>
            <Paula_EN />
          </Route>
          <Route path="/es/film-hombre-en-la-llanura" exact>
            <Hombre />
          </Route>
          <Route path="/en/film-man-on-the-plain" exact>
            <Hombre_EN />
          </Route>
          <Route path="/es/film-schettinimous" exact>
            <Schettinimous />
          </Route>
          <Route path="/en/film-schettinimous" exact>
            <Schettinimous_EN />
          </Route>
          {/**/}

          <Redirect to="/" />
        </Switch>
      {/* </DirectorsProvider> */}
    </Router>
  );
};

export default IndexPage;
