import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import Mouse3 from "../../../mouse/Mouse3.js";
import { Pagination, Navigation, Mousewheel } from "swiper";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from "./videos/marianysanti2.mp4";
import "swiper/css";
import "swiper/css/bundle";
import "./styleSlideimage2.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import MetaTags from "react-meta-tags";
import KeyboardEventHandler from "react-keyboard-event-handler";

function MarianoNanteEn() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts title="MARIANO NANTE" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>MARIANO NANTE</title>
          <meta
            content="Dupla de directores de cine y publicidad representados por Mamá Húngara"
            description="Dupla de directores de cine y publicidad representados por Mamá Húngara"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() =>
              window.open("/en/personal-pay-esta-bueno-compartirlo", "_self")
            }
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/935536399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL PAY | ESTA BUENO COMPARTIRLO |"
              title2="Mariano Nante"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/suenia-potente", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUEÑA POTENTE | AMD "
                className="react-player-video"
                url="https://vimeo.com/515381672 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SUEÑA POTENTE | AMD |"
              title2="Marian & Santi"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bplay", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BPLAY | SPONSOR DEL CORAZON ARGENTO | "
              title2="Mariano Nante"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/supervielle", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUPERVIELLE | POR H O POR B | Mariano Nante"
                className="react-player-video"
                url="https://vimeo.com/840224683"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SUPERVIELLE | POR H O POR B |"
              title2="Mariano Nante"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/while-you-live", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/935937413"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HERA HYESANG PARK | WHILE YOU LIVE |"
              title2="Mariano Nante"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/citibanamex", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="CITIBANAMEX | MARIANO & SANTIAGO"
                className="react-player-video"
                url="https://vimeo.com/894116437"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="CITIBANAMEX |" title2="Mariano & Santiago" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/sellers", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="MERCADO PAGO | SELLERS | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/940933655"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MERCADO PAGO | SELLERS |"
              title2="Mariano Nante"
            />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/935536399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL PAY | ESTA BUENO COMPARTIRLO |"
              title2="Mariano Nante"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUEÑA POTENTE | AMD"
                className="react-player-video"
                url="https://vimeo.com/515381672"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="SUEÑA POTENTE | AMD" title2="Mariano Nante" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" BPLAY | SPONSOR DEL CORAZON ARGENTO"
              title2=" Mariano Nante"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUPERVIELLE | POR H O POR B | Mariano Nante"
                className="react-player-video"
                url="https://vimeo.com/840224683"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SUPERVIELLE | POR H O POR B |"
              title2="Mariano Nante"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/935937413"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HERA HYESANG PARK | WHILE YOU LIVE |"
              title2="Mariano Nante"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper"></div>
            <ReactPlayer
              alt="CITIBANAMEX | MARIANO & SANTIAGO"
              className="react-player-video"
              url="https://vimeo.com/894116437"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper"></div>
            <ReactPlayer
              alt="MERCADO PAGO | SELLERS | MARIANO NANTE"
              className="react-player-video"
              url="https://vimeo.com/940933655"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>

          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="ROMANCE ETERNO I FIFA 21 I Mariano Nante"
                className="react-player-video"
                url="https://vimeo.com/497254514"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ROMANCE ETERNO | FIFA 21"
              title2="Mariano Nante"
            />
            <a className="more" href="/en/all-mariano-nante">
              More
            </a>
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}
export default MarianoNanteEn;
