import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { HeaderMenu } from '../../../../headers/headerMenu';
import { HeaderLogoMH } from '../../../../headers/headerLogoMH';
import { useColorModeValue, Image, } from "@chakra-ui/react";
import MouseApp_blanco from "../../../../mouse/mouseApp_blanco.js";
import logoMHB from "../../../../img/500h/Asset_logo_blanco.png";
import '../videoIndividual.css';
import '../../../../footers/footerColors/footerColors.css';
import { FooterLogoColors } from '../../../../footers/footerColors/footerColors';

function IncrediblendsDada_EN(props) {
    return (
        <>
            {/* <HeaderMenuHome /> */}
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="player-wrapper">
                <MouseApp_blanco
                    urlVideo='https://vimeo.com/583411583'
                // urlTitulo='IncrediblendsDada'
                />

                <FooterLogoColors
                    LogoColor={logoMHB}
                />


            </div>
        </>
    )
}
export default IncrediblendsDada_EN