import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from '../../../headers/headerText';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';

// import './grillas-talents.css';

export const GrillaHernanCorera_EN = () => {
    return (<>
        <HeaderTexts
            title="HERNAN CORERA"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <div className='container-altura-grillas-talents'>
            <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/netflix-estes-para-lo-que-estes-1'></a>
                    <ReactPlayer
                        alt='NETFLIX | ESTES PARA LO QUE ESTES '
                        className="video-grilla-talents"
                        url="https://vimeo.com/859836991"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            NETFLIX | ESTES PARA LO QUE ESTES 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>

                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/netflix-estes-para-lo-que-estes-2'></a>
                    <ReactPlayer
                        alt='NETFLIX | ESTES PARA LO QUE ESTES '
                        className="video-grilla-talents"
                        url="https://vimeo.com/857963876"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            NETFLIX | ESTES PARA LO QUE ESTES 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/netflix-estes-para-lo-que-estes-3'></a>
                    <ReactPlayer
                        alt='NETFLIX | ESTES PARA LO QUE ESTES '
                        className="video-grilla-talents"
                        url="https://vimeo.com/856880777"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            NETFLIX | ESTES PARA LO QUE ESTES 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/no-one'></a>
                    <ReactPlayer
                        alt='NADIE I SIN BANDERA '
                        className="video-grilla-talents"
                        url="https://vimeo.com/707412751 "

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            NADIE | SIN BANDERA
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>

                </div>
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/amor-mio'></a>
                    <ReactPlayer
                        alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                        className="video-grilla-talents"
                        url="https://vimeo.com/954460674"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            AMOR MIO | MARIA CAMPOS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera & Mateo Yaya
                        </p>
                    </div>

                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/beldent'></a>
                    <ReactPlayer
                        alt='UN AMOR VERDADERO | SADAELS '
                        className="video-grilla-talents"
                        url="https://vimeo.com/763421158"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            BELDENT | SHOW THEM YOU'RE READY
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera & Juan Piczman
                        </p>
                    </div>
                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/paradise'></a>
                    <ReactPlayer
                        alt='UN AMOR VERDADERO | SADAELS '
                        className="video-grilla-talents"
                        url="https://vimeo.com/520234550"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            PARAISO | SADAELS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>
                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/you-are-not-alone'></a>
                    <ReactPlayer
                        alt="YOU'RE NOT ALONE I AGORIA"
                        className="video-grilla-talents"
                        url="https://vimeo.com/310419904"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            YOU'RE NOT ALONE | AGORIA
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>
                </div>

                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/a-true-love'></a>
                    <ReactPlayer
                        alt='UN AMOR VERDADERO | SADAELS '
                        className="video-grilla-talents"
                        url="https://vimeo.com/471045785 "

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            A TRUE LOVE | SADAELS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera
                        </p>
                    </div>
                </div>
                {/*  */}
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/made-in-argentina'></a>
                    <ReactPlayer
                        alt='Hecho en Argentina | Netflix | Hernán Corera y Juan Piczman'
                        className="video-grilla-talents"
                        url="https://vimeo.com/693596507"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            MADE IN ARGENTINA | NETFLIX
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Hernán Corera & Juan Piczman
                        </p>
                    </div>
                </div>
                {/*  */}
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}

