import React from 'react';

import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from '../../../footers/textFooter';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '.././grillaStill.css';

import img1 from '../../5_still/sisso-chouela/SPOTIFY/trap-spotify-siso-1.jpg'
import img2 from '../../5_still/sisso-chouela/HBO/siso-hbo-1.jpg'
import img3 from '../../5_still/sisso-chouela/HIPPO/hippo-siso-3.jpg'
import img4 from '../../5_still/sisso-chouela/NISSAN/nissan-siso-2.jpg'
import img5 from '../../5_still/sisso-chouela/SPOTIFY/spotify-trap-edit.jpg'
import img6 from '../../5_still/sisso-chouela/PURINA/purina-siso-4.jpg'


export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GSisso_EN = () => {
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="container-grillas-still ">

                <Img50
                    src={img3}
                    href="/en/gallery-hippo"
                    title="HIPPO"
                />
                <Img50
                    src={img4}
                    href="/en/gallery-nissan"
                    title="NISSAN"
                />
                <Img100
                    src={img2}
                    href="/en/gallery-hbo"
                    title="HBOmax"
                />
                <Img50
                    src={img5}
                    href="/en/gallery-spotify"
                    title="TRAP | SPOTIFY"
                />
                <Img50
                    src={img6}
                    href="/en/gallery-purina"
                    title="PURINA | DOG CHOW"
                />
            </div>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

