import React from 'react';
import { useColorModeValue, Image, } from "@chakra-ui/react";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';
import logoMHB from "../../../img/500h/Asset_logo_blanco.png"
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '../internoFilm.css';



export const Schwaneck_EN = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderLogoMH />
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                    Julieta wants to know how did her parents meet so she investigates interviewing her past in a playful use of her memory.
                    </p>
                    <a href='/schwaneck' className='title-video-mobile-film'>
                    SCHWANECK
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/en/schwaneck'>
                                SCHWANECK
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Directed by
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                JULIETA GIMENEZ ZAPIOLA
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Género | Ficción
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duración | 21 minutos
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Formato de rodaje | 4K
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Formato de proyección | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sonido | Estéreo
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Idioma | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                País | Argentina
                            </p>
                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo='https://vimeo.com/326584917' />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/films"
            />
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

