import React from 'react';
import { FooterSmall2 } from '../../footers/FooterSmall2.tsx';
import VideoHome from './video-background/videoHome.js';
import { HeaderLogoMH_ES } from '../../headers/headerLogoMH_ES';
import './home.css';
import { HeaderMenu_ES } from '../../headers/headerMenu_ES';
import { MetaTags } from 'react-meta-tags';
export const Home_ES = () => {
    return (
        <>
            <MetaTags>
                <title>Mamá Húngara</title>
                <meta description="Productora audiovisual con base en Buenos Aires que crea contenido original para todo el mundo"
                    content="Productora audiovisual con base en Buenos Aires que crea contenido original para todo el mundo"
                ></meta>
            </MetaTags>
            <HeaderMenu_ES />
            <HeaderLogoMH_ES />
            <VideoHome />
            <FooterSmall2 />
        </>
    )
}

