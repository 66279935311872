import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import Popover from "@material-ui/core/Popover";
// import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreen from "../img/500h/Asset_fullscreen_with_button.png";
import assetBackBlanco from "../img/500h/Asset_BACK_BLANCO.png";
//import FullScreen from "../img/500h/Asset_icono_fullscreen.png";
import './style.css';
import Mouse_blanco from "./Mouse_blanco";
import Mouse2_blanco from "./Mouse2_blanco";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },


  bottomIcons: {

  },


}));

const PrettoSlider = withStyles({
  root: {
    height: 2,
  },
  thumb: {
    height: 0,
    width: 0,

    marginTop: 0,
    marginLeft: 0,

  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,

  },
  rail: {
    height: 8,

  },
})(Slider);


// const EscapeControl = () => {
//   const [enteredText, setEnteredText] = useState("");

//   // onKeyDown handler function
//   const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
//     if (event.code === "Enter") {
//       alert(`You have typed "${enteredText}"`);
//     }
//   };

//   // onKeyUp handler function
//   const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
//     if (event.code === "Escape") {
//       const confirm = window.confirm(
//         "Are you sure want to clear this text feild?"
//       );

//       if (confirm) {
//         setEnteredText("");
//       }
//     }
//   };

//   // onKeyPress handler function
//   const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
//     // Do something you like with "event"
//   };

//   return (
//     <div className="container">
//       <input
//         onKeyDown={keyDownHandler}
//         onKeyUp={keyUpHandler}
//         onKeyPress={keyPressHandler}
//         type="text"
//         className="text-input"
//         value={enteredText}
//         onChange={(e) => setEnteredText(e.target.value)}
//       />
//     </div>
//   );
// };


function ValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const Controls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      volume,
      onVolumeChange,
      onBookmark,
      urlTitulo
    },
    ref
  ) => {
    const classes = useStyles();





    return (
      <div
        ref={ref}
        className={classes.controlsWrapper}
        onDoubleClick={onToggleFullScreen}
      >
        <Grid

          container
          direction="column"
          justifyContent="space-between"
          style={{ flexGrow: 1 }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ padding: 16 }}
          ></Grid>

          <div
            onClick={onPlayPause}
            className={"hola3"}

          >
            {playing ? (
              <Mouse2_blanco />
            ) : (
              <Mouse_blanco />
            )}
          </div>


          {/* bottom controls */}
          <Grid
          className="container-controls-padding"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          
          >
            <Grid item xs={12}>
              <PrettoSlider
                min={0}
                max={100}
                ValueLabelComponent={(props) => (
                  <ValueLabelComponent {...props} value={elapsedTime} />
                )}
                aria-label="custom thumb label"
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
                onDuration={onDuration}
              />
            </Grid>

            <Grid item className="lean12">
              <Grid container alignItems="center" justifyContent="space-between">
                <div className="lean">
                  <p className="title-video">
                    {/* titulo control */}
                  </p>
                  <Button
                    variant="text"
                    onClick={
                      onChangeDispayFormat
                      //     () =>
                      //   setTimeDisplayFormat(
                      //     timeDisplayFormat == "normal" ? "remaining" : "normal"
                      //   )
                    }
                  >
                    <Typography
                      fontFamily="GoodSans-Thin"
                      style={{ color: "rgb(165, 162, 162)", marginLeft: 0, fontSize: 12, letterSpacing: 1 }}
                    >
                      {elapsedTime}
                    </Typography>
                  </Button>

                  <IconButton
                    // onClick={() => setState({ ...state, muted: !state.muted })}
                    onClick={onMute}
                    className={`botones ${classes.bottomIcons} ${classes.volumeButton} `}
                  >
                    <p className="sound">SOUND</p>
                    {muted ? (
                      <p className="sound-ON-OFF">OFF</p>
                    ) : (
                      <p className="sound-ON-OFF">ON</p>
                    )}


                  </IconButton>
                  <div className="container-boton-full">
                    <IconButton
                      onClick={onToggleFullScreen}
                      className={`  ${classes.bottomIcons} ${classes.FullScreen} holalean`}
                    >
                      {/* <p className="">FULLSCREEN</p> */}
                      <img src={FullScreen} width='110px !important' height='auto !important' />
                    </IconButton>

                  </div>
                  <div className="container-boton-full2">
                    <IconButton
                      onClick={() => { window.history.back() }}
                      className="holalean"
                    >
                      <p className="boton-back-video">BACK</p> 
                      <p className="boton-back-video-400px">🡨</p> 
                      {/* <img src={assetBackBlanco} width='61px !important' height='auto !important' />  */}
                    </IconButton>

                  </div>

                </div>







              </Grid>
            </Grid>


          </Grid>
        </Grid>
      </div>
    );
  }
);

Controls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
};
export default Controls;
