import React from "react";
import ReactPlayer from "react-player";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import MetaTags from "react-meta-tags";
import "./grillas-talents.css";

export const GrillaCarmenRivoiraEn = () => {
  return (
    <>
      <HeaderTexts title="CARMEN RIVOIRA" />
      <HeaderMenu />
      <div className="container-grillas-talents">
        <MetaTags>
          <title>MH | Todo CARMEN RIVOIRA</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <div className="container-altura-grillas-talents">
          {/* 1 */}
          <div className="container-video-talents-1">
            <a className="container-href-videos0-talents" href="/en/bonafont"></a>
            <ReactPlayer
              alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
              className="video-grilla-talents"
              url="https://vimeo.com/917605551"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">BONAFONT | KILOMETROS QUE NOS MUEVEN |</p>
              <p className="text-footer-grillas-talents-2">CARMEN RIVOIRA </p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/prosegur"
            ></a>
            <ReactPlayer
              alt="PROSEGUR | NADA ES TAN PROFESIONAL | CARMEN RIVOIRA "
              className="video-grilla-talents"
              url="https://vimeo.com/918353819"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              PROSEGUR | NADA ES TAN PROFESIONAL |
              </p>
              <p className="text-footer-grillas-talents-2">CARMEN RIVOIRA </p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/maria-wolf"
            ></a>
            <ReactPlayer
              alt="MARIA WOLF | CTRL | CARMEN RIVOIRA"
              className="video-grilla-talents"
              url="https://vimeo.com/926394029"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">MARIA WOLF | CTRL |</p>
              <p className="text-footer-grillas-talents-2">CARMEN RIVOIRA</p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/chita"
            ></a>
            <ReactPlayer
              alt="CHITA | MEDIANOCHE | CARMEN RIVOIRA"
              className="video-grilla-talents"
              url="https://vimeo.com/926394080"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              CHITA | MEDIANOCHE |
              </p>
              <p className="text-footer-grillas-talents-2">CARMEN RIVOIRA</p>
            </div>
          </div>
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/isla-de-caras"
            ></a>
            <ReactPlayer
              alt="NENA LO MEJOR VA A SER QUE VUELVAS A CREER | ISLA DE CARAS | CARMEN RIVOIRA"
              className="video-grilla-talents"
              url="https://vimeo.com/926394137"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              NENA LO MEJOR VA A SER QUE VUELVAS A CREER | ISLA DE CARAS |
              </p>
              <p className="text-footer-grillas-talents-2">CARMEN RIVOIRA</p>
            </div>
          </div>
        </div>
      </div>
      <a
        onClick={() => {
          window.history.back();
        }}
      >
        <TextFooter title1="BACK" />
      </a>

      <FooterSmall2 />
    </>
  );
};
