import React from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
// import Mause from '../../../mause/Mouse.js';
// import {TextFooter} from '../../footers/textFooter.js'
import MouseApp from './mouseApp'


function VideoControls() {
  return ( <>
         <MouseApp />
        
    </>
  )
}
export default VideoControls