import React from 'react';

import { useColorModeValue, Image, } from "@chakra-ui/react";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';

import logoMHB from "../../../img/500h/Asset_logo_blanco.png"
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '../internoFilm.css';


export const Hombre_EN = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderLogoMH />
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                        Inside the plain and isolated landscape of the Argentinian Pampa, Horacio works his farm and wanders through it looking for survival.
                    </p>
                    <a href='/en/man-on-the-plain' className='title-video-mobile-film'>
                        MAN ON THE PLAIN
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/en/man-on-the-plain'>
                                MAN ON THE PLAIN
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Directed by
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                PATRICIO SUAREZ
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Genre | Documentary
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duration | 76 minutes
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Shooting Format | 2K
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Projection Format | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sound | 5.1
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Language | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Country | Argentina - España
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                World première | Visions Du Reel, Nyon -  International Competition. 2018.
                            </p>
                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo='https://vimeo.com/366336989' />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/en/films"
            />
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

