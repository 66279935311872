import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from '../../../headers/headerText';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
import './grillas-talents.css';

export const GrillaSanta = () => {
    return (<>
        <HeaderTexts
            title="SANTA"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | Todo SANTA</title>
                <meta
                    content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                    description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/chrysler-santa'></a>
                    <ReactPlayer
                        alt='Chrysler | Pacifica | SANTA'
                        className="video-grilla-talents"
                        url="https://vimeo.com/843883412/40c5239933"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        CHRYSLER | PACIFICA 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        SANTA
                        </p>
                    </div>

                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/jeep-liberty'></a>
                    <ReactPlayer
                        alt='WE LOVE TACOS | SIDRAL MUNDET | SANTA'
                        className="video-grilla-talents"
                        url="https://vimeo.com/843887356/62e458e7e8?share=copy"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        WE LOVE TACOS | SIDRAL MUNDET 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/solo-good-vibes'></a>
                    <ReactPlayer
                        alt='VIRGIN | SOLO GOOD VIBES | SANTA'
                        className="video-grilla-talents"
                        url="https://vimeo.com/843887288/23ebe6579d?share=copy"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        VIRGIN | SOLO GOOD VIBES 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/regreso-a-clase'></a>
                    <ReactPlayer
                        alt='COPPEL | REGRESO A CLASE | SANTA'
                        className="video-grilla-talents"
                        url="https://vimeo.com/843889678/bcb031bddc?share=copy"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        COPPEL | REGRESO A CLASE
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>

                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/elevate-your-life'></a>
                    <ReactPlayer
                        alt='ELEVATE YOUR LIFE'
                        className="video-grilla-talents"
                        url="https://vimeo.com/843886285/60370298d9?share=copy"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        ELEVATE YOUR LIFE | VIVO
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>
                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/no-filter'></a>
                    <ReactPlayer
                       alt='NO FILTER | INNVICTUS | SADAELS '
                        className="video-grilla-talents"
                        url="https://vimeo.com/843890660/79390aa72e?share=copy"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        NO FILTER | INNVICTUS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>
                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/open-for-more'></a>
                    <ReactPlayer
                        alt='OPEN FOR MORE | TUBORG | SANTA '
                        className="video-grilla-talents"
                        url="https://vimeo.com/843885655/fb3593f632?share=copy"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            OPEN FOR MORE | TUBORG
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>
                </div>

                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/super-bock'></a>
                    <ReactPlayer
                        alt='SUPERBOCK | SANTA '
                        className="video-grilla-talents"
                        url="https://vimeo.com/843878235/9249f4a4bc?share=copy"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        SUPERBOCK
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            SANTA
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}

