import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from '../../../headers/headerText';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
// import './grillas-talents.css';

export const GrillaDiegoFried_EN = () => {
    return (<>
        <HeaderTexts
            title="DIEGO FRIED"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>All Diego Fried</title>
                <meta
                    content="Director de cine y publicidad. Representado por Mamá Húngara."
                    description="Director de cine y publicidad. Representado por Mamá Húngara."
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>

                {/* 1 */}
                <div class="container-video-talents-1">

                    <ReactPlayer
                        alt="KELLOGG'S | POP-TARTS | DIEGO FRIED "
                        className="video-grilla-talents"
                        url="https://vimeo.com/769897032"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        KELLOGG'S | POP-TARTS
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Diego Fried
                        </p>
                    </div>
                    <a className='container-href-videos0-talents' href='/eN/kelloggs-pop-tarts'></a>
                </div>
                {/* 2 */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/purina-dog-chow'></a>
                        <ReactPlayer
                            alt='CHALLENGE I GILLETTE I DIEGO FRIED'
                            className="video-grilla-works"
                            url="https://vimeo.com/752245982"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                PURINA DOG CHOW

                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Diego Fried
                            </p>
                        </div>

                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/challenge-gillette'></a>
                        <ReactPlayer
                            alt='LOVE STORY I RIPLEY I DIEGO FRIED'
                            className="video-grilla-works"
                            url="https://vimeo.com/396178205"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                CHALLENGE | GILLETTE
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Diego Fried
                            </p>
                        </div>

                    </div>
                </div>
                {/*  */}
                <div class="container-video-talents-1">

                    <ReactPlayer
                        alt='LA FIESTA SILENCIOSA I DIEGO FRIED'
                        className="video-grilla-talents"
                        url="https://vimeo.com/396142179"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            THE SILENT PARTY
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Diego Fried
                        </p>
                    </div>
                    <a className='container-href-videos0-talents' href='/en/the-quiet-party'></a>
                </div>
                {/*  */}
                <div className='container-video-talents-2'>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/love-story-ripley'></a>
                        <ReactPlayer
                            alt='Cereal Mix | Diego Fried'
                            className="video-grilla-talents"
                            url="https://vimeo.com/396445722"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                LOVE STORY | RIPLEY

                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Diego Fried
                            </p>
                        </div>

                    </div>
                    <div className='container-video-talents-50'>
                        <a className='container-href-videos0-talents' href='/en/cereal-mix'></a>
                        <ReactPlayer
                            alt='Cereal Mix | Diego Fried'
                            className="video-grilla-talents"
                            url="https://vimeo.com/396434883"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-talents-2'>
                            <p className="text-footer-grillas-talents-1">
                                CEREAL MIX
                            </p>
                            <p className='text-footer-grillas-talents-2'>
                                Diego Fried
                            </p>
                        </div>

                    </div>
                </div>


                {/* <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/love-story-ripley'></a>
                    <ReactPlayer
                        alt='Cereal Mix | Diego Fried'
                        className="video-grilla-talents"
                        url="https://vimeo.com/396445722"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            LOVE STORY | RIPLEY
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Diego Fried
                        </p>
                    </div>

                </div>
                <div class="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/cereal-mix'></a>
                    <ReactPlayer
                        alt='Cereal Mix | Diego Fried'
                        className="video-grilla-talents"
                        url="https://vimeo.com/396434883"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                            CEREAL MIX
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Diego Fried
                        </p>
                    </div>

                </div> */}
            </div>

        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>
        <FooterSmall2 />
    </>
    )
}

