import { useState } from "react";
import { DirectorsContext } from "./directorsContext"

export const DirectorsProvider = ({children}) => {
    const [nation, setNation] = useState("");
    return (
        <DirectorsContext.Provider value={{
            nation,
            setNation,
        }}>
            {children}
        </DirectorsContext.Provider>
    )
}