import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from "swiper";
import { TextFooter } from "../../../footers/textFooter.js";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Mouse3 from "../../../mouse/Mouse3";
import MetaTags from "react-meta-tags";

//import './videoHome.css';
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css";

function VideoHome_EN() {
  const [swiper, setSwiper] = useState(null);

  return (
    <>
      <div className="container-swiper-home-page">
        <MetaTags>
          <title>Mamá Húngara</title>
          <meta content="Productora audiovisual con base en Buenos Aires que crea contenido original para todo el mundo" />
        </MetaTags>
        <Mouse3 />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="mySwiper cursor-none container-slider-photographer-still"
          slidesPerView={1}
          loopedSlides={4}
          spaceBetween={0}
          // freeMode={false}
          loop={true}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          navigation
          onSwiper={setSwiper}
        >
          <SwiperSlide
            onClick={() => window.open("/en/gatorade", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="GATORADE | MATCH SAVER | Fede García Rico"
                className="react-player-video"
                url="https://vimeo.com/940878425"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GATORADE | MATCH SAVER |"
              title2="Fede García Rico"
              href1="/en/gatorade"
              href2="/en/gatorade"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/no-one", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="NADIE | SIN BANDERA | Hernán Corera &amp; Juan Piczman"
                className="react-player-video"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NADIE | SIN BANDERA |"
              title2="Hernán Corera &amp; Juan Piczman"
              href1="/en/no-one"
              href2="/en/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              window.open("/en/personal-pay-esta-bueno-compartirlo", "_self")
            }
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/935536399"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL PAY | ESTA BUENO COMPARTIRLO |"
              title2="MARIANO NANTE"
              href1="/en/personal-pay-esta-bueno-compartirlo"
              href2="/en/personal-pay-esta-bueno-compartirlo"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/bgh-calurosamente", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINOS | SANTIAGO FRANCO"
                className="react-player-video"
                url="https://vimeo.com/891557091"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BGH | CALUROSAMENTE ARGENTINO |"
              title2="Santiago Franco"
              href1="/es/netflix-3-en-1"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/netflix-3-en-1", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="react-player-video"
                url="https://vimeo.com/862779909"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES |"
              title2="Hernan Corera & Juan Piczman"
              href1="/es/netflix-3-en-1"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/aguas-de-rio-de-janeiro", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I"
              title2="GONZO LLORENTE"
              href1="/es/aguas-de-rio-de-janeiro"
              href2="/es/gonzo-llorente"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bplay", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BPLAY | SPONSOR DEL CORAZON ARGENTO |"
              title2="Mariano Nante"
              href1="/es/bplay"
              href2="/es/marian-santi"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bonafont", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/917605551"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BONAFONT | KILOMETROS QUE NOS MUEVEN |"
              title2="Carmen Rivoira"
              href1="/es/bonafont"
              href2="/es/carmen-rivoira"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/amor-mio", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                className="react-player-video"
                url="https://vimeo.com/954460674"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA CAMPOS | AMOR MIO | "
              title2="Hernán Corera & Mateo Yaya"
              href1="/es/amor-mio"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/we-make-wow", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="HYUNDAI | KACPER LARSKI"
                className="react-player-video"
                url="https://vimeo.com/882916034"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | WE MAKE WOW"
              title2="KACPER LARSKI"
              href1="/en/we-make-wow"
              href2="/en/kacper-larski"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* MOBILE */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          {/* Video 1*/}
          <SwiperSlide
            onClick={() => window.open("/en/gatorade", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="GATORADE | MATCH SAVER | Fede García Rico"
                className="react-player-video"
                url="https://vimeo.com/940878425"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GATORADE | MATCH SAVER |"
              title2="Fede García Rico"
              href1="/en/gatorade"
              href2="/en/gatorade"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/no-one", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE "
                className="react-player-video"
                url="https://vimeo.com/935536399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NADIE | SIN BANDERA |"
              title2="Hernán Corera &amp; Juan Piczman"
              href1="/en/no-one"
              href2="/en/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() =>
              window.open("/en/personal-pay-esta-bueno-compartirlo", "_self")
            }
            className="container-video-SwiperSlide"
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE "
                className="react-player-video"
                url="https://vimeo.com/935536399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL PAY | ESTA BUENO COMPARTIRLO |"
              title2="MARIANO NANTE"
              href1="/en/personal-pay-esta-bueno-compartirlo"
              href2="/en/personal-pay-esta-bueno-compartirlo"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/bgh-calurosamente", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper" style={{ padding: 0 }}>
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINO | SANTIAGO FRANCO"
                className="react-player-video"
                url="https://vimeo.com/891557091"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BGH | CALUROSAMENTE ARGENTINO |"
              title2="Santiago Franco"
              href1="/es/netflix-3-en-1"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/netflix-3-en-1", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="react-player-video"
                url="https://vimeo.com/862779909"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTES PARA LO QUE ESTES |"
              title2="Hernan Corera & Juan Piczman"
              href1="/es/netflix-3-en-1"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/aguas-de-rio-de-janeiro", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I"
              title2="GONZO LLORENTE"
              href1="/es/aguas-de-rio-de-janeiro"
              href2="/es/gonzo-llorente"
            />
          </SwiperSlide>
          {/* Video 3*/}
          <SplideSlide onClick={() => (window.location = "/en/bplay")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BPLAY | SPONSOR DEL CORAZON ARGENTO"
              title2="Mariano Nante"
              href1="/en/bplay"
              href2="/en/marian-santi"
            />
          </SplideSlide>
          {/* Video 4*/}
          <SplideSlide onClick={() => (window.location = "/es/bonafont")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                id="2"
                alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/917605551"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BONAFONT | KILOMETROS QUE NOS MUEVEN |"
              title2="Carmen Rivoira"
              href1="/es/bonafont"
              href2="/es/carmen-rivoira"
            />
          </SplideSlide>
          {/* Video 5*/}
          <SplideSlide onClick={() => (window.location = "/es/amor-mio")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                id="1"
                alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                className="react-player-video"
                url="https://vimeo.com/954460674"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA CAMPOS | AMOR MIO | "
              title2="Hernán Corera & Mateo Yaya"
              href1="/es/amor-mio"
              href2="/es/hernan-corera"
            />
          </SplideSlide>
          {/* Video 6*/}
          <SplideSlide onClick={() => (window.location = "/en/we-make-wow")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="HYUNDAI | WE MAKE WOW | KACPER LARSKI"
                className="react-player-video"
                url="https://vimeo.com/882916034"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | WE MAKE WOW"
              title2="KACPER LARSKI"
              href1="/en/we-make-wow"
              href2="/en/kacper-larski"
            />
          </SplideSlide>
          {/*  */}
        </Splide>
      </div>
    </>
  );
}

export default VideoHome_EN;
