import React, { useState } from "react";
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from '../../../footers/textFooter.js'
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from './videos/zurich.mp4'
import "swiper/css";
import "swiper/css/bundle";
import './styleSlideimage2.css'
import {  Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import MetaTags from 'react-meta-tags';
import '@splidejs/react-splide/css';
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from 'react-keyboard-event-handler';

function Santa() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES
        title="SANTA"
      />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>SANTA</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={['left']}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className='cursor-none container-swiper-home container-slider-photographer-still'
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[ Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/es/chrysler-santa', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='Chrysler | Pacifica | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843883412/40c5239933"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHRYSLER | PACIFICA"
              title2="SANTA"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/we-love-tacos', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='WE LOVE TACOS | SIDRAL MUNDET | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843887356/62e458e7e8?share=copy"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="WE LOVE TACOS | SIDRAL MUNDET"
              title2="SANTA"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/jeep-liberty', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='Jeep | Liberty '
                className="react-player-video"
                url="https://vimeo.com/843890065/3b626da3a6?share=copy"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="JEEP | LIBERTY"
              title2="SANTA"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/solo-good-vibes', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='VIRGIN | SOLO GOOD VIBES | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843887288/23ebe6579d?share=copy"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="VIRGIN | SOLO GOOD VIBES"
              title2="SANTA"
            />

          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => (window.open('/es/regreso-a-clase', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='COPPEL | REGRESO A CLASE | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843889678/bcb031bddc?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="COPPEL | REGRESO A CLASE"
              title2="SANTA"
            />
          </SwiperSlide>
          {/* video 3*/}
          <SwiperSlide
            onClick={() => (window.open('/es/elevate-your-life', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='ELEVATE YOUR LIFE'
                className="react-player-video"
                url="https://vimeo.com/843886285/60370298d9?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ELEVATE YOUR LIFE | VIVO"
              title2="SANTA"
            />
          </SwiperSlide>
          {/* video 4 */}
          <SwiperSlide
            onClick={() => (window.open('/es/no-filter', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='NO FILTER | INNVICTUS | SADAELS '
                className="react-player-video"
                url="https://vimeo.com/843890660/79390aa72e?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NO FILTER | INNVICTUS"
              title2="SANTA"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/open-for-more', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='OPEN FOR MORE | TUBORG | SANTA '
                className="react-player-video"
                url="https://vimeo.com/843885655/fb3593f632?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="OPEN FOR MORE | TUBORG"
              title2="SANTA"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/super-bock', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='SUPERBOCK | SANTA '
                className="react-player-video"
                url="https://vimeo.com/843878235/9249f4a4bc?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="SUPERBOCK"
              title2="SANTA"
            />
            <a className="more" href='/es/todo-santa'>More</a>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide
          data-splide='{"type":"slide","perPage": 1, "drag": "free" }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='Chrysler | Pacifica | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843883412/40c5239933?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
             title1="CHRYSLER | PACIFICA"
             title2="SANTA"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='WE LOVE TACOS | SIDRAL MUNDET | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843887356/62e458e7e8?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
             title1="WE LOVE TACOS | SIDRAL MUNDET"
             title2="SANTA"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='Jeep | Liberty '
                className="react-player-video"
                url="https://vimeo.com/843890065/3b626da3a6?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="JEEP | LIBERTY"
              title2="SANTA"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='VIRGIN | SOLO GOOD VIBES | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843887288/23ebe6579d?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="VIRGIN | SOLO GOOD VIBES"
              title2="SANTA"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='COPPEL | REGRESO A CLASE | SANTA'
                className="react-player-video"
                url="https://vimeo.com/843889678/bcb031bddc?share=copy"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="COPPEL | REGRESO A CLASE"
              title2="SANTA"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='ELEVATE YOUR LIFE'
                className="react-player-video"
                url="https://vimeo.com/843886285/60370298d9?share=copy"


                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="ELEVATE YOUR LIFE | VIVO"
              title2="SANTA"
            />
            <a className="more" href='/es/todo-hernan-corera'>More</a>
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='NO FILTER | INNVICTUS | SADAELS '
                className="react-player-video"
                url="https://vimeo.com/843890660/79390aa72e?share=copy"


                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="NO FILTER | INNVICTUS"
              title2="SANTA"
            />
            <a className="more" href='/es/todo-hernan-corera'>More</a>
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='OPEN FOR MORE | TUBORG | SANTA '
                className="react-player-video"
                url="https://vimeo.com/843885655/fb3593f632?share=copy"


                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="OPEN FOR MORE | TUBORG"
              title2="SANTA"
            />
            <a className="more" href='/es/todo-hernan-corera'>More</a>
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='SUPERBOCK | SANTA '
                className="react-player-video"
                url="https://vimeo.com/843878235/9249f4a4bc?share=copy"



                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="SUPERBOCK"
              title2="SANTA"
            />
            <a className="more" href='/es/todo-hernan-corera'>More</a>
          </SplideSlide>

        </Splide>
      </div>
      <FooterLogoColors
        LogoColor={logoMHV}
      />
    </>
  )
}

export default Santa