import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from "./videos/zurich.mp4";
import "swiper/css";
import "swiper/css/bundle";
import "./styleSlideimage2.css";
import { Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import MetaTags from "react-meta-tags";
import "@splidejs/react-splide/css";
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from "react-keyboard-event-handler";

function CarmenRivoiraEn() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES title="CARMEN RIVOIRA" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>CARMEN RIVOIRA</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => window.open("/en/bonafont", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/917605551"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="BONAFONT | KILOMETROS QUE NOS MUEVEN |" title2="CARMEN RIVOIRA" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/prosegur", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PROSEGUR | NADA ES TAN PROFESIONAL | CARMEN RIVOIRA "
                className="react-player-video"
                url="https://vimeo.com/925990391"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PROSEGUR | NADA ES TAN PROFESIONAL |"
              title2="CARMEN RIVOIRA "
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/maria-wolf", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="MARIA WOLF | CTRL | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/926394029"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA WOLF | CTRL |"
              title2="CARMEN RIVOIRA"
            />
          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => window.open("/en/chita", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="CHITA | MEDIANOCHE | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/926394080"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHITA | MEDIANOCHE |"
              title2="CARMEN RIVOIRA"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/en/isla-de-caras", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="NENA LO MEJOR VA A SER QUE VUELVAS A CREER | ISLA DE CARAS | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/926394137"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NENA LO MEJOR VA A SER QUE VUELVAS A CREER | ISLA DE CARAS |"
              title2="CARMEN RIVOIRA"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"slide","perPage": 1, "drag": "free" }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/917605551"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="BONAFONT | KILOMETROS QUE NOS MUEVEN |" title2="CARMEN RIVOIRA" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
               alt="PROSEGUR | NADA ES TAN PROFESIONAL | CARMEN RIVOIRA "
                className="react-player-video"
                url="https://vimeo.com/925990391"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PROSEGUR | NADA ES TAN PROFESIONAL |"
              title2="CARMEN RIVOIRA "
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="MARIA WOLF | CTRL | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/926394029"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA WOLF | CTRL |"
              title2="CARMEN RIVOIRA"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
               alt="CHITA | MEDIANOCHE | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/926394080"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CHITA | MEDIANOCHE |"
              title2="CARMEN RIVOIRA"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NENA LO MEJOR VA A SER QUE VUELVAS A CREER | ISLA DE CARAS | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/926394137"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NENA LO MEJOR VA A SER QUE VUELVAS A CREER | ISLA DE CARAS |"
              title2="CARMEN RIVOIRA"
            />
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}

export default CarmenRivoiraEn;
