import React from 'react';

import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from '../../../footers/textFooter';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '.././grillaStill.css';

// {/* JOE EKONEN  */ }
import img1 from './CARRUSEL AMERICANINO/americanino-joe-1.jpg'
import img2 from './SADAELS PARAISO/sadaels-paraiso-1.jpg'
import img3 from './BRAHMA POMELO/brahma-pomelo-1.jpg'
import img4 from './MMIC/mmic-joe-1.jpg'
import img5 from './BETUNOIRE/betunoire-joe-1.jpg'
import img6 from './NADIE/nadie-1.jpg'
import img7 from './COLLAB/collab-1.jpg'


export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)
export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GJoe_EN = () => {
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="container-grillas-still">

                <Img50
                    src={img1}
                    href="/en/gallery-americanino"
                    title="THE REASON IS FREEDOM | AMERICANINO"
                />
                <Img50
                    src={img6}
                    href="/en/gallery-nadie"
                    title="NADIE | SIN BANDERA"
                />
                     <Img50
                    src={img4}
                    href="/en/gallery-mmic"
                    title="DOKKAEBIS | MMIC"
                />
                   <Img50
                    src={img7}
                    href="/en/gallery-collab"
                    title="WHAT IS LOVE | COLLAB"
                />
                    <Img50
                    src={img2}
                    href="/en/gallery-sadaels-paraiso"
                    title="PARAISO | SADAELS"
                />
                <Img50
                    src={img5}
                    href="/en/gallery-betunoire"
                    title="BETUNOIRE | STELLA ARTOIS"
                />
        
                <Img100
                    src={img3}
                    href="/en/gallery-brahma"
                    title="TOQUE POMELO | BRAHMA"
                />
            </div>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

