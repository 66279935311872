import React from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import { Pagination, Navigation, Mousewheel } from "swiper";
import video from "./videos/Gonzo2.mp4";
import "swiper/css";
import "swiper/css/bundle";
// import './styleSlideimage2.css'
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { HeaderMenuTalents_EN } from "../../../headers/headerTalents/headerMenuTalents_EN.js";

function Gonzo_EN() {
  return (
    <>
      <HeaderTexts title=" GONZO LLORENTE " />
      <HeaderMenuTalents_EN />
      <div className="container-swiper-talents">
        <Mouse3_drag_violet />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          loop={true}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          navigation
        >
          {/* video 1 */}
          <SwiperSlide className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt="AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU |"
                className="react-player-video"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU | "
              title2="Gonzo Llorente"
            />
          </SwiperSlide>
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => window.open("/es/big-groom", "_self")}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BIG GROOM ENERGY I MANSCAPED I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/744617420"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BIG ROOM ENERGY I MANSCAPED | "
              title2="Gonzo Llorente"
            />
          </SwiperSlide>
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => window.open("/en/personal-conexion", "_self")}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL | CONEXIÓN TOTAL | GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/890293384"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL | CONEXIÓN TOTAL"
              title2="Gonzo Llorente"
            />
          </SwiperSlide>
          <SwiperSlide className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt="UBER I BOXER I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710392513 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="UBER | BOXER | " title2="Gonzo Llorente" />
          </SwiperSlide>
          <SwiperSlide className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt="HYUNDAI"
                className="react-player-video"
                url="https://vimeo.com/710406194"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | YOUR LIFE | "
              title2="Gonzo Llorente"
            />
          </SwiperSlide>
          <SwiperSlide className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt="HBOMAX | DEL CINE A TU CASA | GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/806073285"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HBOMAX | DEL CINE A TU CASA  | "
              title2="Gonzo Llorente"
            />
          </SwiperSlide>
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => window.open("/es/jealousy", "_self")}
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="COCA COLA I JEALOUSY I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710479842"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="COCA COLA | JEALOUSY | "
              title2="Gonzo Llorente"
            />
            <a className="more" href="/en/all-gonzo-llorente">
              More
            </a>
          </SwiperSlide>
          {/* video 5 */}
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU |"
                className="react-player-video"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU  "
              title2="Gonzo Llorente"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BIG GROOM ENERGY I MANSCAPED I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/744617420"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BIG ROOM ENERGY I MANSCAPED | "
              title2="Gonzo Llorente"
            />
          </SplideSlide>
          <SwiperSlide
            className="container-video-SwiperSlide"
            onClick={() => window.open("/en/personal-conexion", "_self")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL | CONEXIÓN TOTAL | GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/890293384"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL | CONEXIÓN TOTAL"
              title2="Gonzo Llorente"
            />
          </SwiperSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="UBER I BOXER I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710392513 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="UBER | BOXER  " title2="Gonzo Llorente" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="HYUNDAI"
                className="react-player-video"
                url="https://vimeo.com/710406194"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | YOUR LIFE  "
              title2="Gonzo Llorente"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="HBOMAX | DEL CINE A TU CASA | GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/806073285"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HBOMAX | DEL CINE A TU CASA | "
              title2="Gonzo Llorente"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="COCA COLA I JEALOUSY I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710479842"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="COCA COLA | JEALOUSY | "
              title2="Gonzo Llorente"
            />
            <a className="more" href="/en/all-gonzo-llorente">
              More
            </a>
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}

export default Gonzo_EN;
