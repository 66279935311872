import React from 'react';

import { HeaderMenu } from "../../headers/headerMenu";
import { HeaderLogoMH } from "../../headers/headerLogoMH";
import { TextFooter } from '../../footers/textFooter';
import './films.css';
import { TitleFilms_EN } from './titleFilms_EN';
import { FooterSmall2 } from '../../footers/FooterSmall2.tsx';
import { FooterLogoColors } from '../../footers/footerColors/footerColors';
import logoMHV from "../../img/500h/Asset_logo_verde.png";
import { HeaderLogoMH_ES } from '../../headers/headerLogoMH_ES';


export const Film_EN = () => {
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenu />
            <TitleFilms_EN />
            <TextFooter
                title1="FILMS"
            />
            <FooterLogoColors
                LogoColor={logoMHV}
            />
        </>
    )
}

