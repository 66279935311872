import React from 'react';

import poster1 from "../../img/posters/gilda.jpg"
import poster2eng from "../../img/posters/lasparticulas_eng.jpg"
import poster3 from "../../img/posters/schwaneck.png"
import poster4 from "../../img/posters/hombre.jpg"
import poster5 from "../../img/posters/anoche.jpg"
import poster6 from "../../img/posters/figuras_eng.jpg"
import poster7 from "../../img/posters/paula.jpg"
import posterSchettinimous from "../../img/posters/schettinimous.jpg"

import './films.css';


export const TitleFilms_EN = () => {
    return (
        <div className="container-films">
            <div className="container-list-video-films">
                <a className="list-title-films" href="/en/film-gilda">GILDA</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster1} />
                </div>
                <a className="list-title-films" href='/en/film-paula'>PAULA</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster7} />
                </div>
                <a className="list-title-films" href='/en/film-figures'>FIGURES</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster6} />
                </div>
                <a className="list-title-films" href='/en/film-man-on-the-plain'>MAN ON THE PLAIN</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster4} />
                </div>
                <a className="list-title-films" href='/en/film-schwaneck'>SCHWANECK</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster3} />
                </div>
                <a className="list-title-films" href='/en/film-last-night'>LAST NIGHT</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster5} />
                </div>
                <a className="list-title-films" href='/en/film-schettinimous'>SCHETTINIMOUS</a>
                <div className='video-hover-films'>
                    <img className='img' src={posterSchettinimous} />
                </div>
            </div>
        </div>
    )
}

