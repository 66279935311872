import React from 'react';

import { Image } from '@chakra-ui/react';
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';
import poster from "../../../img/posters/schettinimous.jpg"
import { MetaTags } from 'react-meta-tags';

import '../internoFilm.css';


export const Schettinimous_EN = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderLogoMH />
            <MetaTags>
                <title>Mamá Húngara | Schettinimous</title>
                <meta
                    content="An old psycho-wizard artist is invited to a television program where he must demonstrate his predictions about the future of humanity."
                    description="An old psycho-wizard artist is invited to a television program where he must demonstrate his predictions about the future of humanity."
                />
            </MetaTags>
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                        An old psycho-wizard artist is invited to a television program where he must demonstrate his predictions about the future of humanity.
                    </p>
                    <a href='/en/schettinimous' className='title-video-mobile-film'>
                        SCHETTINIMOUS
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/en/schettinimous'>
                                SCHETTINIMOUS
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Directed by
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                TOMO TERZANO
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Genre | Hybrid
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Sub-Genre | Science Fiction - Drama - Comedy - Biography
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duration | 13 minutes 54 seconds
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Shooting Format | 4K
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Projection Format | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sound | Stereo
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Language | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Country | Argentina
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                World Premiere in the Official Argentine Competition [24] BAFICI
                                (Buenos Aires International Independent Film Festival)
                            </p>
                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo='https://vimeo.com/818975035' />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/en/films"
            />
            <FooterSmall2 />
        </>
    )
}

