import React from 'react';

import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from '../../../footers/textFooter';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '.././grillaStill.css';

import brugal_img1 from './BRUGAL/BRUGAL-1.jpg'
import fractal_img3 from './FRACTAL/fractal-4.jpg'
import mmic_img1 from './MMIC/MMIC-2.jpg'
import remix_img1 from './REMIX_B/remix-b-6.jpg'
import fila from './Fila-B/1.JPG'
import stella_img1 from './STELLA_ARTOIS/stella-artois-1.jpg'
import brahma_img1 from './TOQUE_BRAHMA/toque-brahma-4.jpg'
import volkswagen_img1 from './VOLKSWAGEN/VOLKSWAGEN-5.jpg'


export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GTatu_EN = () => {
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="container-grillas-still ">
                <Img50
                    src={mmic_img1}
                    href="/en/gallery-mmic-tatu"
                    title="DOKKAEBIS | MMIC"
                />
                <Img50
                    src={brugal_img1}
                    href="/en/gallery-brugal"
                    title="LEYENDA | BRUGAL"
                />
                <Img50
                    src={fila}
                    href="/en/gallery-fila"
                    title="FILA"
                />
                <Img50
                        src={fractal_img3}
                        href="/en/gallery-fractal"
                        title="EDITORIAL | FRACTAL"
                />
                <Img50
                    src={volkswagen_img1}
                    href="/en/gallery-volkswagen"
                    title="NIVUS | VOLKSWAGEN"
                />
                <Img50
                    src={brahma_img1}
                    href="/en/gallery-toque-brahma"
                    title="TOQUE | BRAHMA POMELO"
                />
                <Img100
                    src={stella_img1}
                    href="/en/gallery-stella-artois"
                    title="BETUNOIRE | STELLA ARTOIS"
                />
            </div>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

