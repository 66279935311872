import React from "react";
// Import Swiper React components

import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";
import { HeaderMenu } from "../../../headers/headerMenu"
import { HeaderLogoMH } from "../../../headers/headerLogoMH"
import { TextFooter } from "../../../footers/textFooter";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx"
// import ".././styleSelectWork.css";
import { Splide, SplideSlide } from '@splidejs/react-splide';

import '@splidejs/react-splide/css';


// import required modules
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper";

export default function Commercial_EN() {
  const title = ["AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU", "ZURICH | ANOTHER SPORT", "YPF | IDOLAS", "UIFRA | FEEDING DREAMS", "BPLAY | SPONSOR DEL CORAZON ARGENTO", "AMD | DREAM BIG"];
  const title2 = ["Gonzo Llorente", "Santiago Franco", "Fede García Rico", "Fede García Rico", "Mariano Nante", "Marian & Santi"];
  const href1 = ["/en/rio-de-janeiro-s-water", "/en/another-sport", "/en/idolas", "en/feeding-dreams", "/en/bplay", "/en/dream-big"]

  return (
    <>
      <HeaderLogoMH />
      <HeaderMenu />
      <div className="container-swiper-home">

        <Swiper
          className="mySwiper  container-works-sections-test "
          slidesPerView={1}
          loop={true}
          spaceBetween={0}
          centeredSlides={true}
          Autoplay={false}
          /* autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }} */
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<div class="' + className + '">' + '<p class="texto-selected-works">' + title[index] + '</p>' + '<p class="texto-selected-works">' + title2[index] + '</p>' + '<div class="btn-active">' + '</div>' + '<a class="href-works" href="' + href1[index] + '">' + '</a>' + '</div>'
              );
            }
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, Mousewheel]}
          mousewheel

        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/en/rio-de-janeiro-s-water', '_self'))}
          >
            <ReactPlayer
              alt='AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU | GONZO LLORENTE'
              className="react-player-chau"
              url="https://vimeo.com/710488571"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide>

          {/* video 2 */}
          <SwiperSlide
            onClick={() => (window.open('/en/another-sport', '_self'))}
          >
            <ReactPlayer
              alt='Another Sport | ZURICH | Marian & Santi'
              className="react-player-chau"
              url="https://vimeo.com/752541132"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 3 */}

          <SwiperSlide
            onClick={() => (window.open('/en/idolas', '_self'))}
          >
            <ReactPlayer
              alt='YPF | IDOLAS | FEDE GARCIA RICO'
              className="react-player-video"
              url="https://vimeo.com/848029570"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/feeding-dreams', '_self'))}
          >
            <ReactPlayer
              alt='FEEDING DREAMS I Fede García Rico'
              className="react-player-chau"
              url="https://vimeo.com/755986261"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 4 */}
          <SwiperSlide
            onClick={() => (window.open('/en/bplay', '_self'))}
          >
            <ReactPlayer
              alt='BPLAY | SPONSOR DEL CORAZON ARGENTO'
              className="react-player-chau"
              url="https://vimeo.com/761431757"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/dream-big', '_self'))}
          >
            <ReactPlayer
              alt='DREAM BIG | MARIAN & SANTI'
              className="react-player-chau"
              url="https://vimeo.com/515381672"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide>
        </Swiper>
      </div>

        {/*
          SplideSlide = Mobile
        */}

      <div className="container-video-works-mobile">
        <Splide
          data-splide='{"type":"loop","perPage":1 }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU | GONZO LLORENTE'
              className="react-player-chau"
              url="https://vimeo.com/710488571"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='ANOTHER SPORT | ZURICH | Marian & Santi'
              className="react-player-chau"
              url="https://vimeo.com/752541132"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='YPF | IDOLAS | Fede García Rico'
              className="react-player-chau"
              url="https://vimeo.com/848029570"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='FEEDING DREAMS I Fede García Rico'
              className="react-player-chau"
              url="https://vimeo.com/755986261"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='BPLAY'
              className="react-player-chau"
              url="https://vimeo.com/761431757"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='DREAM BIG'
              className="react-player-chau"
              url="https://vimeo.com/515381672"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>



        </Splide>
      </div>
      <div className="container-titles-works-mobile">
        <a href="/en/rio-de-janeiro-s-water">
          <p className="texto-selected-works-mobile">
            AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU
          </p>
          <p className="texto-selected-works-mobile">
            Gonzo Llorente
          </p>
        </a>
        <a href="/en/dream-big">
          <p className="texto-selected-works-mobile">
            ZURICH | ANOTHER SPORT
          </p>
          <p className="texto-selected-works-mobile">
            Marian & Santi
          </p>
        </a>
        <a href="/en/idolas">
          <p className="texto-selected-works-mobile">
            YPF | IDOLAS
          </p>
          <p className="texto-selected-works-mobile">
            Fede García Rico
          </p>
        </a>
        <a href="/en/feeding-dreams">
          <p className="texto-selected-works-mobile">
            UIFRA | ALIMENTANDO SUEÑOS
          </p>
          <p className="texto-selected-works-mobile">
            Fede García Rico
          </p>
        </a>
        <a href="/en/uber-boxer">
          <p className="texto-selected-works-mobile">
            BPLAY | SPONSOR DEL CORAZON ARGENTO
          </p>
          <p className="texto-selected-works-mobile">
            Mariano Nante
          </p>
        </a>
        <a href="/en/made-in-argentina">
          <p className="texto-selected-works-mobile">
            DREAM BIG | AMD
          </p>
          <p className="texto-selected-works-mobile">
            Marian & Santi
          </p>
        </a>
      </div>
      <TextFooter
        title1="ALL COMMERCIAL"
        href1="/en/all-commercial"
        hoverHref="hoverHref"
        centerContent
      />
      <FooterSmall2 />
    </>
  );
}
