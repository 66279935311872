import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter.js';
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
import './grillas-talents.css';

export const GrillaSalomonEn = () => {
    return (<>
        <HeaderTexts
            title="Salomon Simhon"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | All Salomon Simhon</title>
                <meta
                    content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                    description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/riot-games-lol'></a>
                    <ReactPlayer
                        alt='Riot Games LOL 10 años | SALOMON SIMHON'
                        className="video-grilla-talents"
                        url="https://vimeo.com/884517693"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        Riot Games LOL 10 años
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Salomon Simhon
                        </p>
                    </div>

                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/100-años-soledad'></a>
                    <ReactPlayer
                        alt='100 AÑOS DE SOLEDAD | SALOMON SIMHON'
                        className="video-grilla-talents"
                        url="https://vimeo.com/884519081"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        100 AÑOS DE SOLEDAD
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Salomon Simhon
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/señorita'></a>
                    <ReactPlayer
                        alt='SEÑORITA 89 | SALOMON SIMHON'
                        className="video-grilla-talents"
                        url="https://vimeo.com/884813399"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        SEÑORITA 89
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Salomon Simhon
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/en/colombina-abuela'></a>
                    <ReactPlayer
                        alt='COLOMBINA ABUELA | SALOMON SIMHON'
                        className="video-grilla-talents"
                        url="https://vimeo.com/884813780"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        COLOMBINA ABUELA
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Salomon Simhon
                        </p>
                    </div>

                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/en/nike-imagine'></a>
                    <ReactPlayer
                        alt='NIKE | IF YOU CAN IMAGINE YOU CAN PLAY IT | Salomon Simhon'
                        className="video-grilla-talents"
                        url="https://vimeo.com/883263269"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        NIKE | IF YOU CAN IMAGINE YOU CAN PLAY IT
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Salomon Simhon
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}