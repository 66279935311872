import React from 'react';
import { Image, } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from "swiper/react";
import {  Pagination, Navigation, Mousewheel} from "swiper";
import { HeaderMenu } from "../../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../../headers/headerLogoMH";
import { TextFooter } from '../../../../footers/textFooter';
import { FooterLogoColors } from '../../../../footers/footerColors/footerColors';
import logoMHW from "../../../../img/500h/Asset_logo_blanco.png";
import '../.././sliderStill.css';
import Mouse3_drag_blanco from '../../../../mouse/Mouse3_drag_blanco';

import img1 from './Free-ioni-1.jpg'
import img2 from './Free-ioni-2.jpg'
import img3 from './Free-ioni-3.jpg'
import img4 from './Free-ioni-4.jpg'
import img5 from './Free-ioni-5.jpg'

export const S_Proy_Free_EN = () => {
    return (
        <>
            <HeaderLogoMH />
            <HeaderMenu />
            <Mouse3_drag_blanco />
            <Swiper
                className='cursor-none container-swiper-still'
                slidesPerView={"auto"}
                loopedSlides={5}
                spaceBetween={0}
                freeMode={false}
                /*  pagination={{
                     clickable: true
                 }} */
                 modules={[ Pagination, Navigation, Mousewheel]}
mousewheel
                 navigation
            >
                <SwiperSlide>
                    <div className="container-slider-photographer-still  cursor-none">
                        <Image className='img-slider-still' src={img1} alt='Ioni Epelbaum' />
                        <div className="contain-02">
                            <p className="text-top-slider-still">2021</p>
                            <div className='container-text-slider-still'>
                                <p className="title-img-slider-stil-1"></p>
                                <p className="title-img-slider-stil-2">FREE</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={img2} alt='Ioni Epelbaum' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={img3} alt='Ioni Epelbaum' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={img4} alt='Ioni Epelbaum' />
                </SwiperSlide>
                <SwiperSlide>
                    <Image className='img-slider-still' src={img5} alt='Ioni Epelbaum' />
                </SwiperSlide>
            </Swiper>
            <a onClick={() => { window.history.back() }}>
                <TextFooter
                    title1="BACK"
                />
            </a>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>

    )
}

