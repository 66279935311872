import React from "react"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from '../../../footers/textFooter.js'
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { Pagination, Navigation, Mousewheel } from "swiper";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from './videos/zurich.mp4'
import "swiper/css";
import "swiper/css/bundle";
// import './styleSlideimage2.css'
import MetaTags from 'react-meta-tags';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

function MartinaGalliEn() {
  return (
    <>
      <HeaderTexts
        title="MARTINA GALLI AGULLA"
      />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>MARTINA GALLI AGULLA</title>
          <meta
            content="Dupla de directoras publicitarias representadas por Mamá Húngara"
            description="Dupla de directoras publicitarias representadas por Mamá Húngara"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <Swiper
          className='cursor-none container-swiper-home container-slider-photographer-still'
          slidesPerView={1}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          loop={true}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/en/diarco', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='DIARCO | Martina Galli Agulla'
                className="react-player-video"
                url="https://vimeo.com/886854534"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="DIARCO |"
              title2="Martina Galli Agulla"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/lo-que-nos-hace-bien', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='CLIGHT | LO QUE NOS HACE BIEN | Martina Galli Agulla'
                className="react-player-video"
                url="https://vimeo.com/803081038"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="CLIGHT | LO QUE NOS HACE BIEN | "
              title2="Martina Galli Agulla"
            />
          </SwiperSlide>

          <SwiperSlide
            onClick={() => (window.open('/en/sprite-love-you-hater', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='SPRITE | LOVE YOU HATER'
                className="react-player-video"
                url="https://vimeo.com/703862295 "

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="SPRITE | LOVE YOU HATER | "
              title2="Martina Galli Agulla"
            />

          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => (window.open('/en/becks-do-it-different', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='BECKS HACELO DIFERENTES'
                className="react-player-video"
                url="https://vimeo.com/703861246  "

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BECKS | DO IT DIFFERENT | "
              title2="Martina Galli Agulla"
            />
            <a className="more" href='/en/all-martina-galli-agulla'>More</a>
          </SwiperSlide>
          {/* video 3*/}
          {/* <SwiperSlide
            onClick={() => (window.open('/en/movistar-more-gig', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='MOVISTAR / MAS GIGAS'
                className="react-player-video"
                url="https://vimeo.com/703862101"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="MOVISTAR | MORE GIG | "
              title2="Martina Galli Agulla"
            />
            
          </SwiperSlide> */}
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide
          data-splide='{"type":"loop","perPage":1 }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='CLIGHT | LO QUE NOS HACE BIEN '
                className="react-player-video"
                url="https://vimeo.com/803081038"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="CLIGHT | LO QUE NOS HACE BIEN"
              title2="Martina Galli Agulla"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='SPRITE | LOVE YOU HATER'
                className="react-player-video"
                url="https://vimeo.com/703862295 "

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="SPRITE | LOVE YOU HATER  "
              title2="Martina Galli Agulla"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='SPRITE | LOVE YOU HATER'
                className="react-player-video"
                url="https://vimeo.com/703862295 "

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
              title1="SPRITE | LOVE YOU HATER  "
              title2="Martina Galli Agulla"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='BECKS HACELO DIFERENTES'
                className="react-player-video"
                url="https://vimeo.com/703861246  "

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BECKS | HACELO DIFERENTE  "
              title2="Martina Galli Agulla"
            />
            <a className="more" href='/en/all-martina-galli-agulla'>More</a>
          </SplideSlide>
          {/* <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='MOVISTAR / MAS GIGAS'
                className="react-player-video"
                url="https://vimeo.com/703862101"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>

            <TextFooter
              title1="MOVISTAR | MAS GIGAS  "
              title2="Martina Galli Agulla"
            />
            
          </SplideSlide> */}


        </Splide>
      </div>
      <FooterLogoColors
        LogoColor={logoMHV}
      />
    </>
  )
}

export default MartinaGalliEn