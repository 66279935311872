import React from 'react';

import poster1 from "../../img/posters/gilda.jpg"
import poster2eng from "../../img/posters/lasparticulas_eng.jpg"
import poster3 from "../../img/posters/schwaneck.png"
import poster4 from "../../img/posters/hombre.jpg"
import poster5 from "../../img/posters/anoche.jpg"
import poster6esp from "../../img/posters/figuras_esp.jpg"
import poster7 from "../../img/posters/paula.jpg"
import { MetaTags } from 'react-meta-tags';
import posterSchettinimous from "../../img/posters/schettinimous.jpg"

import './films.css';


export const TitleFilms = () => {
    return (
        <div className="container-films">
            <MetaTags>
                <title>Mamá Húngara | Films</title>
                <meta
                    content="Talentos Mamá Húngara"
                    description="Talentos Mamá Húngara"
                />
            </MetaTags>
            <div className="container-list-video-films">
                <a className="list-title-films" href="/es/film-gilda">GILDA</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster1} />
                </div>
                <a className="list-title-films" href='/es/film-paula'>PAULA</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster7} />
                </div>
                <a className="list-title-films" href='/es/film-figuras'>FIGURAS</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster6esp} />
                </div>
                <a className="list-title-films" href='/es/film-hombre-en-la-llanura'>HOMBRE EN LA LLANURA</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster4} />
                </div>
                <a className="list-title-films" href='/es/film-schwaneck'>SCHWANECK</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster3} />
                </div>
                <a className="list-title-films" href='/es/film-anoche'>ANOCHE</a>
                <div className='video-hover-films'>
                    <img className='img' src={poster5} />
                </div>
                <a className="list-title-films" href='/es/film-schettinimous'>SCHETTINIMOUS</a>
                <div className='video-hover-films'>
                    <img className='img' src={posterSchettinimous} />
                </div>
            </div>
        </div>
    )
}

