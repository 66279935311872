import { useColorModeValue, Image,} from "@chakra-ui/react";
import { props } from "react-bootstrap/esm/Image";

// import url2 from '../../../images/cropped-Asset-6-1-32x32.png';
import logoMHV from "../../img/500h/Asset_logo_violet.png";
import logoMHW from "../../img/500h/Asset_logo_blanco.png";
import logoMHG from "../../img/500h/Asset_logo_verde.png";


import './footerColors.css'
 

export const FooterLogoColors = (props) => {
  const bgColor = useColorModeValue("blackAlpha.700", "gray.800");

  return (
    <div className="container-footer-v">
        <a     onClick={() => {window.history.back()}}>
             <Image
             className="width-img-logo-footer-v"
          alt="logoMH-V"
          src={props.LogoColor}
        />
        </a>
     
    </div>

  );
};
