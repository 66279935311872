import React from 'react';

import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../headers/headerLogoMH";
import { TextFooter } from '../../../footers/textFooter';
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '.././grillaStill.css';

import img1 from '../../5_still/martin-sigal/1_Adversiting/5-Flow-Persiana-martin-sigal.jpg'
import img2 from '../../5_still/martin-sigal/4_Landscape/landscape-martin-sigal-Ruca-Malen-1.jpg'

import img3 from '../../5_still/martin-sigal/6_The_returned/the-returned-edit.jpg'
import img4 from '../../5_still/martin-sigal/2_Sports/Nike-martin-sigal-merge-1.jpg'
import img5 from '../../5_still/martin-sigal/2_Sports/Sports-World-hombre-salto-martin-sigal-2.jpg'
import img6 from '../../5_still/martin-sigal/1_Adversiting/1-blue-propel-martin-sigal.jpg'
import img7 from '../../5_still/martin-sigal/6_The_returned/returned-martin-sigal.jpg'
import img8 from '../../5_still/martin-sigal/1_Adversiting/4-Aguila-americanino-martin-sigal.jpg'
import img9 from '../../5_still/martin-sigal/2_Sports/Nike-edite.jpg'
import img10 from '../../5_still/martin-sigal/1_Adversiting/8-rexona-martin-sigal-1.jpg'
import img11 from './8_El_Esteco/2.jpg'
import img12 from './8_El_Esteco/9.jpg'

import { MetaTags } from 'react-meta-tags';

export const Img50 = ({ href, title, src }) => (
    <div className="container-img-grillas-still">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const Img100 = ({ href, title, src }) => (
    <div className="container-img-grillas-still-100">
        <img className='img-grillas-still' src={src} />
        <div className='container-a-hover'>
            <a className='a-grillas-still' href={href}>
                {title}
            </a>
        </div>
    </div>
)

export const GSigalEN = () => {
    return (
        <>
        <MetaTags>
        <title>Mamá Húngara | Martín Sigal</title>
        </MetaTags>
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="container-grillas-still ">
                <Img50
                    src={img1}
                    href="/en/gallery-advertising"
                    title="ADVERTISING"
                />
                <Img50
                    src={img2}
                    href="/en/gallery-landscape"
                    title="LANDSCAPE"
                />
                <Img50
                    src={img3}
                    href="/en/gallery-the-returned"
                    title="THE RETURNED"
                />
                <Img50
                    src={img4}
                    href="/en/gallery-sports"
                    title="SPORTS"
                />
                <Img50
                    src={img5}
                    href="/en/gallery-sports"
                    title="SPORTS"
                />
                <Img50
                    src={img6}
                    href="/en/gallery-advertising"
                    title="ADVERTISING"
                />
                <Img50
                    src={img7}
                    href="/en/gallery-the-returned"
                    title="THE RETURNED"
                />
                <Img50
                    src={img8}
                    href="/en/gallery-advertising"
                    title="ADVERTISING"
                />
                <Img50
                    src={img9}
                    href="/en/gallery-sports"
                    title="SPORTS"
                />
                <Img50
                    src={img10}
                    href="/en/gallery-advertising"
                    title="ADVERTISING"
                />
                <Img50
                    src={img11}
                    href="/en/gallery-el-esteco"
                    title="EL ESTECO"
                />
                <Img50
                    src={img12}
                    href="/en/gallery-el-esteco"
                    title="EL ESTECO"
                />
            </div>
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

