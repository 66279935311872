import React from "react";
import ReactPlayer from "react-player";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenu } from "../../../headers/headerMenu.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import MetaTags from "react-meta-tags";
import "./grillas-talents.css";

export const GrillaFernandoCattori = () => {
  return (
    <>
      <HeaderTexts title="FernandoCattori" />
      <HeaderMenu />
      <div className="container-grillas-talents">
        <MetaTags>
          <title>MH | Todo Fernando Cattori</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <div className="container-altura-grillas-talents">
          {/* 1 */}
          <div className="container-video-talents-1">
            <a className="container-href-videos0-talents" href="/es/llorar"></a>
            <ReactPlayer
              alt="LLORAR | RUBIO | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920503144"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">LLORAR | RUBIO |</p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/elsa-y-elmar"
            ></a>
            <ReactPlayer
              alt="ELSA Y ELMAR | VUELVE | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920506198"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              ELSA Y ELMAR | VUELVE
              </p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/palacio-de-hierro"
            ></a>
            <ReactPlayer
              alt="PALACIO DE HIERRO | SATÉLITE | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920503636"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">PALACIO DE HIERRO | SATÉLITE |</p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
          {/* 1 */}
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/ultra-hard-seltzer"
            ></a>
            <ReactPlayer
              alt="MICHELOB ULTRA HARD SELTZER | SUMMER LOVE | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920505558"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              MICHELOB ULTRA HARD SELTZER | SUMMER LOVE |
              </p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/runners-ayudando"
            ></a>
            <ReactPlayer
              alt="NIKE | RUNNERS AYUDANDO RUNNERS | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920505170"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              NIKE | RUNNERS AYUDANDO RUNNERS |
              </p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/cartier"
            ></a>
            <ReactPlayer
              alt="GQ CARTIER | AHMED | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920502387"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              GQ CARTIER | AHMED |
              </p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
          <div className="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/es/cartier"
            ></a>
            <ReactPlayer
              alt="MICHELOB ULTRA HARD SELTZER | LANZAMIENTO | FERNANDO CATTORI"
              className="video-grilla-talents"
              url="https://vimeo.com/920504432"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
              MICHELOB ULTRA HARD SELTZER | LANZAMIENTO |
              </p>
              <p className="text-footer-grillas-talents-2">FERNANDO CATTORI</p>
            </div>
          </div>
        </div>
      </div>
      <a
        onClick={() => {
          window.history.back();
        }}
      >
        <TextFooter title1="BACK" />
      </a>

      <FooterSmall2 />
    </>
  );
};
