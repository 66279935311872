import React, { useContext, useState } from "react";
import ReactPlayer from "react-player";
import reel_diego_fried from "../../videos/talents/talents2/diegofried2.mp4";
import reel_gonzo_llorente from "../../videos/talents/talents2/Gonzo2.mp4";
import reel_fede_garcia from "../../videos/talents/talents2/FedeGarcia2.mp4";
import reel_la_dulce_liberal from "../../videos/talents/talents2/LaDulceLiberal2.mp4";
import reel_marian_santi from "../../videos/talents/talents2/marianysanti2.mp4";
import reel_hernan_corera from "../../videos/talents/talents2/Corera2.mp4";

import "./talent.css";
import { DirectorsContext } from "../../context/directorsContext";

export const TitleVideo = () => {
  const { nation, setNation } = useContext(DirectorsContext);

  // const handdleMouse = (zona) => {
  //   if (zona === 'International') {
  //     setNation('International');
  //   } else if (zona === 'Arg/Latam') {
  //     setNation('Arg/Latam');
  //   }
  // };

  // const handdleMouseLeave = () => {
  //   setNation('');
  //   console.log(nation);
  // };

  console.log(nation);

  return (
    <>
      <nav className="container-talents">
        <div className="container-list-video-talents">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p a className="titles">
              ARG/LATAM
            </p>

            {/* video 1 */}
            <a
              className="list-title-talents-mobile"
              href="/es/todo-hernan-corera"
            >
              HERNAN CORERA
            </a>

            <a
              className="list-title-talents"
              href="/es/hernan-corera"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              HERNAN CORERA
            </a>
            <ReactPlayer
              alt="UN AMOR VERDADERO | SADAELS"
              className="video-hover-talents"
              url={reel_hernan_corera}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />

            <a
              className="list-title-talents-mobile"
              href="/es/todo-santiago-franco"
            >
              SANTIAGO FRANCO
            </a>

            <a
              className="list-title-talents"
              href="/es/santiago-franco"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              SANTIAGO FRANCO
            </a>
            <ReactPlayer
              alt="Romance Eterno | Fifa 21"
              className="video-hover-talents"
              url={reel_marian_santi}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <a
              className="list-title-talents-mobile"
              href="/es/todo-mariano-nante"
            >
              MARIANO NANTE
            </a>

            <a
              className="list-title-talents"
              href="/es/mariano-nante"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              MARIANO NANTE
            </a>
            <ReactPlayer
              alt="Romance Eterno | Fifa 21"
              className="video-hover-talents"
              url={reel_marian_santi}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <a
              className="list-title-talents-mobile"
              href="/es/todo-gonzo-llorente"
            >
              GONZO LLORENTE
            </a>

            <a
              className="list-title-talents"
              href="/es/gonzo-llorente"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              GONZO LLORENTE
            </a>
            <ReactPlayer
              alt="NOWNESS I CONGOS &amp; EL DIABLO I  GONZO LLORENTE"
              className="video-hover-talents"
              url={reel_gonzo_llorente}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <a
              className="list-title-talents-mobile"
              href="/es/todo-fede-garcia-rico"
            >
              FEDE GARCIA RICO
            </a>

            <a
              className="list-title-talents"
              href="/es/fede-garcia-rico"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              FEDE GARCIA RICO
            </a>
            <ReactPlayer
              alt="EL AMOR NOS SALVA I ALMA MORA I Fede Garcia Rico"
              className="video-hover-talents"
              url={reel_fede_garcia}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            {/* <a
              className="list-title-talents-mobile"
              href="/es/todo-martina-galli-agulla"
            >
              MARTINA GALLI AGULLA
            </a>

            <a
              className="list-title-talents"
              href="/es/martina-galli-agulla"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              MARTINA GALLI AGULLA
            </a>
            <ReactPlayer
              alt=" SPRITE I LOVE YOU HATER"
              className="video-hover-talents"
              url={reel_la_dulce_liberal}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            /> */}
            <a
              className="list-title-talents-mobile"
              href="/es/todo-olivia-sanchez-ruival"
            >
              OLIVIA SANCHEZ RUIVAL
            </a>

            <a
              className="list-title-talents"
              href="/es/olivia-sanchez-ruival"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              OLIVIA SANCHEZ RUIVAL
            </a>
            <ReactPlayer
              alt=" SPRITE I LOVE YOU HATER"
              className="video-hover-talents"
              url={reel_la_dulce_liberal}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            {/* <a
              className="list-title-talents-mobile"
              href="/es/todo-diego-fried"
            >
              DIEGO FRIED
            </a>

            <a
              className="list-title-talents"
              href="/es/diego-fried"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              DIEGO FRIED
            </a>
            <ReactPlayer
              alt="LA FIESTA SILENCIOSA I DIEGO FRIED"
              className="video-hover-talents"
              url={reel_diego_fried}
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            /> */}
            <a
              className="list-title-talents-mobile"
              href="/es/todo-carmen-rivoira"
            >
              CARMEN RIVOIRA
            </a>

            <a
              className="list-title-talents"
              href="/es/carmen-rivoira"
              onMouseEnter={() => setNation("ARG/LATAM")}
              onMouseLeave={() => setNation("")}
            >
              CARMEN RIVOIRA
            </a>
            <ReactPlayer
              alt="PROSEGUR | NADA ES TAN PROFESIONAL | CARMEN RIVOIRA"
              className="video-hover-talents"
              url="https://vimeo.com/925990391"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="international">
              <p a className="titles divider">
                INTERNATIONAL
              </p>
              <a
                className="list-title-talents-mobile setCountry-mobile "
                href="/es/todo-kacper-larski"
              >
                KACPER LARSKI <p className="country">(CAN)</p>
              </a>
              <a
                className="list-title-talents setCountry"
                href="/es/kacper-larski"
                onMouseEnter={() => setNation("INTERNATIONAL")}
                onMouseLeave={() => setNation("")}
              >
                KACPER LARSKI <p className="country">(CAN)</p>
              </a>
              <ReactPlayer
                alt="GARMIN | KACPER LARKSI"
                className="video-hover-talents"
                url={"https://vimeo.com/882908413?share=copy"}
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

              <a
                className="list-title-talents-mobile setCountry-mobile "
                href="/es/todo-fernando-cattori"
              >
                FERNANDO CATTORI <p className="country">(MEX)</p>
              </a>
              <a
                className="list-title-talents setCountry"
                href="/es/fernando-cattori"
                onMouseEnter={() => setNation("INTERNATIONAL")}
                onMouseLeave={() => setNation("")}
              >
                FERNANDO CATTORI <p className="country">(MEX)</p>
              </a>
              <ReactPlayer
                alt="GARMIN | KACPER LARKSI"
                className="video-hover-talents"
                url={"https://vimeo.com/920503144"}
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

              <a
                className="list-title-talents-mobile setCountry-mobile "
                href="/es/todo-keane-shaw"
              >
                KEANE SHAW <p className="country">(UK)</p>
              </a>
              <a
                className="list-title-talents setCountry"
                href="/es/keane-shaw"
                onMouseEnter={() => setNation("INTERNATIONAL")}
                onMouseLeave={() => setNation("")}
              >
                KEANE SHAW <p className="country">(UK)</p>
              </a>

              {/* <a className="list-title-talents-mobile" href="/es/todo-santa">
          SANTA <p className='country'>(MX + UY)</p>
          </a>
          <a className="list-title-talents setCountry" href="/es/santa"
          onMouseEnter={() => setNation('INTERNATIONAL')}
          onMouseLeave={() => setNation("")}>
          SANTA <p className='country'>(MX + UY)</p>
          </a> */}
              <ReactPlayer
                alt="ADIDAS | PARLEY & REAL MADRID | KEANE SHAW"
                className="video-hover-talents"
                url={"https://vimeo.com/886896115"}
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

              <a
                className="list-title-talents-mobile setCountry-mobile "
                href="/es/todo-steve-bruno"
              >
                STEVE BRUNO <p className="country">(BRA)</p>
              </a>
              <a
                className="list-title-talents setCountry"
                href="/es/steve-bruno"
                onMouseEnter={() => setNation("INTERNATIONAL")}
                onMouseLeave={() => setNation("")}
              >
                STEVE BRUNO <p className="country">(BRA)</p>
              </a>
              <ReactPlayer
                alt="SPOTIFY | PLAYLIST | STEVE BRUNO"
                className="video-hover-talents"
                url={"https://vimeo.com/848730249/d9016bcbda?share=copy"}
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <a
                className="list-title-talents-mobile setCountry-mobile "
                href="/es/todo-salomon"
              >
                SALOMON SIMHON <p className="country">(MEX/COL)</p>
              </a>

              <a
                className="list-title-talents setCountry "
                href="/es/salomon"
                onMouseEnter={() => setNation("INTERNATIONAL")}
                onMouseLeave={() => setNation("")}
              >
                SALOMON SIMHON <p className="country">(MEX/COL)</p>
              </a>
              <ReactPlayer
                alt="Riot Games LOL 10 años | SALOMON SIMHON"
                className="video-hover-talents"
                url={"https://vimeo.com/884517693"}
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
