import React from 'react';
import ReactPlayer from "react-player";
import { HeaderMenu } from "../../../../headers/headerMenu"
import { HeaderLogoMH } from "../../../../headers/headerLogoMH"
import { TextFooter } from '../../../../footers/textFooter';
import { FooterSmall2 } from '../../../../footers/FooterSmall2.tsx';
import { MetaTags } from 'react-meta-tags';
import '../../grillas-works.css';
import { HeaderMenu_ES } from '../../../../headers/headerMenu_ES';
import { HeaderLogoMH_ES } from '../../../../headers/headerLogoMH_ES';

export const GrillasContent = () => {
    return (<>
    <MetaTags>
      <title>Mamá Húngara | All Content</title>
    </MetaTags>
        <HeaderMenu_ES />
        <HeaderLogoMH_ES />
        <div className='container-grillas-works'>
            <div className='container-altura-works'>


            <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='L.E.S. | Sahara'
                            className="video-grilla-works"
                            url="https://vimeo.com/861750876/e33c9ee6a7"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                            L.E.S.
                            </p>
                            <p className='text-footer-grillas-works-2'>
                            Sahara
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/sahara'></a>
                    </div>
                   
                </div>


                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='AMERICANINO | THE REASON IS FREEDOM | MC CALLE'
                            className="video-grilla-works"
                            url="https://vimeo.com/766590399"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                AMERICANINO | THE REASON IS FREEDOM
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Mc Calle
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/americanino'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt="HERA HYESANG PARK | WHILE YOU LIVE | MARIANO NANTE"
                            className="video-grilla-works"
                            url="https://vimeo.com/935937413"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                            HERA HYESANG PARK | WHILE YOU LIVE
                            </p>
                            <p className='text-footer-grillas-works-2'>
                            Mariano Nante
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/while-you-live'></a>
                    </div>
                </div>
                <div className='container-2-videos-works'>

                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='DANTE SPINETTA | EL LADO OSCURO DEL CORAZON | HERNAN CORERA & JUAN PICZMAN'
                            className="video-grilla-works"
                            url="https://vimeo.com/759890900"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                DANTE SPINETTA | EL LADO OSCURO DEL CORAZON
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera & Juan Piczman
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/el-lado-oscuro-del-corazon'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='PARAISO I SADAELS '
                            className="video-grilla-works"
                            url="https://vimeo.com/520227700"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                PARAISO | SADAELS
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/paraiso'></a>
                    </div>
                </div>
                <div className='container-2-videos-works'>

                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='DOKKAEBIS I MMIC I Alcibiade Cohen'
                            className="video-grilla-works"
                            url="https://vimeo.com/606825402"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                DOKKAEBIS | MMIC
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Alcibiade Cohen
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/dokkaebis'></a>
                    </div>

                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='YOU´RE NOT ALONE | AGORIA'
                            className="video-grilla-works"
                            url="https://vimeo.com/310419904"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                YOU'RE NOT ALONE | AGORIA
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/you-are-not-alone'></a>
                    </div>
                </div>

                {/* 2 */}
                <div className='container-2-videos-works'>



                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='UN AMOR VERDADERO I SADAELS'
                            className="video-grilla-works"
                            url="https://vimeo.com/471045785"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                UN AMOR VERDADERO | SADAELS
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/un-amor-verdadero'></a>
                    </div>


                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='EL IDOLO I BANDALOS CHINOS | Tomás Terzano '
                            className="video-grilla-works"
                            url="https://vimeo.com/459370646 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                EL IDOLO | BANDALOS CHINOS
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Tomás Terzano
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/el-idolo'></a>
                    </div>
                </div>
                {/*3*/}
                <div className='container-2-videos-works'>

                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='EVERYTIME | FORMA | FRANCISCO MAZZIOTTI'
                            className="video-grilla-works"
                            url="https://vimeo.com/575492418"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                EVERYTIME | FORMA
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Francisco Mazziotti
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/everytime'></a>
                    </div>

                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='Mi vida | Dante Spinetta'
                            className="video-grilla-works"
                            url="https://vimeo.com/241159334 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                MI VIDA | DANTE SPINETTA
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/mi-vida'></a>
                    </div>
                </div>
                {/*4*/}

                {/*5*/}
                <div className='container-2-videos-works'>

                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='SIN SEÑAL | TOMO TERZANO'
                            className="react-player-chau"
                            url="https://vimeo.com/441068545"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}

                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                SIN SEÑAL
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Tomo Terzano
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/sin-senial'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='NOWNESS | CONGOS & EL DIABLO | GONZO LLORENTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/710392065"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                NOWNESS | CONGOS &amp; EL DIABLO
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Gonzo Llorente
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/nowness'></a>
                    </div>

                </div>
            </div>
        </div>
        <TextFooter
            title1="CONTENT"
            href1="/es/content"
        />
        <FooterSmall2 />
    </>
    )
}

