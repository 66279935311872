import React from "react";
import { useDisclosure, Image } from "@chakra-ui/react";

import { Drawer_Negro } from "../drawer/DrawerNegro";

import './header.css'

export const HeaderMenuNegro = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>
  <div className="container-header-1">
    <Drawer_Negro className="color" />
  </div>
  </>
  );
};
