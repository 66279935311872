import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import Mouse3 from "../../../mouse/Mouse3.js";
import { Pagination, Navigation, Mousewheel } from "swiper";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from "./videos/marianysanti2.mp4";
import "swiper/css";
import "swiper/css/bundle";
import "./styleSlideimage2.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import MetaTags from "react-meta-tags";
import KeyboardEventHandler from "react-keyboard-event-handler";

function SantiagoFranco() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts title="SANTIAGO FRANCO" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>SANTIAGO FRANCO</title>
          <meta
            content="Dupla de directores de cine y publicidad representados por Mamá Húngara"
            description="Dupla de directores de cine y publicidad representados por Mamá Húngara"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => window.open("/es/bgh-calurosamente", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINO | SANTIAGO FRANCO"
                className="react-player-video"
                url="https://vimeo.com/891549390/09919b0f75"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BGH | CALUROSAMENTE ARGENTINO |"
              title2="Santiago Franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bplay", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" BPLAY | SPONSOR DEL CORAZON ARGENTO"
              title2=" Santiago Franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/suenia-potente", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUEÑA POTENTE | AMD "
                className="react-player-video"
                url="https://vimeo.com/515381672 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SUEÑA POTENTE | AMD |"
              title2="Marian & Santi"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/betwarrior", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BET WARRIOR | NO ESTÁ NADA | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/953268080"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BET WARRIOR | NO ESTÁ NADA |"
              title2="Santiago Franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/conmebol", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="CONMEBOL | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/878049177"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="CONMEBOL |" title2="Santiago Franco" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/otro-deporte", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="ZURICH | OTRO DEPORTE "
                className="react-player-video"
                url="https://vimeo.com/752541132 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ZURICH | OTRO DEPORTE |"
              title2="Santiago Franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/rugby-world-cup", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="RUGBY WORLD CUP | ZURICH | Marian & Santi"
                className="react-player-video"
                url="https://vimeo.com/361111813 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="RUGBY WORLD CUP | ZURICH | "
              title2="Marian & Santi"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/romance-eterno", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="ROMANCE ETERNO | FIFA 21 | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/497254514"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ROMANCE ETERNO | FIFA 21 |"
              title2="Santiago Franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/brahma-diminutivos", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="BRAHMA | DIMINUTIVOS | Santiago Franco "
                className="react-player-video"
                url="https://vimeo.com/344817236"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BRAHMA | DIMINUTIVOS |"
              title2="Santiago Franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/galicia-app", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="GALICIA APP | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/767430979"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="GALICIA APP |" title2="Santiago Franco" />
          </SwiperSlide>
          {/* video 2 */}

          {/* video 3 */}
          {/* video 4*/}

          {/* video 5 */}

          <SwiperSlide
            onClick={() => window.open("/es/citibanamex", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="CITIBANAMEX | MARIANO & SANTIAGO"
                className="react-player-video"
                url="https://vimeo.com/894116437"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="CITIBANAMEX |" title2="Mariano & Santiago" />
            <a className="more" href="/en/all-santiago-franco">
              More
            </a>
          </SwiperSlide>
          {/* video 6 */}
        </Swiper>
      </div>

      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINO | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/891549390/09919b0f75"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BGH | CALUROSAMENTE ARGENTINO "
              title2="Santiago Franco"
            />
            <a className="more" href="/es/todo-marian-santi">
              More
            </a>
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" BPLAY | SPONSOR DEL CORAZON ARGENTO"
              title2=" Santiago Franco"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUEÑA POTENTE | AMD"
                className="react-player-video"
                url="https://vimeo.com/515381672"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="SUEÑA POTENTE | AMD" title2=" Marian & Santi" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BET WARRIOR | NO ESTÁ NADA | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/953268080"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BET WARRIOR | NO ESTÁ NADA |"
              title2="Santiago Franco"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="CONMEBOL | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/878049177"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="CONMEBOL |" title2="Santiago Franco" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="ZURICH | OTRO DEPORTE "
                className="react-player-video"
                url="https://vimeo.com/752541132 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ZURICH | OTRO DEPORTE |"
              title2="Santiago Franco"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="RUGBY WORLD CUP | ZURICH | Marian & Santi"
                className="react-player-video"
                url="https://vimeo.com/361111813 "
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="RUGBY WORLD CUP | ZURICH | "
              title2="Marian & Santi"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="ROMANCE ETERNO I FIFA 21 I Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/497254514"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ROMANCE ETERNO | FIFA 21"
              title2="Santiago Franco"
            />
            <a className="more" href="/es/todo-marian-santi">
              More
            </a>
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BRAHMA | DIMINUTIVOS | Santiago Franco "
                className="react-player-video"
                url="https://vimeo.com/344817236"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BRAHMA | DIMINUTIVOS |"
              title2="Santiago Franco"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="GALICIA APP | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/767430979"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="GALICIA APP |" title2="Santiago Franco" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="SUPERVIELLE | POR H O POR B | Santiago Franco"
                className="react-player-video"
                url="https://vimeo.com/840224683"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SUPERVIELLE | POR H O POR B |"
              title2="Santiago Franco"
            />
          </SplideSlide>

          {/* <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='SEX EDUCATION I NETFLIX I  Santiago Franco'
                className="react-player-video"
                url="https://vimeo.com/617028986"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SEX EDUCATION | NETFLIX "
              title2="Santiago Franco"
            />
          </SplideSlide> */}

          {/* <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='LA VIDA ARTOIS | STELLA ARTOIS'
                className="react-player-video"
                url="https://vimeo.com/685534917"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1=" LA VIDA ARTOIS | STELLA ARTOIS "
              title2=" Marian & Santi"
            />
          </SplideSlide> */}
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}
export default SantiagoFranco;
