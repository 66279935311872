import {
  Box,
  chakra,
  Container,
  Flex,
  IconButton,
  Link,
  Stack,
  VStack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
} from "@chakra-ui/react";
import { FaCalendar, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ReactNode } from "react";
// import url2 from '../../../images/cropped-Asset-6-1-32x32.png';
import logoMH from "../img/logos/png/Asset_1B.png";
import './FooterSmall2.css'


export const FooterSmall2 = () => {
  const bgColor = useColorModeValue("blackAlpha.700", "gray.800");

  return (
    <div className="container-footer">
      <a onClick={() => { window.history.back() }}>
        <Image
          className="width-img-logo-footer"
          alt="logoMH"
          src={logoMH}
        />
      </a>

    </div>

  );
};
