// import React, { useState, useEffect } from "react";
// import { sortBy } from 'lodash';

// import { Swiper, SwiperSlide, pagination } from "swiper/react";
// import ReactPlayer from "react-player";
// import { HeaderMenu } from "../../../headers/headerMenu"
// import { HeaderLogoMH } from "../../../headers/headerLogoMH"
// import { getCategory, getVideosList } from "../../../services";
// import { TextFooter } from "../../../footers/textFooter";
// import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx"
// import ".././styleSelectWork.css";
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import { MetaTags } from "react-meta-tags";
// import '@splidejs/react-splide/css';
// import KeyboardEventHandler from 'react-keyboard-event-handler';

// // import required modules
// import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper";
// import { HeaderMenu_ES } from "../../../headers/headerMenu_ES";
// import { HeaderLogoMH_ES } from "../../../headers/headerLogoMH_ES";

// export default function Commercial_ES() {
//   const [swiper, setSwiper] = useState(null);
//   const [videosInfo, setVideosInfo] = useState([]);
//   const [selectedVideos, setSelectedVideos] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedCategory, setSelectedCategory] = useState('Commercial');
//   const sortedVideos = sortBy(videosInfo, 'order');
//   // console.log('Sorted Videos:', sortedVideos);

//   const handleCategoryChange = (category) => {
//     setSelectedCategory(category);
//   };

//   function createDirectorLink(name) {
//     return name
//       .normalize('NFD') // Descomponer caracteres en formas normalizadas
//       .replace(/[\u0300-\u036f]/g, '') // Remover acentos
//       .toLowerCase() // Convertir a minúsculas
//       .replace(/\s+/g, '-'); // Reemplazar espacios con guiones
//   }


//   useEffect(() => {
//     async function getSelectedVideos() {
//       try {
//         const response = await getVideosList();
//         const videos = response.data.list;
//         // console.log('Videos from API:', videos); // Verificar los videos antes de filtrar
//         const selectedVideos = videos.filter(video => video.category === "Commercial");
//         // console.log('Selected Videos:', selectedVideos); // Verificar los videos después de filtrar
//         setVideosInfo(selectedVideos); // Sólo guardar los videos con la categoría "Selected"
//         setLoading(false);
//       } catch (error) {
//         console.error('Error al obtener los videos:', error);
//         setLoading(false);
//       }
//     }
//     getSelectedVideos();
//   }, []);
//   const filteredVideos = sortedVideos.filter((video) =>
//     selectedCategory.toLowerCase() === 'commercial' || video.category === selectedCategory
//   );

//   // console.log('Filtered Videos:', filteredVideos);
//   // console.log('Selected Category:', selectedCategory);


//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <MetaTags>
//         <title>Mamá Húngara | Commercial</title>
//       </MetaTags>
//       <HeaderMenu_ES />
//       <HeaderLogoMH_ES />
//       <div className="container-swiper-home">
//         <KeyboardEventHandler
//           handleKeys={['left']}
//           onKeyEvent={(key, e) => swiper.slidePrev()}
//         />
//         <KeyboardEventHandler
//           handleKeys={['right']}
//           onKeyEvent={(key, e) => swiper.slideNext()}
//         />
//         <Swiper
//           className="mySwiper container-works-sections-test"
//           navigation={true}
//           onSwiper={setSwiper}
//           modules={[Autoplay, Pagination, Navigation, Mousewheel]}
//           slidesPerView={1}
//           loop={true}
//           spaceBetween={0}
//           centeredSlides={true}
//           mousewheel
//           pagination={{
//             clickable: true,
//             renderBullet: function (index, className) {
//               const video = filteredVideos[index];
//               if (!video) return '<div class="' + className + '"></div>';
//               const title = video.title ? video.title : '';
//               const directorName = video.director && video.director.name ? video.director.name : '';
//               const videoLink = video.videoLink ? video.videoLink : '#';

//               return (
//                 '<div class="' + className + '">' + '<p class="texto-selected-works">' + title + '</p>' + '<p class="texto-selected-works">' + directorName + '</p>' + '<div class="btn-active">' + '</div>' + '<a class="href-works" href="' + videoLink + '">' + '</a>' + '</div>'
//               );
//             }
//           }}
//         >
//           {filteredVideos.map((video, index) => (
//             <SwiperSlide key={index} className="container-video-SwiperSlide"
//               onClick={() => (window.open(`${video.videoLink}`, '_self'))}
//             >

//               <div className="player-wrapper">
//                 <ReactPlayer
//                   alt={video.title}
//                   config={{
//                     vimeo: {
//                       playerOptions: {
//                         autopause: true,
//                       },
//                       iframeParams: {
//                         autoplay: 1,
//                       },
//                       preload: false,
//                     },
//                   }}
//                   className="react-player-video"
//                   url={video.videoURL}
//                   playing={true}
//                   autoPlay={true}
//                   playsinline
//                   controls={false}
//                   volume={0}
//                   muted={true}
//                   loop={true}
//                 />
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>

//       </div>

//       <div className="container-video-works-mobile">
//         <Splide data-splide='{"type":"loop","perPage":1 }'>
//           {filteredVideos.map((video, index) => (
//             <SplideSlide key={index}>
//               <div className="drag-mobile"></div>
//               <ReactPlayer
//                 alt={`${video.title} | ${video.director}`}
//                 className="react-player-chau"
//                 url={video.videoURL}
//                 playing={true}
//                 autoPlay={true}
//                 playsinline
//                 controls={false}
//                 volume={0}
//                 muted={true}
//                 loop={true}
//               />
//             </SplideSlide>
//           ))}
//         </Splide>

//         <div className="container-titles-works-mobile">
//           {filteredVideos.map((video, index) => (
//             <a key={index} href={`${video.videoLink}`}>
//               <p className="texto-selected-works-mobile">
//                 {video.title}
//               </p>
//               <p className="texto-selected-works-mobile">
//                 {video.director.name}
//               </p>
//             </a>
//           ))}
//         </div>


//       </div>

//       <TextFooter
//         title1=" ALL COMMERCIAL"
//         href1="/es/todo-commercial"
//         hoverHref="hoverHref"
//         centerContent
//       />
//       <FooterSmall2 />
//     </>
//   );
// }

import React, { useState } from "react";
// Import Swiper React components

import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";
import { HeaderMenu } from "../../../headers/headerMenu"
import { HeaderLogoMH } from "../../../headers/headerLogoMH"
import { TextFooter } from "../../../footers/textFooter";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx"
import ".././styleSelectWork.css";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { MetaTags } from "react-meta-tags";
import '@splidejs/react-splide/css';
import KeyboardEventHandler from 'react-keyboard-event-handler';


// import required modules
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper";
import { HeaderMenu_ES } from "../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../headers/headerLogoMH_ES";

export default function Commercial_ES() {
  const [swiper, setSwiper] = useState(null);
  const title = ["AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU", "ZURICH | OTRO DEPORTE ", "YPF | IDOLAS ", "UIFRA | ALIMENTANDO SUEÑOS", "BPLAY | SPONSOR DEL CORAZON ARGENTO", "SUEÑA POTENTE | AMD"];
  const title2 = ["Gonzo Llorente", "Marian & Santi", "Fede García Rico", "Fede García Rico", "Mariano Nante", "Marian & Santi"];
  const href1 = ["/es/aguas-de-rio-de-janeiro", "/es/otro-deporte", "/es/idolas", "/es/alimentando-sueños", "/es/bplay", "/es/suenia-potente"]

  return (
    <>
      <MetaTags>
        <title>Mamá Húngara | Commercial</title>
      </MetaTags>
      <HeaderMenu_ES />
      <HeaderLogoMH_ES />

      <div className="container-swiper-home">
        <KeyboardEventHandler
          handleKeys={['left']}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="mySwiper  container-works-sections-test "
          slidesPerView={1}
          onSwiper={setSwiper}
          loop={true}
          spaceBetween={0}
          centeredSlides={true}
          Autoplay={false}
          /* autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }} */
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<div class="' + className + '">' + '<p class="texto-selected-works">' + title[index] + '</p>' + '<p class="texto-selected-works">' + title2[index] + '</p>' + '<div class="btn-active">' + '</div>' + '<a class="href-works" href="' + href1[index] + '">' + '</a>' + '</div>'
              );
            }
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, Mousewheel]}
          mousewheel

        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/es/aguas-de-rio-de-janeiro', '_self'))}
          >
            <ReactPlayer
              alt='AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU | GONZO LLORENTE'
              className="react-player-chau"
              url="https://vimeo.com/710488571"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide>

          {/* video 2 */}
          <SwiperSlide
            onClick={() => (window.open('/es/otro-deporte', '_self'))}
          >
            <ReactPlayer
              alt='ZURICH | OTRO DEPORTE'
              className="react-player-video"
              url="https://vimeo.com/752541132"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 3 */}

          <SwiperSlide
            onClick={() => (window.open('/es/idolas', '_self'))}
          >
            <ReactPlayer
              alt='YPF | IDOLAS | FEDE GARCIA RICO'
              className="react-player-video"
              url="https://vimeo.com/848844305"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/alimentando-sueños', '_self'))}
          >

            <ReactPlayer
              alt='UIFRA | ALIMENTANDO SUEÑOS | Fede Garcia Rico'
              className="react-player-chau"
              url="https://vimeo.com/755986261"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SwiperSlide>
          {/* video 4 */}
          <SwiperSlide
            onClick={() => (window.open('/es/bplay', '_self'))}
          >
            <ReactPlayer
              alt=' BPLAY | SPONSOR DEL CORAZON ARGENTO'
              className="react-player-chau"
              url="https://vimeo.com/761431757"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/suenia-potente', '_self'))}
          >
            <ReactPlayer
              alt='SUEÑA POTENTE | AMD'
              className="react-player-chau"
              url="https://vimeo.com/515381672"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            /></SwiperSlide>
        </Swiper>
      </div>
      <div className="container-video-works-mobile">
        <Splide
          data-splide='{"type":"loop","perPage":1 }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU | GONZO LLORENTE'
              className="react-player-chau"
              url="https://vimeo.com/710488571"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='ZURICH | OTRO DEPORTE'
              className="react-player-chau"
              url="https://vimeo.com/752541132 "

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='YPF | IDOLAS | Fede García Rico'
              className="react-player-chau"
              url="https://vimeo.com/848844305"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='UIFRA | ALIMENTANDO SUEÑOS'
              className="react-player-chau"
              url="https://vimeo.com/755986261"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='BPLAY | SPONSOR DEL CORAZON ARGENTO'
              className="react-player-chau"
              url="https://vimeo.com/761431757"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <ReactPlayer
              alt='SUEÑA POTENTE | AMD'
              className="react-player-chau"
              url="https://vimeo.com/515381672"

              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </SplideSlide>


        </Splide>
      </div>
      <div className="container-titles-works-mobile">
        <a href="/es/aguas-de-rio-de-janeiro">
          <p className="texto-selected-works-mobile">
            AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU
          </p>
          <p className="texto-selected-works-mobile">
            Gonzo Llorente
          </p>
        </a>
        <a href="/es/suenia-potente">
          <p className="texto-selected-works-mobile">
            ZURICH | OTRO DEPORTE
          </p>
          <p className="texto-selected-works-mobile">
            Marian & Santi
          </p>
        </a>
        <a href="/es/idolas">
          <p className="texto-selected-works-mobile">
            YPF | IDOLAS
          </p>
          <p className="texto-selected-works-mobile">
            Fede García Rico
          </p>
        </a>

        <a href="/es/alimentando-sueños">
          <p className="texto-selected-works-mobile">
            UIFRA | ALIMENTANDO SUEÑOS
          </p>
          <p className="texto-selected-works-mobile">
            Fede García Rico
          </p>
        </a>
        <a href="/es/bplay">
          <p className="texto-selected-works-mobile">
            BPLAY | SPONSOR DEL CORAZON ARGENTO
          </p>
          <p className="texto-selected-works-mobile">
            Mariano Nante
          </p>
        </a>
        <a href="/es/hecho-en-argentina">
          <p className="texto-selected-works-mobile">
            SUEÑA POTENTE | AMD
          </p>
          <p className="texto-selected-works-mobile">
            Marian & Santi
          </p>
        </a>


      </div>

      <TextFooter
        title1="ALL COMMERCIAL"
        href1="/es/todo-commercial"
        hoverHref="hoverHref"
        centerContent
      />
      <FooterSmall2 />
    </>
  );
}