import React from 'react';

import { useColorModeValue, Image, } from "@chakra-ui/react";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
import { HeaderMenu } from '../../../headers/headerMenu';
import logoMHB from "../../../img/500h/Asset_logo_blanco.png"
import { FooterLogoColors } from '../../../footers/footerColors/footerColors';
import logoMHW from "../../../img/500h/Asset_logo_blanco.png";

import '../internoFilm.css';


export const Figuras_EN = () => {
    return (
        <>
            <HeaderMenu />
            <HeaderLogoMH />
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                        “Figures” is the portrait of a woman in the final stage of a terminal illness and the impact it has on her closest relatives.
                    </p>
                    <a href='/en/figures' className='title-video-mobile-film'>
                        FIGURES
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/en/figures'>
                                FIGURES
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Directed by
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                EUGENIO CANEVARI
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Genre | Fiction
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duration | 85 minutos
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Shooting Format | 4K
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Shooting Format | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 1:85
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Black & White
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sound | Stereo
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Language | Spanish
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Country | Argentina - Spain
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                World Première | San Sebastian International Film Festival - Latin Horizons. 2018.
                            </p>

                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo="https://vimeo.com/291580751" />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/en/films"
            />
            <FooterLogoColors
                LogoColor={logoMHW}
            />
        </>
    )
}

