import React from 'react';
import ReactPlayer from "react-player";
// import { HeaderMenu } from "../../../../headers/headerMenu"
import { HeaderLogoMH } from "../../../../headers/headerLogoMH"
import { TextFooter } from '../../../../footers/textFooter';
import { FooterSmall2 } from '../../../../footers/FooterSmall2.tsx';

// import '../../grillas-works.css';
import { HeaderMenu } from '../../../../headers/headerMenu';

export const GrillasCommercial_EN = () => {
    return (<>
        <HeaderMenu />

        <HeaderLogoMH />
        <div className='container-grillas-works'>
            <div className='container-altura-works'>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/761431757"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BPLAY | SPONSOR DEL CORAZON ARGENTO
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Mariano Nante
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/bplay'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='UIFRA | ALIMENTANDO SUEÑOS | Fede Garcia Rico'
                            className="video-grilla-works"
                            url="https://vimeo.com/755986261"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                UIFRA | ALIMENTANDO SUEÑOS
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Fede Garcia Rico
                            </p>
                        </div>
                        <a className='container-href-videos' href="/en/feeding-dreams"></a>
                    </div>
                </div>
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU | GONZO LLORENTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/710488571"
                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                AE AGUAS DE RIO DE JANEIRO | THE WATER IN YOU
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Gonzo Llorente
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/rio-de-janeiro-s-water'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='Beldent I Mostrales que estas ready I Hernan Corera Juan Piczman'
                            className="video-grilla-works"
                            url="https://vimeo.com/763421158 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BELDENT | MOSTRALES QUE ESTAS READY
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera & Juan Piczman
                            </p>
                        </div>
                        <a className='container-href-videos' href='/es/beldent'></a>
                    </div>

                </div>

                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='DREAM BIG | AMD | Marian &amp; Santi'
                            className="video-grilla-works"
                            url="https://vimeo.com/515381672"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                DREAM BIG | AMD
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Marian &amp; Santi
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/dream-big'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='EL AMOR NOS SALVA | ALMA MORA | Fede Garcia Rico'
                            className="video-grilla-works"
                            url="https://vimeo.com/489073992"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                EL AMOR NOS SALVA | ALMA MORA
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Fede Garcia Rico
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/love-save-us'></a>
                    </div>




                </div>

                {/* 2 */}

                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='UBER | BOXER | GONZO LLORENTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/710392513"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                UBER | BOXER
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Gonzo Llorente
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/uber-boxer'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='HECHO EN ARGENTINA | NETFLIX | Hernán Corera & Juan Piczman'
                            className="video-grilla-works"
                            url="https://vimeo.com/693596507"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                MADE IN ARGENTINA | NETFLIX
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera &amp; Juan Piczman
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/made-in-argentina'></a>
                    </div>


                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='LA VIDA ARTOIS I STELLA ARTOIS | Mariano Nante & Santiago Franco'
                            className="video-grilla-works"
                            url="https://vimeo.com/685534917"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                ARTOIS'S LIFE | STELLA ARTOIS
                            </p>
                            <p className='text-footer-grillas-works-2'>
                            Mariano Nante & Santiago Franco
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/artois-s-life'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='SEX EDUCATION | NETFLIX'
                            className="video-grilla-works"
                            url="https://vimeo.com/617028986"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                SEX EDUCATION | NETFLIX
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Santiago Franco
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/sex-education'></a>
                    </div>

                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='  JUGOS DEL VALLE'
                            className="video-grilla-works"
                            url="https://vimeo.com/727415690"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                JUGOS DEL VALLE
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Marian &amp; Santi
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/jugos-del-valle'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='ASPIREMOS A MÁS I ATMA I FEDE GARCIA RICO'
                            className="video-grilla-works"
                            url="https://vimeo.com/616466255"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                ATMA | ASPIRE FOR MORE
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Fede García Rico
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/aspire-for-more'></a>
                    </div>


                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='ROMANCE ETERNO I FIFA 21 I SANTIAGO FRANCO'
                            className="video-grilla-works"
                            url="https://vimeo.com/497254514"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                FIFA 21 | ETERNAL ROMANCE
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Santiago Franco
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/eternal-romance'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='RUGBY WORLD CUP | ZURICH'
                            className="video-grilla-works"
                            url="https://vimeo.com/361111813"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                ZURICH | RUGBY WORLD CUP
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Marian &amp; Santi
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/rugby-world-cup'></a>
                    </div>

                </div>

                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt="BECK'S I HACELO DIFERENTE"
                            className="video-grilla-works"
                            url="https://vimeo.com/703861246"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BECK'S | DO IT DIFFERENT
                            </p>
                            <p className='text-footer-grillas-works-2'>
                            Olivia Sanchez & Martina Galli Agulla
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/becks-do-it-different'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='TOQUE I BRAHMA POMELO'
                            className="video-grilla-works"
                            url="https://vimeo.com/649535282"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BRAHMA | POMELO TOUCH
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Santiago Franco & Fede García Rico
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/touch-brahma-pomelo'></a>
                    </div>

                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='HYUNDAI I YOUR LIFE'
                            className="video-grilla-works"
                            url="https://vimeo.com/710406194"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                HYUNDAI | YOUR LIFE
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Gonzo Llorente
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/your-life'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='BRAHMA DIMINUTIVOS'
                            className="video-grilla-works"
                            url="https://vimeo.com/344817236"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BRAHMA | DIMINUTIVES
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Marian &amp; Santi
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/brahma-diminutives'></a>
                    </div>

                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='PEUGEOT 208'
                            className="video-grilla-works"
                            url="https://vimeo.com/475924465 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                PEUGEOT | 208
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Fede García Rico
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/208-peugeot'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='PURINA DOG CHOW I DIEGO FRIED'
                            className="video-grilla-works"
                            url="https://vimeo.com/752245982"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                PURINA DOG CHOW
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Diego Fried
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/purina-dog-chow'></a>
                    </div>

                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='BRAHMA | TRAP ARGENTO'
                            className="video-grilla-works"
                            url="https://vimeo.com/371941473"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BRAHMA | TRAP ARGENTO
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Hernán Corera &amp; Tomo Terzano
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/trap-argento'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='NIVEA I  BEAUTY I GONZO LLORENTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/710391880"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                NIVEA | BEAUTY
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Gonzo Llorente
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/nivea-beauty'></a>
                    </div>

                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='SPRITE I LOVE YOU HATER'
                            className="video-grilla-works"
                            url="https://vimeo.com/703862295"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                SPRITE | LOVE YOU HATER
                            </p>
                            <p className='text-footer-grillas-works-2'>
                            Olivia Sanchez & Martina Galli Agulla
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/sprite-love-you-hater'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='INCREDIBLENDS I DADA I TOMO TERZANO & JUAN PICZMAN'
                            className="video-grilla-works"
                            url="https://vimeo.com/583411583"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                INCREDIBLENDS | DADA
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Tomo Terzano &amp; Juan Piczman
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/incrediblends-dada'></a>
                    </div>
                    
                </div>
                {/* 2 */}
                <div className='container-2-videos-works'>
                <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='BETUNOIRE | STELLA ARTOIS'
                            className="video-grilla-works"
                            url="https://vimeo.com/589890138"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BETUNOIRE | STELLA ARTOIS
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Santiago Franco
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/betunoire'></a>
                    </div>
                    <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='NO SE CHONGUEA | BRAHMA'
                            className="video-grilla-works"
                            url="https://vimeo.com/348240617 "

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                               NO SE CHONGUEA | BRAHMA 
                            </p>
                            <p className='text-footer-grillas-works-2'>
                                Martin Holzman
                            </p>
                        </div>
                        <a className='container-href-videos' href='/en/no-se-chonguea'></a>
                    </div>
                    {/* <div className='contrainer-50-works'>
                        <ReactPlayer
                            alt='BECK´S I HACELO DIFERENTE'
                            className="video-grilla-works"
                            url="https://vimeo.com/703861246"

                            playing={true}
                            autoPlay={true}
                            playsinline
                            controls={false}
                            volume={0}
                            muted={true}
                            loop={true}
                        />
                        <div className='container-footer-grillas-works'>
                            <p className="text-footer-grillas-works-1">
                                BECK´S | HACELO DIFERENTE
                            </p>
                            <p className='text-footer-grillas-works-2'>

                            </p>
                        </div>
                        <a className='container-href-videos' href='/jugos-del-valle'></a>
                    </div> */}
                </div>
                {/* 2 */}

            </div>
        </div>
        <TextFooter
            title1="COMMERCIAL"
            href1="/en/commercial"
        />
        <FooterSmall2 />
    </>
    )
}

