import React from "react";
import ReactPlayer from "react-player";

import { HeaderMenu } from "../../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../../headers/headerLogoMH";
import { TextFooter } from "../../../../footers/textFooter";
import { FooterSmall2 } from "../../../../footers/FooterSmall2.tsx";
import { MetaTags } from "react-meta-tags";
import "../../grillas-works.css";
import { HeaderMenu_ES } from "../../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../../headers/headerLogoMH_ES";

export const GrillasWorks_ES = () => {
  return (
    <>
      <MetaTags>
        <title>Mamá Húngara | All Selected</title>
      </MetaTags>
      <HeaderMenu_ES />
      <HeaderLogoMH_ES />
      <div className="container-grillas-works">
        <div className="container-altura-works">
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE"
                className="video-grilla-works"
                url="https://vimeo.com/935527550"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  PERSONAL PAY | ESTA BUENO COMPARTIRLO
                </p>
                <p className="text-footer-grillas-works-2">Mariano Nante</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/netflix-3-en-1"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="NETFLIX | Hernan Corera & Juan Piczman"
                className="video-grilla-works"
                url="https://vimeo.com/862779909"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">NETFLIX</p>
                <p className="text-footer-grillas-works-2">
                  Hernan Corera & Juan Piczman
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/es/netflix-3-en-1"
              ></a>
            </div>
            {/*  */}
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PROSEGUR | NADA ES TAN PROFESIONAL | CARMEN RIVOIRA | SUB SPA"
                className="video-grilla-works"
                url="https://vimeo.com/925990391"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  PROSEGUR | NADA ES TAN PROFESIONAL
                </p>
                <p className="text-footer-grillas-works-2">Carmen Rivoira</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/netflix-3-en-1"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINO | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/891549390"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    BGH | CALUROSAMENTE ARGENTINO
                </p>
                <p className="text-footer-grillas-works-2">
                    Santiago Franco
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/es/netflix-3-en-1"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PERSONAL | CONEXIÓN TOTAL | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/888108453"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    PERSONAL | CONEXIÓN TOTAL
                </p>
                <p className="text-footer-grillas-works-2">
                    Gonzo Llorente
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/es/netflix-3-en-1"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BPLAY | SPONSOR OFICIAL | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/850727345"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BPLAY | SPONSOR OFICIAL
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/bplay-sponsor-oficial"
              ></a>
            </div>
          </div>
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="YPF | ÍDOLAS | FEDE GARCIA RICO"
                className="video-grilla-works"
                url="https://vimeo.com/848844305"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">YPF | ÍDOLAS</p>
                <p className="text-footer-grillas-works-2">Fede García Rico</p>
              </div>
              <a className="container-href-videos" href="/es/idolas"></a>
            </div>
            {/*  */}
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="SUPERVIELLE | Por H o por B | MARIANO NANTE"
                className="video-grilla-works"
                url="https://vimeo.com/840224683"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  SUPERVIELLE | POR H o POR B
                </p>
                <p className="text-footer-grillas-works-2">Mariano Nante</p>
              </div>
              <a className="container-href-videos" href="/es/supervielle"></a>
            </div>
          </div>
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="HBOMAX | DEL CINE A TU CASA | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/806073285"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  HBOMAX | DEL CINE A TU CASA
                </p>
                <p className="text-footer-grillas-works-2">Gonzo Llorente</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/del-cine-a-tu-casa"
              ></a>
            </div>
            {/*  */}
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZÓN ARGENTO | MARIANO NANTE"
                className="video-grilla-works"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BPLAY | SPONSOR DEL CORAZÓN ARGENTO
                </p>
                <p className="text-footer-grillas-works-2">Mariano Nante</p>
              </div>
              <a className="container-href-videos" href="/es/bplay"></a>
            </div>
          </div>

          {/* video 3 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="ZURICH | OTRO DEPORTE | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/752541132"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  ZURICH | OTRO DEPORTE
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a className="container-href-videos" href="/es/otro-deporte"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="STRIX | LA EVOLUCION DEL LOJACK | FEDE GARCIA RICO"
                className="video-grilla-works"
                url="https://vimeo.com/756236637"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  STRIX | LA EVOLUCION DEL LOJACK
                </p>
                <p className="text-footer-grillas-works-2">Fede García Rico</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/la-evolucion-del-lojack"
              ></a>
            </div>
          </div>
          {/*  */}

          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="KELLOGG´S | POP-TARTS | DIEGO FRIED"
                className="video-grilla-works"
                url="https://vimeo.com/769897032"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  KELLOGG'S | POP-TARTS
                </p>
                <p className="text-footer-grillas-works-2">Diego Fried</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/kelloggs-pop-tarts"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BELDENT | MOSTRALES QUE ESTAS READY"
                className="video-grilla-works"
                url="https://vimeo.com/763421158"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BELDENT | MOSTRALES QUE ESTAS READY
                </p>
                <p className="text-footer-grillas-works-2">
                  Hernán Corera & Juan Piczman
                </p>
              </div>
              <a className="container-href-videos" href="/es/beldent"></a>
            </div>
          </div>
          {/*  */}

          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt=" AE | AGUAS DE RIO DE JANEIRO"
                className="video-grilla-works"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  AE | AGUAS DE RIO DE JANEIRO
                </p>
                <p className="text-footer-grillas-works-2">Gonzo Llorente</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/aguas-de-rio-de-janeiro"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="AMERICANINO | THE REASON IS FREEDOM"
                className="video-grilla-works"
                url="https://vimeo.com/766590399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  AMERICANINO | THE REASON IS FREEDOM
                </p>
                <p className="text-footer-grillas-works-2">MC Calle</p>
              </div>
              <a className="container-href-videos" href="/es/americanino"></a>
            </div>
          </div>
          <div className="container-2-videos-works">
            {/* <div className="contrainer-50-works">
              <ReactPlayer
                alt="NADIE | SIN BANDERA"
                className="video-grilla-works"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  NADIE | SIN BANDERA
                </p>
                <p className="text-footer-grillas-works-2">
                  Hernán Corera & Juan Piczman
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/es/suenia-potente"
              ></a>
            </div> */}
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="2-SUEÑA POTENTE | AMD"
                className="video-grilla-works"
                url="https://vimeo.com/515269858"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  SUEÑA POTENTE | AMD
                </p>
                <p className="text-footer-grillas-works-2">Marian & Santi</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/suenia-potente"
              ></a>
            </div>
          </div>

          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="UBER | BOXER | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/710392513"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">UBER | BOXER</p>
                <p className="text-footer-grillas-works-2">Gonzo Llorente</p>
              </div>
              <a className="container-href-videos" href="/es/uber-boxer"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="4-EL AMOR NOS SALVA I ALMA MORA I Fede Garcia Rico"
                className="video-grilla-works"
                url="https://vimeo.com/489073992"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  EL AMOR NOS SALVA | ALMA MORA
                </p>
                <p className="text-footer-grillas-works-2">Fede Garcia Rico</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/el-amor-nos-salva"
              ></a>
            </div>
          </div>
          {/* 2 */}
          <div className="container-2-videos-works">
            {/* <div className="contrainer-50-works">
              <ReactPlayer
                alt="PARAISO | SADAELS | HERNAN CORERA"
                className="video-grilla-works"
                url="https://vimeo.com/520227700"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">PARAISO | SADAELS</p>
                <p className="text-footer-grillas-works-2">Hernán Corera</p>
              </div>
              <a className="container-href-videos" href="/es/paraiso"></a>
            </div> */}
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="DOKKAEBIS | MMIC "
                className="video-grilla-works"
                url="https://vimeo.com/606825402"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">DOKKAEBIS | MMIC</p>
                <p className="text-footer-grillas-works-2">Alcibiade Cohen</p>
              </div>
              <a className="container-href-videos" href="/es/dokkaebis"></a>
            </div>
          </div>
          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="UIFRA | ALIMENTANDO SUEÑOS | Fede Garcia Rico"
                className="video-grilla-works"
                url="https://vimeo.com/755986261"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  UIFRA | ALIMENTANDO SUEÑOS
                </p>
                <p className="text-footer-grillas-works-2">Fede García Rico</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/alimentando-sueños"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt=" LA VIDA ARTOIS | STELLA ARTOIS | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/685534917"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  LA VIDA ARTOIS | STELLA ARTOIS
                </p>
                <p className="text-footer-grillas-works-2">Marian & Santi</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/la-vida-artois"
              ></a>
            </div>
          </div>
          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="SEX EDUCATION | NETFLIX"
                className="video-grilla-works"
                url="https://vimeo.com/617028986"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  SEX EDUCATION | NETFLIX
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a className="container-href-videos" href="/es/sex-education"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BECK'S | HACELO DIFERENTE"
                className="video-grilla-works"
                url="https://vimeo.com/703861246"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BECK'S | HACELO DIFERENTE
                </p>
                <p className="text-footer-grillas-works-2">
                  Olivia Sanchez & Martina Galli Agulla
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/es/becks-hacelo-diferente"
              ></a>
            </div>
          </div>
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="5-HECHO EN ARGENTINA | NETFLIX | Hernán Corera y Juan Piczman "
                className="video-grilla-works"
                url="https://vimeo.com/693596507"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  HECHO EN ARGENTINA | NETFLIX
                </p>
                <p className="text-footer-grillas-works-2">
                  Hernán Corera &amp; Juan Piczman
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/es/hecho-en-argentina"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="ROMANCE ETERNO | FIFA 21 | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/497254514"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  ROMANCE ETERNO | FIFA 21
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a
                className="container-href-videos"
                href="/es/romance-eterno"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <TextFooter title1="SELECTED" href1="/es/selected" />
      <FooterSmall2 />
    </>
  );
};
