import React from 'react';

// import { Image } from '@chakra-ui/react';
import { TextFooter } from '../../../footers/textFooter';
// import { HeaderLogoMH } from '../../../headers/headerLogoMH';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import VideoControlFilm from '../../../mouse/videoControlFilm';
// import { HeaderMenu } from '../../../headers/headerMenu';
import { MetaTags } from 'react-meta-tags';
import '../internoFilm.css';
import { HeaderMenu_ES } from '../../../headers/headerMenu_ES';
import { HeaderLogoMH_ES } from '../../../headers/headerLogoMH_ES';


export const Schettinimous = () => {
    return (
        <>
            <HeaderMenu_ES />
            <HeaderLogoMH_ES />
            <MetaTags>
                <title>Mamá Húngara | Schettinimous</title>
                <meta
                    content="Un viejo artista psico-mago es invitado a un programa de televisión donde deberá demostrar sus predicciones sobre el futuro de la humanidad."
                    description="Un viejo artista psico-mago es invitado a un programa de televisión donde deberá demostrar sus predicciones sobre el futuro de la humanidad."
                />
            </MetaTags>
            <div className="container-data-film">
                <div className="container-list-video-data-film">
                    {/* poster 1 */}
                    <p className="title-data-film">
                    Un viejo artista psico-mago es invitado a un programa de televisión donde deberá demostrar sus predicciones sobre el futuro de la humanidad.
                    </p>
                    <a href='/es/schettinimous' className='title-video-mobile-film'>
                    SCHETTINIMOUS
                    </a>
                    <div className='container-data-video-100-film'>
                        <div className='data-video-1-film'>
                            {/* titulo */}
                            <a className='text-data-vidoe-film-0 color-film-1' href='/es/schettinimous'>
                            SCHETTINIMOUS
                            </a>
                            {/* directed */}
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-100'>
                                Dirigida por
                            </p>
                            <p className='text-data-vidoe-film-1 color-film-2'>
                                TOMO TERZANO
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Género | Híbrido
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2 margin-top-50'>
                                Sub-Género | Ciencia ficción - Drama - Comedia - Biografía
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Duración | 13 minutos 54 segundos
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Formato de rodaje | 4K
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Formato de proyección | DCP
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Ratio | 16:9
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Color | Color
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Sonido | Estéreo
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Idioma | Español
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                País | Argentina
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                &nbsp;
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                                Estreno Mundial en la Competencia Oficial Argentina [24] BAFICI 
                            </p>
                            <p className='text-data-vidoe-film-2 color-film-2'>
                            (Buenos Aires Festival Internacional de Cine Independiente)
                            </p>
                        </div>

                        <div className="video-gilda">
                            <VideoControlFilm urlVideo='https://vimeo.com/818975035' />
                        </div>
                    </div>
                </div>
            </div>
            <TextFooter
                title1="FILMS"
                href1="/es/films"
            />
            <FooterSmall2 />
        </>
    )
}

