import React from 'react';
import ReactPlayer from "react-player";
import { TextFooter } from '../../../footers/textFooter';
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from '../../../headers/headerText';
import { FooterSmall2 } from '../../../footers/FooterSmall2.tsx';
import MetaTags from 'react-meta-tags';
import './grillas-talents.css';

export const GrillaKacper = () => {
    return (<>
        <HeaderTexts
            title="Kacper Larski"
        />
        <HeaderMenu />
        <div className='container-grillas-talents'>
            <MetaTags>
                <title>MH | Todo Kacper Larski</title>
                <meta
                    content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                    description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
                />
            </MetaTags>
            <div className='container-altura-grillas-talents'>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/garmin'></a>
                    <ReactPlayer
                        alt='GARMIN | KACPER LARSKI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/882908413"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        GARMIN
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                        Kacper Larski
                        </p>
                    </div>

                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/ford'></a>
                    <ReactPlayer
                        alt='FORD | KACPER LARSKI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/882911580"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        FORD 
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Kacper Larski
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/jeep-conform-rough'></a>
                    <ReactPlayer
                        alt='JEEP | CONFORM ROUGH | KACPER LARSKI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/882892554"
                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        JEEP | CONFORM ROUGH
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Kacper Larski
                        </p>
                    </div>
                </div>
                {/* 1 */}
                <div className="container-video-talents-1">
                <a className='container-href-videos0-talents' href='/es/we-make-wow'></a>
                    <ReactPlayer
                        alt='HYUNDAI | KACPER LARSKI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/882916034"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        HYUNDAI | WE MAKE WOW
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Kacper Larski
                        </p>
                    </div>

                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/land-rover-defender'></a>
                    <ReactPlayer
                        alt='LAND ROVER DEFENDER | KACPER LARSKI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/882885242"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        LAND ROVER DEFENDER
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Kacper Larski
                        </p>
                    </div>
                </div>
                <div className="container-video-talents-1">
                    <a className='container-href-videos0-talents' href='/es/mazda'></a>
                    <ReactPlayer
                       alt='MAZDA | KACPER LARSKI'
                        className="video-grilla-talents"
                        url="https://vimeo.com/882906067"

                        playing={true}
                        autoPlay={true}
                        playsinline
                        controls={false}
                        volume={0}
                        muted={true}
                        loop={true}
                    />
                    <div className='container-footer-grillas-talents'>
                        <p className="text-footer-grillas-talents-1">
                        MAZDA
                        </p>
                        <p className='text-footer-grillas-talents-2'>
                            Kacper Larski
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a onClick={() => { window.history.back() }}>
            <TextFooter
                title1="BACK"
            />
        </a>

        <FooterSmall2 />
    </>
    )
}
