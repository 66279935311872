import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Link, useHistory } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from "swiper";
import { TextFooter } from "../../../footers/textFooter.js";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Mouse3 from "../../../mouse/Mouse3";
import "./videoHome.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { MetaTags } from "react-meta-tags";


import "@splidejs/react-splide/css";

function VideoHome() {
  const [swiper, setSwiper] = useState(null);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videosInfo, setVideosInfo] = useState([]);
  const history = useHistory();


  return (
    <>
      <div className="container-swiper-home-page">
        <MetaTags>
          <title>Mamá Húngara</title>
          <meta description="Productora audiovisual con base en Buenos Aires que crea contenido original para todo el mundo" />
        </MetaTags>
        <Mouse3 />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />

        <Swiper
          className="mySwiper cursor-none container-slider-photographer-still"
          onSwiper={setSwiper}
          slidesPerView={1}
          loopedSlides={4}
          spaceBetween={0}
          // freeMode={false}
          loop={true}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          navigation
        >
          <SwiperSlide
            onClick={() => window.open("/es/gatorade", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="GATORADE | MATCH SAVER | Fede García Rico"
                className="react-player-video"
                url="https://vimeo.com/940878425"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GATORADE | MATCH SAVER |"
              title2="Fede García Rico"
              href1="/es/gatorade"
              href2="/es/gatorade"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/nadie", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="NADIE | SIN BANDERA | Hernán Corera &amp; Juan Piczman"
                className="react-player-video"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NADIE | SIN BANDERA |"
              title2="Hernán Corera &amp; Juan Piczman"
              href1="/es/nadie"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/personal-pay-esta-bueno-compartirlo", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/935527550"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PERSONAL PAY | ESTA BUENO COMPARTIRLO |"
              title2="MARIANO NANTE"
              href1="/es/personal-pay-esta-bueno-compartirlo"
              href2="/es/personal-pay-esta-bueno-compartirlo"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bgh-calurosamente", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINO | SANTIAGO FRANCO"
                className="react-player-video"
                url="https://vimeo.com/891549390"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BGH | CALUROSAMENTE ARGENTINOS |"
              title2="Santiago Franco"
              href1="/es/bgh-calurosamente"
              href2="/es/santiago-franco"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/netflix-3-en-1", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="NETFLIX | ESTA EN NETFLIX "
                className="react-player-video"
                url="https://vimeo.com/856874220"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NETFLIX | ESTA EN NETFLIX |"
              title2="Hernan Corera & Juan Piczman"
              href1="/es/netflix-3-en-1"
              href2="/es/hernan-corera"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/aguas-de-rio-de-janeiro", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt" style={{ padding: 0 }}>
              <ReactPlayer
                alt="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I"
              title2="GONZO LLORENTE"
              href1="/es/aguas-de-rio-de-janeiro"
              href2="/es/gonzo-llorente"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bplay", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BPLAY | SPONSOR DEL CORAZON ARGENTO |"
              title2="Mariano Nante"
              href1="/es/bplay"
              href2="/es/marian-santi"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/bonafont", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/917605551"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BONAFONT | KILOMETROS QUE NOS MUEVEN |"
              title2="Carmen Rivoira"
              href1="/es/bonafont"
              href2="/es/carmen-rivoira"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/amor-mio", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                className="react-player-video"
                url="https://vimeo.com/954460674"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA CAMPOS | AMOR MIO | "
              title2="Hernán Corera & Mateo Yaya"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/es/we-make-wow', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper-testtt">
              <ReactPlayer
                alt='HYUNDAI | KACPER LARSKI'
                className="react-player-video"
                url="https://vimeo.com/882916034"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | WE MAKE WOW"
              title2="KACPER LARSKI"
            />

          </SwiperSlide>
        </Swiper>
      </div>

      {/* esto es para mobile osea otra vista  */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"loop","perPage":1 }'>
          {/* Video 1*/}
          <SplideSlide
            onClick={() => (window.location = "/es/gatorade")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="GATORADE | MATCH SAVER | Fede García Rico"
                className="react-player-video"
                url="https://vimeo.com/940878425"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
            title1="GATORADE | MATCH SAVER |"
            title2="Fede García Rico"
            href1="/es/gatorade"
            href2="/es/gatorade"
            />
          </SplideSlide>
          <SplideSlide
            onClick={() => (window.location = "/es/nadie")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NADIE | SIN BANDERA | Hernán Corera & Juan Piczman"
                className="react-player-video"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
            title1="NADIE | SIN BANDERA |"
            title2="Hernán Corera & Juan Piczman"
            href1="/es/nadie"
            href2="/es/hernan-corera"
            />
          </SplideSlide>
          <SplideSlide
            onClick={() => (window.location = "/es/personal-pay-esta-bueno-compartirlo")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE "
                className="react-player-video"
                url="https://vimeo.com/935527550"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
            title1="PERSONAL PAY | ESTA BUENO COMPARTIRLO |"
            title2="MARIANO NANTE"
            href1="/es/personal-pay-esta-bueno-compartirlo"
            href2="/es/personal-pay-esta-bueno-compartirlo"
            />
          </SplideSlide>
          <SplideSlide
            onClick={() => (window.location = "/es/bgh-calurosamente")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                 alt="BGH | CALUROSAMENTE ARGENTINO | SANTIAGO FRANCO"
                 className="react-player-video"
                 url="https://vimeo.com/891549390"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
           title1="BGH | CALUROSAMENTE ARGENTINO |"
           title2="Santiago Franco"
           href1="/es/netflix-3-en-1"
           href2="/es/hernan-corera"
            />
          </SplideSlide>
          <SplideSlide
            onClick={() => (window.location = "/es/netflix-3-en-1")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NETFLIX | ESTA EN NETFLIX "
                className="react-player-video"
                url="https://vimeo.com/856874220"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
            title1="NETFLIX | ESTA EN NETFLIX |"
            title2="Hernan Corera & Juan Piczman"
            href1="/es/netflix-3-en-1"
            href2="/es/hernan-corera"
            />
          </SplideSlide>
          <SplideSlide
            onClick={() => (window.location = "/es/aguas-de-rio-de-janeiro")}
          >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I GONZO LLORENTE"
                className="react-player-video"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
               title1="AE AGUAS DE RIO DE JANERIO I THE WATER IN YOU I"
               title2="GONZO LLORENTE"
               href1="/es/aguas-de-rio-de-janeiro"
               href2="/es/gonzo-llorente"
            />
          </SplideSlide>
          <SplideSlide onClick={() => (window.location = "/es/bplay")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZON ARGENTO | MARIANO NANTE"
                className="react-player-video"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BPLAY | SPONSOR DEL CORAZON ARGENTO"
              title2="Mariano Nante"
              href1="/es/bplay"
              href2="/es/marian-santi"
            />
          </SplideSlide>
          <SplideSlide onClick={() => (window.location = "/es/bonafont")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="BONAFONT | KILOMETROS QUE NOS MUEVEN | CARMEN RIVOIRA"
                className="react-player-video"
                url="https://vimeo.com/917605551"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BONAFONT | KILOMETROS QUE NOS MUEVEN"
              title2="Carmen Rivoira"
              href1="/es/bonafont"
              href2="/es/carmen-rivoira"
            />
          </SplideSlide>
          <SplideSlide onClick={() => (window.location = "/es/amor-mio")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="MARIA CAMPOS | AMOR MIO | Hernan Corera & Mateo Yaya"
                className="react-player-video"
                url="https://vimeo.com/954460674"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MARIA CAMPOS | AMOR MIO"
              title2="Hernán Corera & Mateo Yaya"
              href1="/es/amor-mio"
              href2="/es/hernan-corera"
            />
          </SplideSlide>
          
          {/* Video 2*/}
          <SplideSlide onClick={() => (window.location = "/es/we-make-wow")}>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="HYUNDAI | WE MAKE WOW | KACPER LARSKI"
                className="react-player-video"
                url="https://vimeo.com/882916034"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="HYUNDAI | WE MAKE WOW"
              title2="KACPER LARSKI"
              href1="/es/we-make-wow"
              href2="/es/kacper-larski"
            />
          </SplideSlide>
          {/*  */}
        </Splide>
      </div>
    </>
  );
}


export default VideoHome;
