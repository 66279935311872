import React from "react";
import ReactDOM from "react-dom";
import IndexPage from "./App.js";
import "./index.css";
import "./fonts/GoodSans-Thin.otf";
import "./fonts/Serifbabe_Regular.ttf";
import Vimeo from "@u-wave/react-vimeo";
import "focus-visible/dist/focus-visible";
import { ChakraProvider, extendTheme, theme as base } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import { StrictMode } from "react";
import { DirectorsProvider } from "./components/context/directorsProvider.js";

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
    background-color: none;
  }
`;

const theme = extendTheme({
  colors: {
    brand: {
      50: "#f5fee5",
      100: "#e1fbb2",
      200: "#cdf781",
      300: "#b8ee56",
      400: "#a2e032",
      500: "#8ac919",
      600: "#71ab09",
      700: "#578602",
      800: "#3c5e00",
      900: "#203300",
    },
  },
  fonts: {
    heading: `GoodSans-Thin, Serifbabe_Regular, ${base.fonts?.heading}`,
    body: `GoodSans-Thin, Serifbabe_Regular, ${base.fonts?.body}`,
    footer: `GoodSans-Thin, Serifbabe_Regular, ${base.fonts?.footer}`,
    content: `GoodSans-Thin, ${base.fonts?.content}`,
  },
  config: {
    initialColorMode: "dark",
    color: "black",
  },

  // https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9
  // para sacar el outline azul de los estilos
});
export default theme;

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <React.Fragment>
      <DirectorsProvider>
        <ChakraProvider theme={theme}>
          <Global styles={GlobalStyles} />
          <IndexPage />
        </ChakraProvider>
      </DirectorsProvider>
    </React.Fragment>
  </StrictMode>,
  rootElement
);
