import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from "../../../footers/textFooter.js";
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from "../../../headers/headerText.js";
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from "./videos/zurich.mp4";
import "swiper/css";
import "swiper/css/bundle";
import "./styleSlideimage2.css";
import { Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import MetaTags from "react-meta-tags";
import "@splidejs/react-splide/css";
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from "react-keyboard-event-handler";

function FernandoCattori() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES title="FERNANDO CATTORI" />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>FERNANDO CATTORI</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={["left"]}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={["right"]}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className="cursor-none container-swiper-home container-slider-photographer-still"
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => window.open("/es/llorar", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="LLORAR | RUBIO | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920503144"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="LLORAR | RUBIO | " title2="FERNANDO CATTORI" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/elsa-y-elmar", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="ELSA Y ELMAR | VUELVE | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920506198"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ELSA Y ELMAR | VUELVE"
              title2="FERNANDO CATTORI"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/palacio-de-hierro", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="PALACIO DE HIERRO | SATÉLITE | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920503636"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PALACIO DE HIERRO | SATÉLITE |"
              title2="FERNANDO CATTORI"
            />
          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => window.open("/es/ultra-hard-seltzer", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="MICHELOB ULTRA HARD SELTZER | SUMMER LOVE | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920505558"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MICHELOB ULTRA HARD SELTZER | SUMMER LOVE |"
              title2="FERNANDO CATTORI"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/runners-ayudando", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="NIKE | RUNNERS AYUDANDO RUNNERS | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920505170"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NIKE | RUNNERS AYUDANDO RUNNERS |"
              title2="FERNANDO CATTORI"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/cartier", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="GQ CARTIER | AHMED | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920502387"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GQ CARTIER | AHMED |"
              title2="FERNANDO CATTORI"
            />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => window.open("/es/michelob-lanzamiento", "_self")}
            className="container-video-SwiperSlide"
          >
            <div className="player-wrapper">
              <ReactPlayer
                alt="MICHELOB ULTRA HARD SELTZER | LANZAMIENTO | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920504432"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MICHELOB ULTRA HARD SELTZER | LANZAMIENTO |"
              title2="FERNANDO CATTORI"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide data-splide='{"type":"slide","perPage": 1, "drag": "free" }'>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="LLORAR | RUBIO | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920503144"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter title1="LLORAR | RUBIO | " title2="FERNANDO CATTORI" />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="ELSA Y ELMAR | VUELVE | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920506198"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="ELSA Y ELMAR | VUELVE"
              title2="FERNANDO CATTORI"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="PALACIO DE HIERRO | SATÉLITE | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920503636"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="PALACIO DE HIERRO | SATÉLITE |"
              title2="FERNANDO CATTORI"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="MICHELOB ULTRA HARD SELTZER | SUMMER LOVE | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920505558"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MICHELOB ULTRA HARD SELTZER | SUMMER LOVE |"
              title2="FERNANDO CATTORI"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="NIKE | RUNNERS AYUDANDO RUNNERS | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920505170"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="NIKE | RUNNERS AYUDANDO RUNNERS |"
              title2="FERNANDO CATTORI"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="GQ CARTIER | AHMED | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920502387"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="GQ CARTIER | AHMED |"
              title2="FERNANDO CATTORI"
            />
          </SplideSlide>
          <SplideSlide>
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt="MICHELOB ULTRA HARD SELTZER | LANZAMIENTO | FERNANDO CATTORI"
                className="react-player-video"
                url="https://vimeo.com/920504432"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="MICHELOB ULTRA HARD SELTZER | LANZAMIENTO |"
              title2="FERNANDO CATTORI"
            />
            <a className="more" href="/es/todo-fernando-cattori">
              More
            </a>
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors LogoColor={logoMHV} />
    </>
  );
}

export default FernandoCattori;
