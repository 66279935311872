import React from "react";
import { useState } from "react";

import { HeaderLogoNegro } from "../../headers/headerLogoNegro";
import { HeaderLogoVioleta } from "../../headers/headerLogoVioleta";
import { HeaderLogoMH } from "../../headers/headerLogoMH";

import { HeaderMenuNegro } from "../../headers/headerMenuNegro";
import { HeaderMenuVioleta } from "../../headers/headerMenuVioleta";
import { HeaderMenu } from "../../headers/headerMenu";
import { HeaderMenuTalents } from "../../headers/headerTalents/headerMenuTalents";

import { FooterSmall2 } from "../../footers/FooterSmall2.tsx";
import { FooterLogoColors } from "../../footers/footerColors/footerColors";

import logoMHW from "../../img/500h/Asset_logo_blanco.png";
import logoMHNegro from "../../img/logos/png/Asset_1.png";
import logoVioleta from "../../img/500h/Asset_logo_violet.png";
import { MetaTags } from "react-meta-tags";
// import './family.css';

export const Person = ({
  name,
  data1,
  data2,
  data3,
  hrefMail,
  mail,
  hrefData1,
  hrefData2,
  hrefData3,
  displaynoneFamily2,
  displaynoneFamily3,
  svg1,
  svg2,
  svg3,
}) => (
  <div className="container-data-person-family ">
    <a className="text-data-family">{name}</a>
    <a
      style={{ display: "flex", alignItems: "center" }}
      className="text-data-family"
      href={hrefData1}
      target="_blank"
    >
      {svg1}
      {data1}
    </a>
    <a
      style={{ display: "flex", alignItems: "center" }}
      className={`text-data-family  ${displaynoneFamily2}`}
      href={hrefData2}
      target="_blank"
    >
      {svg2}
      {data2}
    </a>
    <a
      style={{ display: "flex", alignItems: "center" }}
      className={` text-data-family  ${displaynoneFamily3}`}
      href={hrefData3}
      target="_blank"
    >
      {svg3}
      {data3}
    </a>
    <a className="text-data-family" href={hrefMail} target="_blank">
      {mail}
    </a>
  </div>
);

export const Family_EN = () => {
  const [isHoverVioleta, setIsHoverVioleta] = useState(false);
  const [isHoverVerde, setIsHoverVerde] = useState(false);

  const HoverVerde = () => {
    setIsHoverVerde(true);
  };

  const OutVerde = () => {
    setIsHoverVerde(false);
  };

  const HoverVioleta = () => {
    setIsHoverVioleta(true);
  };

  const OutVioleta = () => {
    setIsHoverVioleta(false);
  };

  return (
    <>
      {/* logos */}
      {/*  */}
      <div
        className={` ${
          isHoverVerde ? "header-footer-colores-family--disabled" : ""
        } ${isHoverVioleta ? "header-footer-colores-family--disabled" : ""}`}
      >
        <HeaderLogoMH />
      </div>
      <div
        className={
          isHoverVerde
            ? "header-footer-colores-family--active"
            : "header-footer-colores-family--disabled"
        }
      >
        <HeaderLogoNegro />
      </div>
      <div
        className={
          isHoverVioleta
            ? "header-footer-colores-family--active"
            : "header-footer-colores-family--disabled"
        }
      >
        <HeaderLogoVioleta />
      </div>

      {/* MENU */}
      <div
        className={` ${
          isHoverVerde ? "header-footer-colores-family--disabled" : ""
        } ${isHoverVioleta ? "header-footer-colores-family--disabled" : ""}`}
      >
        <HeaderMenuTalents />
      </div>
      {/* MENU NEGRO */}
      <div
        className={
          isHoverVerde
            ? "header-footer-colores-family--active"
            : "header-footer-colores-family--disabled"
        }
      >
        <HeaderMenuNegro />
      </div>
      {/* MENU VIOLETA */}
      <div
        className={
          isHoverVioleta
            ? "header-footer-colores-family--active"
            : "header-footer-colores-family--disabled"
        }
      >
        <HeaderMenuVioleta />
      </div>

      <div
        className={`continer-full-family  ${
          isHoverVerde ? "HoverVerdeFamily" : ""
        } ${isHoverVioleta ? "HoverVioletaFamily" : ""} `}
      >
        <div
          className={`container-text-title-family  ${
            isHoverVioleta ? "HoverVioletaFamily" : ""
          } `}
          onMouseOver={HoverVioleta}
          onMouseOut={OutVioleta}
        >
          <p
            className={`text-family  ${
              isHoverVerde ? "HoverVerdeFamily" : ""
            }  ${isHoverVioleta ? "HoverVioletaFamily" : ""}`}
          >
            MH is an audiovisual production company based in Buenos Aires that
            creates original content for everyone.
          </p>
          <p
            className={`title-family  ${
              isHoverVerde ? "HoverVerdeFamily" : ""
            }  ${isHoverVioleta ? "HoverVioletaFamily" : ""}`}
          >
            WE ARE A HOME OF UNIQUE IDEAS, A PLATFORM DEDICATED TO CREATIVITY
            AND QUALITY PRODUCTION
          </p>
        </div>
        <div className="container-conflictos-mobile-family">
          <div className="container-person-MH-family">
            <div /* className='container-data-family'  */
              className={`container-data-family  ${
                isHoverVerde ? "HoverVerdeFamily" : ""
              }  ${isHoverVioleta ? "HoverVioletaFamily " : ""}`}
              onMouseOver={HoverVerde}
              onMouseOut={OutVerde}
            >
              <div className="data-family">
                <MetaTags>
                  <title>Juan Saravia</title>
                  <meta
                    content="Socio gerente y productor ejecutivo de Mamá Húngara."
                    description="Socio gerente y productor ejecutivo de Mamá Húngara."
                  />
                </MetaTags>
                <Person
                  name="JUAN SARAVIA"
                  data1="Managing Partner"
                  data2="Executive Producer"
                  data3="Adverts | Feature Films"
                  mail="juan@mamahungara.com"
                  hrefMail="mailto:juan@mamahungara.com"
                />
              </div>

              <div className="data-family">
                <MetaTags>
                  <title>Felipe Yaryura</title>
                  <meta
                    content="Socio gerente y productor ejecutivo de Mamá Húngara."
                    description="Socio gerente y productor ejecutivo de Mamá Húngara."
                  />
                </MetaTags>
                <Person
                  name="FELIPE YARYURA"
                  data1="Managing Partner "
                  data2="Executive Producer"
                  data3="Feature Films"
                  mail="felipe@mamahungara.com"
                  hrefMail="mailto:felipe@mamahungara.com"
                />
              </div>

              <div className="data-family">
                <MetaTags>
                  <title>Lisandro Ramos</title>
                  <meta
                    content="Socio & CEO, productor ejecutivo de Mamá Húngara."
                    description="Socio & CEO, y productor ejecutivo de Mamá Húngara."
                  />
                </MetaTags>
                <Person
                  name="LISANDRO RAMOS"
                  data1="Managing Partner "
                  data2="CEO & Executive Producer"
                  data3="Adverts | Feature Films"
                  mail="lisandro@mamahungara.com"
                  hrefMail="mailto:lisandro@mamahungara.com"
                />
              </div>
              <div className="data-family">
                <MetaTags>
                  <title>Lucas Fernandez Loyarte</title>
                  <meta
                    content="CFO, Jefe de Finanzas"
                    description="CFO, Jefe de Finanzas"
                  />
                </MetaTags>
                <Person
                  name="LUCAS FERNANDEZ LOYARTE"
                  data1="CFO"
                  data2="Executive Producer"
                  data3="Adverts | Feature Films"
                  mail="lucas@mamahungara.com"
                  hrefMail="mailto:lucas@mamahungara.com"
                />
              </div>
            </div>

            <div /* className='container-data-family'  */
              className={`container-data-family-02  ${
                isHoverVerde ? "HoverVerdeFamily" : ""
              }  ${isHoverVioleta ? "HoverVioletaFamily " : ""}`}
              onMouseOver={HoverVerde}
              onMouseOut={OutVerde}
            >
              <div className="data-family">
                <Person
                  name=" LUCA MACOME"
                  data1="Executive Producer"
                  mail="luca@mamahungara.com"
                  hrefMail="mailto:luca@mamahungara.com"
                  displaynoneFamily2="none-family"
                  displaynoneFamily3="none-family"
                />
              </div>
              <div className="data-family">
                <Person
                  name="TOMÁS GÓMEZ DEL FRESNO"
                  data1="Managing Director & EP / Mexico"
                  mail="tomas@mamahungara.com"
                  hrefMail="mailto:tomas@mamahungara.com"
                  displaynoneFamily2="none-family"
                  displaynoneFamily3="none-family"
                />
              </div>
              <div className="data-family">
                <Person
                  name="SOTI CHARNAS"
                  data1="Executive Producer"
                  mail="soti@mamahungara.com"
                  hrefMail="mailto:soti@mamahungara.com"
                  displaynoneFamily2="none-family"
                  displaynoneFamily3="none-family"
                />
              </div>
            </div>
          </div>
          <div className="container-redes-mail">
            <div className="margin-bottom-redes">
              {/* Habia <Person /> con las redes */}
            </div>

            <div className="data-networks-family">
              <a
                className="input-news-family"
                href="mailto:tati@mamahungara.com"
              >
                Newsletter Sign Up →
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* footer blanco 1 */}
      <div
        className={` ${
          isHoverVerde ? "header-footer-colores-family--active" : ""
        } ${isHoverVioleta ? "header-footer-colores-family--active" : ""}`}
      >
        <FooterLogoColors LogoColor={logoMHW} />
      </div>
      {/* footer negro 2 */}
      <div
        className={
          isHoverVerde
            ? "header-footer-colores-family--active"
            : "header-footer-colores-family--disabled"
        }
      >
        <FooterLogoColors LogoColor={logoMHNegro} />
      </div>
      {/* footer violeta 3 */}
      <div
        className={
          isHoverVioleta
            ? "header-footer-colores-family--active"
            : "header-footer-colores-family--disabled"
        }
      >
        <FooterLogoColors LogoColor={logoVioleta} />
      </div>
    </>
  );
};
