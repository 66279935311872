import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { HeaderMenu } from '../../../../headers/headerMenu';
import { HeaderLogoMH } from '../../../../headers/headerLogoMH';
import { useColorModeValue, Image, } from "@chakra-ui/react";
import MouseApp_blanco from "../../../../mouse/mouseApp_blanco.js";
import logoMHB from "../../../../img/500h/Asset_logo_blanco.png";
import '../videoIndividual.css';
import '../../../../footers/footerColors/footerColors.css';
import { FooterLogoColors } from '../../../../footers/footerColors/footerColors';
import { HeaderMenu_ES } from "../../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../../headers/headerLogoMH_ES";

function ElEstecoEN(props) {

    return (
        <>
            {/* <HeaderMenuHome /> */}
            <HeaderLogoMH />
            <HeaderMenu />
            <div className="player-wrapper">
                <MouseApp_blanco
                    alt='El Esteco | Fede García Rico & Martin Sigal'
                    urlVideo='https://vimeo.com/735212967 '
                // urlTitulo='elEsteco'
                />

                <FooterLogoColors
                    LogoColor={logoMHB}
                />


            </div>
        </>
    )
}
export default ElEstecoEN