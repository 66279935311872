import React from "react";
import ReactPlayer from "react-player";
import { TextFooter } from "../../../footers/textFooter";
import { HeaderMenu } from "../../../headers/headerMenu";
import { HeaderTexts } from "../../../headers/headerText";
import { FooterSmall2 } from "../../../footers/FooterSmall2.tsx";
import MetaTags from "react-meta-tags";

// import './grillas-talents.css';

export const GrillaFedeGR_EN = () => {
  return (
    <>
      <HeaderTexts title="FEDE GARCIA RICO" />
      <HeaderMenu />
      <div className="container-grillas-talents">
        <MetaTags>
          <title>All Fede García Rico</title>
          <meta
            content="Director publicitario representado por Mamá Húngara. Premiado como Nuevo Talento en Diente 2022"
            description="Director publicitario representado por Mamá Húngara. Premiado como Nuevo Talento en Diente 2022"
          />
        </MetaTags>
        <div className="container-altura-grillas-talents">
        <div className="container-video-talents-50">
            <a
              className="container-href-videos0-talents"
              href="/en/garpamos"
            ></a>
            <ReactPlayer
              alt="BRAHMA | NO LA GARPAMOS | FEDE GARCIA RICO"
              className="video-grilla-works"
              url="https://vimeo.com/992156559"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">
                BRAHMA | NO LA GARPAMOS
              </p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div className="container-video-talents-50">
            <a
              className="container-href-videos0-talents"
              href="/en/consumo"
            ></a>
            <ReactPlayer
              alt="BRAHMA | CONSUMO | FEDE GARCIA RICO"
              className="video-grilla-works"
              url="https://vimeo.com/992155726/5fa27728ba"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">
                BRAHMA | CONSUMO
              </p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div className="container-video-talents-50">
            <a
              className="container-href-videos0-talents"
              href="/es/gatorade"
            ></a>
            <ReactPlayer
              alt="GATORADE | MATCH SAVER | Fede García Rico"
              className="video-grilla-works"
              url="https://vimeo.com/940878425"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">
                GATORADE | MATCH SAVER
              </p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div className="container-video-talents-50">
            <a
              className="container-href-videos0-talents"
              href="/en/la-evolucion-del-lojack"
            ></a>
            <ReactPlayer
              alt="STRIX | LA EVOLUCION DEL LOJACK | Fede García Rico"
              className="video-grilla-works"
              url="https://vimeo.com/756236637"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">
                STRIX | LA EVOLUCION DEL LOJACK
              </p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/feeding-dreams"
            ></a>
            <ReactPlayer
              alt="FEEDING DREAMS I Fede García Rico"
              className="video-grilla-talents"
              url="https://vimeo.com/755986261"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">FEEDING DREAMS</p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div className="container-video-talents-50">
            <a
              className="container-href-videos0-talents"
              href="/en/love-save-us"
            ></a>
            <ReactPlayer
              alt="EL AMOR NOS SALVA I ALMA MORA I Fede García Rico"
              className="video-grilla-talents"
              url="https://vimeo.com/489073992"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                LOVE SAVE US | ALMA MORA
              </p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/burger-king-netflix"
            ></a>
            <ReactPlayer
              alt="BURGER KING + NETFLIX “THE WITCHER ” | FEDE GARCIA RICO"
              className="video-grilla-works"
              url="https://vimeo.com/846028180"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">
                BURGER KING + NETFLIX THE WITCHER
              </p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div class="container-video-talents-1">
            <a className="container-href-videos0-talents" href="/en/idolas"></a>
            <ReactPlayer
              alt="YPF | IDOLAS | FEDE GARCIA RICO"
              className="video-grilla-talents"
              url="https://vimeo.com/848844305"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">YPF | IDOLAS</p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>
          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/en/molinos"
              ></a>
              <ReactPlayer
                alt="120 AÑOS | MOLINOS | FEDE GARCIA RICO"
                className="video-grilla-works"
                url="https://vimeo.com/780534111"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  120 AÑOS | MOLINOS
                </p>
                <p className="text-footer-grillas-talents-2">
                  Fede García Rico
                </p>
              </div>
            </div>
          </div>

          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/208-peugeot"
            ></a>
            <ReactPlayer
              alt="208 | PEUGEOT | Fede García Rico"
              className="video-grilla-works"
              url="https://vimeo.com/475924465"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents-2">
              <p className="text-footer-grillas-talents-1">208 | PEUGEOT</p>
              <p className="text-footer-grillas-talents-2">Fede García Rico</p>
            </div>
          </div>

          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/en/molinos"
              ></a>
              <ReactPlayer
                alt="120 AÑOS | MOLINOS | FEDE GARCIA RICO"
                className="video-grilla-works"
                url="https://vimeo.com/780534111"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  120 AÑOS | MOLINOS
                </p>
                <p className="text-footer-grillas-talents-2">
                  Fede García Rico
                </p>
              </div>
            </div>
          </div>

          <div class="container-video-talents-1">
            <a
              className="container-href-videos0-talents"
              href="/en/el-esteco"
            ></a>
            <ReactPlayer
              alt="El Esteco | Fede García Rico & Martin Sigal"
              className="video-grilla-works"
              url="https://vimeo.com/735212967"
              playing={true}
              autoPlay={true}
              playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
            <div className="container-footer-grillas-talents">
              <p className="text-footer-grillas-talents-1">EL ESTECO</p>
              <p className="text-footer-grillas-talents-2">
                Fede García Rico & Martin Sigal
              </p>
            </div>
          </div>

          <div className="container-video-talents-2">
            <div className="container-video-talents-50">
              <a
                className="container-href-videos0-talents"
                href="/en/stella-vintage"
              ></a>
              <ReactPlayer
                alt="STELLA VINTAGE | STELLA ARTOIS"
                className="video-grilla-talents"
                url="https://vimeo.com/469937792"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-talents-2">
                <p className="text-footer-grillas-talents-1">
                  STELLA VINTAGE | STELLA ARTOIS
                </p>
                <p className="text-footer-grillas-talents-2">
                  Fede García Rico
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        onClick={() => {
          window.history.back();
        }}
      >
        <TextFooter title1="BACK" />
      </a>

      <FooterSmall2 />
    </>
  );
};
