import React, { useState } from "react";
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react";
import { TextFooter } from '../../../footers/textFooter.js'
import { HeaderMenuTalents } from "../../../headers/headerTalents/headerMenuTalents.js";
import { HeaderTexts } from '../../../headers/headerText.js';
import { FooterLogoColors } from "../../../footers/footerColors/footerColors.js";
import logoMHV from "../../../img/500h/Asset_logo_violet.png";
import Mouse3_drag_violet from "../../../mouse/Mouse3_drag_violet.js";
import video from './videos/zurich.mp4'
import "swiper/css";
import "swiper/css/bundle";
import './styleSlideimage2.css'
import {  Pagination, Navigation, Mousewheel } from "swiper";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import MetaTags from 'react-meta-tags';
import '@splidejs/react-splide/css';
import { HeaderTexts_ES } from "../../../headers/headerText_ES.js";
import KeyboardEventHandler from 'react-keyboard-event-handler';

function SteveBrunoEn() {
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <HeaderTexts_ES
        title="STEVE BRUNO"
      />
      <HeaderMenuTalents />
      <div className="container-swiper-talents">
        <MetaTags>
          <title>STEVE BRUNO</title>
          <meta
            content="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
            description="Artista, director, músico y fotógrafo. Durante la última década ha cultivado una visión artística poderosa y única que extiende disciplinas y proyectos creativos"
          />
        </MetaTags>
        <Mouse3_drag_violet />
        <KeyboardEventHandler
          handleKeys={['left']}
          onKeyEvent={(key, e) => swiper.slidePrev()}
        />
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => swiper.slideNext()}
        />
        <Swiper
          className='cursor-none container-swiper-home container-slider-photographer-still'
          slidesPerView={1}
          onSwiper={setSwiper}
          loopedSlides={5}
          spaceBetween={0}
          freeMode={false}
          modules={[ Pagination, Navigation, Mousewheel]}
          mousewheel
          loop={true}
          navigation
        >
          {/* video 1 */}
          <SwiperSlide
            onClick={() => (window.open('/en/spotify-playlist', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='SPOTIFY | PLAYLIST | STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848730249/d9016bcbda"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="SPOTIFY | PLAYLIST"
              title2="STEVE BRUNO"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/drives-you-wonder', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='DRIVES YOU WONDER | NISSAN | STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848731979/6ab28bf48d"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="DRIVES YOU WONDER | NISSAN"
              title2="STEVE BRUNO"
            />

          </SwiperSlide>
          <SwiperSlide
            onClick={() => (window.open('/en/blending', '_self'))}
            className="container-video-SwiperSlide" >
            <div className="player-wrapper">
              <ReactPlayer
                alt='BLENDING / STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848485218/dc9b87a94c"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BLENDING"
              title2="STEVE BRUNO"
            />

          </SwiperSlide>
          {/* video 2 */}
          <SwiperSlide
            onClick={() => (window.open('/en/boticario-cuidese', '_self'))}
            className="container-video-SwiperSlide">
            <div className="player-wrapper">
              <ReactPlayer
                alt='BOTICARIO | CUIDESE BEM BOA NOITE | STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848734278/ce5302881b"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BOTICARIO | CUIDESE BEM BOA NOITE "
              title2="STEVE BRUNO"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* mobile */}
      <div className="container-video-home-mobile">
        <Splide
          data-splide='{"type":"slide","perPage": 1, "drag": "free" }'
        >
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='SPOTIFY | PLAYLIST | STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848730249/d9016bcbda"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
             title1="SPOTIFY | PLAYLIST"
             title2="STEVE BRUNO"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='DRIVES YOU WONDER | NISSAN | STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848731979/6ab28bf48d"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />

            </div>

            <TextFooter
             title1="DRIVES YOU WONDER | NISSAN"
             title2="STEVE BRUNO"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='BLENDING / STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848485218/dc9b87a94c"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BLENDING"
              title2="STEVE BRUNO"
            />
          </SplideSlide>
          <SplideSlide >
            <div className="drag-mobile"></div>
            <div className="player-wrapper">
              <ReactPlayer
                alt='BOTICARIO | CUIDESE BEM BOA NOITE | STEVE BRUNO'
                className="react-player-video"
                url="https://vimeo.com/848734278/ce5302881b"

                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
            </div>
            <TextFooter
              title1="BOTICARIO | CUIDESE BEM BOA NOITE "
              title2="STEVE BRUNO"
            />
          </SplideSlide>
        </Splide>
      </div>
      <FooterLogoColors
        LogoColor={logoMHV}
      />
    </>
  )
}

export default SteveBrunoEn