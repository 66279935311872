import React from "react";
import { useDisclosure, Image } from "@chakra-ui/react";

import { Drawer_MH_v } from "../../drawer/drawerTalents/DrawerTalents";

import '.././header.css'

export const HeaderMenuTalents = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>
  <div className="container-header-1">
    <Drawer_MH_v className="color" />
  </div>
  </>
  );
};
