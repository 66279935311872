import { useDisclosure } from "@chakra-ui/react";
import React from "react";

import './header.css'

export const HeaderTexts = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>
    <div className="container-text-header-1">
      <a href="/en/home/">
        {props.title}
      </a>
    </div>
  </>
  );
};
