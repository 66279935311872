import { Button, useDisclosure } from '@chakra-ui/react'
import { Drawer, Image,} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'

import './DrawerTalents'
import openIcon from '../../img/500h/Asset_boton_menu_violeta.png'
import closeIcon from '../../img/500h/Asset_1.png'

export const Submenu = ({ href, text }) => (
  <a href={href} className="text-submenu">
    {text}
  </a>
)
export const Drawer_Violeta = () => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure()/* aca esto  */
  const [placement, setPlacement] = React.useState('right')
  const [isShown, setShown] = useState("")
  // agregar use effect para que salga el boton de aceptar cookies apenas se carga la web

  return (
    <>
           <Button
         bgColor={'transparent'}
         onClick={onOpen}
         alignItems={"center"}
         
         _hover={{
           bg:'transparent', 
           cursor: 'default', 
           borderLine: 'none', 
           boxShadow: 'none'
          }}
          padding="0px"
          margin="0px"
         leftIcon={<Image margin='0' bgColor='none' src={openIcon} w='36px' h ='32px'/>}
         >
         </Button>

    
    </>
  )
}