import React from "react";
import "./textFooter.css";

export const TextFooter = (props) => {
  return (
    <div
      className="footer-video"
      style={{ justifyContent: props.centerContent && "center" }}
    >
      <div
        className={
          props.title1 == "DIRECTORS" ||
          props.title1 == "FILMS" ||
          props.title1 == "ALL SELECTED" ||
          props.title1 == "ALL COMMERCIAL" ||
          props.title1 == "ALL CONTENT"
            ? "container-text-footer-homeDir"
            : "container-text-footer-home"
        }
      >
        <a href={props.href1} className="text-footer-home-1">
          {" "}
          {props.title1}{" "}{props.country}
        </a>
        <a href={props.href2} className="text-footer-home-2">
          {" "}
          {props.title2}{" "}
        </a>
        <a href={props.href3} className="text-footer-home-3">
          {" "}
          {props.title3}{" "}
        </a>
      </div>
    </div>
  );
};
