import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { HeaderMenu } from '../../../../../headers/headerMenu';
import { HeaderLogoMH } from '../../../../../headers/headerLogoMH';
import { useColorModeValue, Image, } from "@chakra-ui/react";
import MouseApp_blanco from "../../../../../mouse/mouseApp_blanco.js";
import logoMHB from "../../../../../img/500h/Asset_logo_blanco.png";
import '../../videoIndividual.css';
import '../../../../../footers/footerColors/footerColors.css';
import {FooterLogoColors} from '../../../../../footers/footerColors/footerColors';
import { HeaderMenu_ES } from "../../../../../headers/headerMenu_ES";
import { HeaderLogoMH_ES } from "../../../../../headers/headerLogoMH_ES";




function MichelobUltra(props) {

    return (
        <>        
        {/* <HeaderMenuHome /> */}
            <HeaderLogoMH_ES />
            <HeaderMenu_ES/>
            <div className="player-wrapper">
                <MouseApp_blanco
                alt="MICHELOB ULTRA | LA DULCE LIBERAL"
                urlVideo='https://vimeo.com/703861445'
                // urlTitulo='NowNess'
                />
              
                 <FooterLogoColors 
            LogoColor={logoMHB}
            />
                

            </div>
        </>
    )
}
export default MichelobUltra