import React from "react";
import ReactPlayer from "react-player";

import { HeaderMenu } from "../../../../headers/headerMenu";
import { HeaderLogoMH } from "../../../../headers/headerLogoMH";
import { TextFooter } from "../../../../footers/textFooter";
import { FooterSmall2 } from "../../../../footers/FooterSmall2.tsx";

// import '../../grillas-works.css';

export const GrillasWorks_EN = () => {
  return (
    <>
      <HeaderMenu />
      <HeaderLogoMH />
      <div className="container-grillas-works">
        <div className="container-altura-works">
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PERSONAL PAY | ESTA BUENO COMPARTIRLO | MARIANO NANTE"
                className="video-grilla-works"
                url="https://vimeo.com/935536399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  PERSONAL PAY | ESTA BUENO COMPARTIRLO
                </p>
                <p className="text-footer-grillas-works-2">Mariano Nante</p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="NETFLIX | ESTES PARA LO QUE ESTES "
                className="video-grilla-works"
                url="https://vimeo.com/862779909"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    NETFLIX | ESTES PARA LO QUE ESTES
                </p>
                <p className="text-footer-grillas-works-2">
                    Hernán Corera & Juan Piczman
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PROSEGUR | NADA ES TAN PROFESIONAL | CARMEN RIVOIRA "
                className="video-grilla-works"
                url="https://vimeo.com/918353819"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    PROSEGUR | NADA ES TAN PROFESIONAL
                </p>
                <p className="text-footer-grillas-works-2">
                    Carmen Rivoira
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BGH | CALUROSAMENTE ARGENTINOS | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/891557091"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    BGH | CALUROSAMENTE ARGENTINOS
                </p>
                <p className="text-footer-grillas-works-2">
                    Santiago Franco
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PERSONAL | CONEXIÓN TOTAL | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/890293384"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    PERSONAL | CONEXIÓN TOTAL
                </p>
                <p className="text-footer-grillas-works-2">
                    Gonzo Llorente
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PERSONAL | CONEXIÓN TOTAL | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/890293384"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    PERSONAL | CONEXIÓN TOTAL
                </p>
                <p className="text-footer-grillas-works-2">
                    Gonzo Llorente
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BPLAY | SPONSOR OFICIAL | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/850703869"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    BPLAY | SPONSOR OFICIAL
                </p>
                <p className="text-footer-grillas-works-2">
                    Santiago Franco
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="HBO MAX | DEL CINE A TU CASA | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/806073285"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                    HBO MAX | DEL CINE A TU CASA
                </p>
                <p className="text-footer-grillas-works-2">
                    Gonzo Llorente
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/del-cine-a-tu-casa"
              ></a>
            </div>
            {/*  */}
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BPLAY | SPONSOR DEL CORAZÓN ARGENTO | MARIANO NANTE"
                className="video-grilla-works"
                url="https://vimeo.com/761431757"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BPLAY | SPONSOR DEL CORAZÓN ARGENTO
                </p>
                <p className="text-footer-grillas-works-2">Mariano Nante</p>
              </div>
              <a className="container-href-videos" href="/en/bplay"></a>
            </div>
          </div>

          {/* video 3 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="ZURICH | OTRO DEPORTE | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/752541132"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  ZURICH | OTRO DEPORTE
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a className="container-href-videos" href="/en/another-sport"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="STRIX | LA EVOLUCION DEL LOJACK | FEDE GARCIA RICO"
                className="video-grilla-works"
                url="https://vimeo.com/756236637"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  STRIX | LA EVOLUCION DEL LOJACK
                </p>
                <p className="text-footer-grillas-works-2">Fede García Rico</p>
              </div>
              <a
                className="container-href-videos"
                href="/en/la-evolucion-del-lojack"
              ></a>
            </div>
          </div>
          {/*  */}

          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="KELLOGG´S | POP-TARTS | DIEGO FRIED"
                className="video-grilla-works"
                url="https://vimeo.com/769897032"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  KELLOGG´S | POP-TARTS
                </p>
                <p className="text-footer-grillas-works-2">Diego Fried</p>
              </div>
              <a
                className="container-href-videos"
                href="/en/kelloggs-pop-tarts"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BELDENT | MOSTRALES QUE ESTAS READY"
                className="video-grilla-works"
                url="https://vimeo.com/763421158"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BELDENT | MOSTRALES QUE ESTAS READY
                </p>
                <p className="text-footer-grillas-works-2">
                  Hernán Corera & Juan Piczman
                </p>
              </div>
              <a className="container-href-videos" href="/en/beldent"></a>
            </div>
          </div>
          {/*  */}

          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt=" AE | AGUAS DE RIO DE JANEIRO"
                className="video-grilla-works"
                url="https://vimeo.com/710488571"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  AE | AGUAS DE RIO DE JANEIRO
                </p>
                <p className="text-footer-grillas-works-2">Gonzo Llorente</p>
              </div>
              <a
                className="container-href-videos"
                href="/en/aguas-de-rio-de-janeiro"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="AMERICANINO | THE REASON IS FREEDOM"
                className="video-grilla-works"
                url="https://vimeo.com/766590399"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  AMERICANINO | THE REASON IS FREEDOM
                </p>
                <p className="text-footer-grillas-works-2">MC Calle</p>
              </div>
              <a className="container-href-videos" href="/en/americanino"></a>
            </div>
          </div>
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="NADIE | SIN BANDERA"
                className="video-grilla-works"
                url="https://vimeo.com/707412751"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  NADIE | SIN BANDERA
                </p>
                <p className="text-footer-grillas-works-2">
                  Hernán Corera & Juan Piczman
                </p>
              </div>
              <a className="container-href-videos" href="/en/no-one"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="2-SUEÑA POTENTE | AMD"
                className="video-grilla-works"
                url="https://vimeo.com/515269858"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  SUEÑA POTENTE | AMD
                </p>
                <p className="text-footer-grillas-works-2">Marian & Santi</p>
              </div>
              <a className="container-href-videos" href="/en/dream-big"></a>
            </div>
          </div>

          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="UBER | BOXER | GONZO LLORENTE"
                className="video-grilla-works"
                url="https://vimeo.com/710392513"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">UBER | BOXER</p>
                <p className="text-footer-grillas-works-2">Gonzo Llorente</p>
              </div>
              <a className="container-href-videos" href="/en/uber-boxer"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="4-EL AMOR NOS SALVA I ALMA MORA I Fede Garcia Rico"
                className="video-grilla-works"
                url="https://vimeo.com/489073992"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  EL AMOR NOS SALVA | ALMA MORA
                </p>
                <p className="text-footer-grillas-works-2">Fede Garcia Rico</p>
              </div>
              <a className="container-href-videos" href="/en/love-save-us"></a>
            </div>
          </div>
          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="PARAISO | SADAELS | HERNAN CORERA"
                className="video-grilla-works"
                url="https://vimeo.com/520227700"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">PARAISO | SADAELS</p>
                <p className="text-footer-grillas-works-2">Hernán Corera</p>
              </div>
              <a className="container-href-videos" href="/en/paradise"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="DOKKAEBIS | MMIC "
                className="video-grilla-works"
                url="https://vimeo.com/606825402"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">DOKKAEBIS | MMIC</p>
                <p className="text-footer-grillas-works-2">Alcibiade Cohen</p>
              </div>
              <a className="container-href-videos" href="/en/dokkaebis"></a>
            </div>
          </div>
          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="UIFRA | ALIMENTANDO SUEÑOS | Fede Garcia Rico"
                className="video-grilla-works"
                url="https://vimeo.com/755986261"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  UIFRA | ALIMENTANDO SUEÑOS
                </p>
                <p className="text-footer-grillas-works-2">Fede García Rico</p>
              </div>
              <a
                className="container-href-videos"
                href="/en/feeding-dreams"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt=" LA VIDA ARTOIS | STELLA ARTOIS | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/685534917"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  LA VIDA ARTOIS | STELLA ARTOIS
                </p>
                <p className="text-footer-grillas-works-2">Marian & Santi</p>
              </div>
              <a className="container-href-videos" href="/en/artois-s-life"></a>
            </div>
          </div>
          {/* 2 */}
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="SEX EDUCATION | NETFLIX"
                className="video-grilla-works"
                url="https://vimeo.com/617028986"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  SEX EDUCATION | NETFLIX
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a className="container-href-videos" href="/en/sex-education"></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="BECK'S | HACELO DIFERENTE"
                className="video-grilla-works"
                url="https://vimeo.com/703861246"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  BECK'S | HACELO DIFERENTE
                </p>
                <p className="text-footer-grillas-works-2">
                  Olivia Sanchez & Martina Galli Agulla
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/becks-do-it-different"
              ></a>
            </div>
          </div>
          <div className="container-2-videos-works">
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="5-HECHO EN ARGENTINA | NETFLIX | Hernán Corera y Juan Piczman "
                className="video-grilla-works"
                url="https://vimeo.com/693596507"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  HECHO EN ARGENTINA | NETFLIX
                </p>
                <p className="text-footer-grillas-works-2">
                  Hernán Corera &amp; Juan Piczman
                </p>
              </div>
              <a
                className="container-href-videos"
                href="/en/made-in-argentina"
              ></a>
            </div>
            <div className="contrainer-50-works">
              <ReactPlayer
                alt="ROMANCE ETERNO | FIFA 21 | SANTIAGO FRANCO"
                className="video-grilla-works"
                url="https://vimeo.com/497254514"
                playing={true}
                autoPlay={true}
                playsinline
                controls={false}
                volume={0}
                muted={true}
                loop={true}
              />
              <div className="container-footer-grillas-works">
                <p className="text-footer-grillas-works-1">
                  ROMANCE ETERNO | FIFA 21
                </p>
                <p className="text-footer-grillas-works-2">Santiago Franco</p>
              </div>
              <a
                className="container-href-videos"
                href="/en/eternal-romance"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <TextFooter title1="SELECTED" href1="/en/selected" />
      <FooterSmall2 />
    </>
  );
};
