// const Video = require('react-video')

// React.render(
//     <Video from='youtube' videoId={videoId} />,
//     $mountNode
// );

// React.render(
//     <Video from='vimeo' videoId={videoId} />,
//     $mountNode
// );

// var _onError = function(err) {
//     console.log(err);
//   };

//   React.render(
//     <Video onError={_onError} videoId={videoId} />,
//     document.querySelector('#your-div')
//   );

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Divider,
  Image,
  CloseButton,
  Stack,
  Center,
  VStack,
  Box,
  Text,
  Menu, MenuButton, MenuList, MenuItem, MenuDivider, Container
} from '@chakra-ui/react'
// import { gsap } from "gsap";
import React from "react"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react";
import Mause from '../mouse/Mouse.js'


import "swiper/css";
import "swiper/css/bundle";
import './styles.css'
import './stylevideo.css'
import { Header_ES } from '../headers/Header_ES.tsx';
import { Home_ES } from '../pages/1_home/Home';

function VideoPlayer() {
  return (
    <>
         <Mause />


        <Swiper className="mySwiper">
        <SwiperSlide className="hola" >
          
          {/* <VStack mt={96}>
            <VStack
              ml='-14'
              bgColor='transparent'
              direction={'column'}
              align='center'
              mt={96}
            >
              <Box
                textColor='whiteAlpha.400'

                mr='-28'
                textAlign='justify'
                justifyContent='center'
                letterSpacing={'2px'}
              >
                <Text
                  className="rotate"
                  fontFamily={'GoodSans-Thin'}
                  fontSize='18.5px'
                  fontWeight='50'
                  textTransform='uppercase'
                >PROJECT
                </Text>
              </Box>
            </VStack>
          </VStack>
          <VStack
            bgColor='transparent'
            direction={'column'}
            align='center'
            mt={96}
          >
            <Box
              textColor='whiteAlpha.900'
              textAlign='justify'
              justifyContent='center'
              letterSpacing={'2px'}
              mt={96}
            >
              <Text
                mr='-30'
                ml='-0'
                className="rotate"
                fontFamily={'Serifbabe_Regular'}
                fontSize='20px'
                textTransform='uppercase'
              >SADAELS
              </Text>
            </Box>
          </VStack> */}
          <div className="player-wrapper">

            <ReactPlayer
              alt='sueña-potente'
              className="react-player-hola"
              url="https://player.vimeo.com/video/520227700?h=a71ae57e47abb2411c4cdf9283000c67"
              
              playing={true}
              autoPlay={true}
playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
            
          </div>
     {/*      <VStack mt={96}>
            <VStack
              ml='-14'
              bgColor='transparent'
              direction={'column'}
              align='center'
              mt={96}
            >
              <Box
                textColor='whiteAlpha.400'

                mr='-28'
                textAlign='justify'
                justifyContent='center'
                letterSpacing={'2px'}
              >
                <Text
                  className="rotate"
                  fontFamily={'GoodSans-Thin'}
                  fontSize='18.5px'
                  fontWeight='50'
                  textTransform='uppercase'
                >PROJECT
                </Text>
              </Box>
            </VStack>
          </VStack> */}
    {/*       <VStack
            bgColor='transparent'
            direction={'column'}
            align='center'
            mt={96}
          >
            <Box
              textColor='whiteAlpha.900'
              textAlign='justify'
              justifyContent='center'
              letterSpacing={'2px'}
              mt={96}
            >
              <Text
                mr='-30'
                ml='-0'
                className="rotate"
                fontFamily={'Serifbabe_Regular'}
                fontSize='20px'
                textTransform='uppercase'
              >SADAELS

              </Text>
            </Box>
          </VStack> */}
        </SwiperSlide>

        <SwiperSlide className="hola">
          <div className="player-wrapper">
            <ReactPlayer
            alt='nadie-sin-bandera'
              className="react-player-hola"
              url="https://player.vimeo.com/video/707412751?h=a71ae57e47abb2411c4cdf9283000c67"
              playing={true}
              autoPlay={true}
playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
              
              // config={{
              //   vimeo: {
              //     playerOptions: { background: true, responsive: true, loop: true }
              //   }
              // }}
              // muted={false}
              // controls={false}

            />
          </div>
 {/*          <VStack mt={96}>
            <VStack
              ml={-24}
              mr={-2}
              bgColor='transparent'
              direction={'column'}
              align='center'
              mt={96}
            >
              <Box
                textColor='whiteAlpha.400'

                mr={-32}
                textAlign='justify'
                justifyContent='center'
                letterSpacing={'2px'}
              >
                <Text
                  className="rotate"
                  fontFamily={'GoodSans-Thin'}
                  fontSize='18.5px'
                  fontWeight='50'
                  textTransform='uppercase'
                >PROJECT
                </Text>
              </Box>
            </VStack>
          </VStack>
          <VStack
            bgColor='transparent'
            direction={'column'}
            align='center'
            mt={96}
          >
            <Box
              textColor='whiteAlpha.900'
              textAlign='justify'
              justifyContent='center'
              letterSpacing={'2px'}
              mt={96}
            >
              <Text
                mr='1'
                ml='1'
                mt='9'
                className="rotate"
                fontFamily={'Serifbabe_Regular'}
                fontSize='20px'
                textTransform='uppercase'
              >NADIE

              </Text>
            </Box>
          </VStack> */}
        </SwiperSlide>
        <SwiperSlide className="hola">
          <div className="player-wrapper">
            <ReactPlayer
            alt='Paraiso-Sadaels'
              className="react-player-hola"
              url="https://player.vimeo.com/video/515269858?h=a71ae57e47abb2411c4cdf9283000c67"
              playing={true}
              autoPlay={true}
playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}
            />
          </div>
          
        </SwiperSlide>
        <SwiperSlide className="hola">
          <div className="player-wrapper">
            <ReactPlayer
            alt='hecho-en-argentina'
              className="react-player-hola"
              url="https://player.vimeo.com/video/693596507?h=a71ae57e47abb2411c4cdf9283000c67"
              playing={true}
              autoPlay={true}
playsinline
              controls={false}
              volume={0}
              muted={true}
              loop={true}

            />
          </div>
        </SwiperSlide>
        {/* <SwiperSlide className="hola">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player-hola"
              url="https://vimeo.com/520227700"
              config={{
                vimeo: {
                  playerOptions: { background: true, responsive: true }
                }
              }}
              muted={false}
              controls={false}
              
            />
          </div>
        </SwiperSlide> */}
      </Swiper>




      {/* <div className="cursor" data-cursor><div></div></div>
    <div className='slide-container'>
      <div className="slider js-slider">
        <div className="slider__inner js-slider__inner">
          <div className="slide js-slide">
            <div className="slide__bg">
              <iframe 
              src="https://www.youtube.com/embed/Law7wfdg_ls" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; 
              autoplay; 
              clipboard-write; 
              encrypted-media; 
              gyroscope; 
              picture-in-picture
              "
              allowfullscreen
              >
                </iframe>
                </div>
          </div>
       <div class="slide js-slide">
            <div class="slide__bg" ><iframe src="https://www.youtube.com/embed/Law7wfdg_ls" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
          </div>
          </div>
        </div>
      </div> */}

      {/* <ReactPlayer
          url="https://vimeo.com/520227700"
          playing={true}
          controls={false}
          volume={0}
          muted={true}
        />
          */}
    </>
  )
}

export default VideoPlayer